import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { NotificationManager } from "react-notifications";
import { authenticationService } from "../../_services";
import { eventService } from "../../_services/staff";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { userService } from "../../_services/admin";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";

function mapStateToProps(state) {
  return {
    jobData: state.jobData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
  };
}

class JobOppsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      userFromApi: null,
      jobDetails: {},
      loading: false,
      jobData: this.props.jobData,
    };
  }

  componentDidMount() {
    this.getJobDetailByJobId();
    console.log(this.props.jobData);
  }
  getJobDetailByJobId() {
    let jobId = this.props.match.params.id;
    let { jobData } = this.state;
    if (jobData.length > 0) {
      let filteredJobItem = this.state.jobData.filter(
        (jobItems) => jobItems.JobId == jobId
      );
      console.log("filteredJobItem: ", filteredJobItem);
      this.setState({ jobDetails: filteredJobItem[0] });
    } else {
      userService.getJobDetailByJobId(jobId).then((data) => {
        this.setState({ loading: false });
        console.log("data", data.Data);
        this.setState({ jobDetails: data.Data });
      });
    }
  }

  render() {
    const { jobDetails } = this.state;
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Job-Opps-Details</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="align-self-center tbl-head">
                          <span>{jobDetails?.JobTitle} </span>
                        </div>
                        <Button
                          color="secondary"
                          className="ml-5"
                          onClick={() => {
                            window.print();
                          }}
                        >
                          Print Post
                        </Button>{" "}
                        <Link to="/jobopps" className="btn btn-primary ml-3">
                          <i className="mdi mdi-chevron-left"></i>
                          <span className="hide-menu">Back</span>
                        </Link>
                      </div>
                      <hr />
                      <div className="row d-flex">
                        <div className="col-md-6">
                          <b>Date Posted :</b>{" "}
                          {moment(
                            jobDetails?.DisplayOnWebDatePost?.date
                          ).format("MMMM DD, YYYY")}
                        </div>
                        <div className="col-md-6 bold">
                          Employment Type : {jobDetails?.FullPartTemp}
                        </div>
                        <div className="col-md-6 bold">
                          Salary : {jobDetails?.RatePerHour} per hour
                        </div>
                        <div className="col-md-6 bold">
                          Transportation :{" "}
                          {jobDetails?.BusRoute === "Yes"
                            ? "On Bus Route"
                            : "None "}
                        </div>
                        <div className="col-md-6 bold">
                          Location : {jobDetails?.City} {jobDetails?.Province}
                        </div>
                      </div>
                      <div className="row d-flex mt-3">
                        <div className="col-md-12">
                          <div className="align-self-center">
                            <span className="bold">Description :</span>
                            <div>{jobDetails?.JobDescription}</div>
                          </div>
                        </div>
                      </div>
                      <div className="row d-flex mt-3">
                        <div className="col-md-12">
                          <div className="align-self-center">
                            <span className="bold">Qualification :</span>
                            <div>{jobDetails?.Qualification}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
        <Modal
          isOpen={this.state.contactModal}
          toggle={this.toggleContact}
          centered
        >
          <ModalHeader toggle={this.toggleContact}>Registration</ModalHeader>
          <ModalBody>
            Please Contact UHC for details regarding registation.
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobOppsDetail);
