import { Storage } from 'aws-amplify';
import { AWS } from '@aws-amplify/core';
import config from '../../config';
import { authHeader, handleResponse } from '../../_helpers';
import { encData } from '../../_helpers/_helperFunctions';
AWS.config.update({
    credentials: new AWS.Credentials({
      accessKeyId: "AKIASYB5S5IBI6RMIXNM",
      secretAccessKey: "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"
    })
  })
  
  Storage.configure({
    bucket: "west-of-windsor",
    region: "ca-central-1"
  });


export const formService = {
    getAllIntakeForms,
    getAllReferalInbox,
    getIntakeFormById,
    save,
    getAllDepartments,
    getAllChartData,
    getAllCountries,
    intakeFormAccessUpdate,
    globleSearchForm,
    uploadFileIntoS3,
    s3Get,
    removeDocumentFromS3,
    removeUploadedDocument,
    getAllUsers,
    referalArchiveToggle,
    saveMessage,
    getMessages,
    getMessageById,
    internalMessagesArchiveToggle,
    getTaskReports,
    saveTaskReport,
    saveWorkCalendar,
    taskReportArchiveToggle,
    completeToggleTaskReport,
    respondToTaskReport,
    getTaskReportById,
    getWorkCalendarById,
    getWorkCalendar,
    respondToWorkCalendar,
    editWorkCalendar,
    getAllUsersOfDepartment,
    fixEmergencyContacts,
    createImportHistory,
    getAllImportHistory,
    getAllChartDataByYear,
    getReferralList,
    getAllWestSupport
};

async function uploadFileIntoS3( file,s3FilePath){
    let _that = this;
    console.log("Upload STARTED.......");
    await Storage.put(s3FilePath,file, {
      contentType: file.type, progressCallback(progress) {
        let percentage = progress.loaded / progress.total;
        // obj[inspectionId].isIndeterminate = percentage===1;
        // obj[inspectionId].percentage = percentage;
        // _that.setState({ uploadPercentages: obj });
        console.log("File Uploaded:", percentage);
        // console.log("_that.state.uploadPercentages::::", _that.state.uploadPercentages);
      }
    }).then(fileInfo => {
      return new Promise(async (resolve)=>{resolve(fileInfo)})
    });
}

async function s3Get(key){
    return new Promise((resolve)=>{
        Storage.get(key, {expires: 2 * 60})
        .then(result => {
            console.log("Result get file::",result);
            resolve(result);
        }).catch(err =>{ 
            console.log("Error while getting file:::",err);
            resolve(false);
        });
    });
}
async function removeDocumentFromS3(file){
    return new Promise((resolve)=>{
        Storage.remove(file)
    .then(result =>{ 
        console.log("Result del file::",result);
    resolve(result);
})
    .catch(err => { 
        console.log("Error while deleting file:::",err);
        resolve(false);
    });
    });
    
}

function getAllWestSupport() {
    const requestOptions = { method: 'GET', headers: authHeader()};
    console.log("requestOptions: ", requestOptions)
    return fetch(`${config.apiUrl}/api/v1/westSupport`, requestOptions).then(handleResponse);
}
function getAllIntakeForms(data) {
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(data) };
    return fetch(`${config.apiUrl}/api/v1/intakeforms`, requestOptions).then(handleResponse);
}
function fixEmergencyContacts(data) {
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(data) };
    return fetch(`${config.apiUrl}/api/v1/intakeforms/fixEmergencyContacts`, requestOptions).then(handleResponse);
}
function getAllReferalInbox(data) {
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(data) };
    console.log('data->>>>',data)
    return fetch(`${config.apiUrl}/api/v1/intakeforms/getAllReferalInbox`, requestOptions).then(handleResponse);
}
function referalArchiveToggle(data) {
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(data) };
    return fetch(`${config.apiUrl}/api/v1/intakeforms/referalArchiveToggle`, requestOptions).then(handleResponse);
}
function internalMessagesArchiveToggle(data) {
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(data) };
    return fetch(`${config.apiUrl}/api/v1/internalMessages/archiveToggle`, requestOptions).then(handleResponse);
}
function taskReportArchiveToggle(data) {
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(data) };
    return fetch(`${config.apiUrl}/api/v1/taskReports/archiveToggle`, requestOptions).then(handleResponse);
}
function respondToTaskReport(data) {
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(data) };
    return fetch(`${config.apiUrl}/api/v1/taskReports/respondToTaskReport`, requestOptions).then(handleResponse);
}
function respondToWorkCalendar(data) {
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(data) };
    return fetch(`${config.apiUrl}/api/v1/workCalendar/respondToWorkCalendar`, requestOptions).then(handleResponse);
}
function getTaskReportById(id) {
    const requestOptions = { method: 'GET', headers: authHeader()};
    return fetch(`${config.apiUrl}/api/v1/taskReports/${id}`, requestOptions).then(handleResponse);
}
function getWorkCalendarById(id) {
    const requestOptions = { method: 'GET', headers: authHeader()};
    return fetch(`${config.apiUrl}/api/v1/workCalendar/${id}`, requestOptions).then(handleResponse);
}
function completeToggleTaskReport(data) {
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(data) };
    return fetch(`${config.apiUrl}/api/v1/taskReports/completeToggleTaskReport`, requestOptions).then(handleResponse);
}

function getAllUsers(currentUserId) {
    const requestOptions = { method: 'POST', headers: authHeader(), body: encData({currentUserId: currentUserId})};
    console.log("requestOptions: ", requestOptions)
    return fetch(`${config.apiUrl}/api/v1/users`, requestOptions).then(handleResponse);
}
function getReferralList() {
    const requestOptions = { method: 'Get', headers: authHeader()};
    console.log("requestOptions: ", requestOptions)
    return fetch(`${config.apiUrl}/api/v1/referral/list`, requestOptions).then(handleResponse);
}
function getAllUsersOfDepartment(data) {
    const requestOptions = { method: 'POST', headers: authHeader(), body: encData(data)};
    console.log("requestOptions: ", requestOptions)
    return fetch(`${config.apiUrl}/api/v1/users/department`, requestOptions).then(handleResponse);
}

function getIntakeFormById(id,data) {
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(data) };
    return fetch(`${config.apiUrl}/api/v1/intakeforms/${id}`, requestOptions).then(handleResponse);
}

function save(formData){
    console.log("save formData: ", formData);
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(formData) };
    return fetch(`${config.apiUrl}/api/v1/intakeforms/update`, requestOptions).then(handleResponse);
}
function saveMessage(formData){
    console.log("save formData: ", formData);
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(formData) };
    return fetch(`${config.apiUrl}/api/v1/internalMessages/create`, requestOptions).then(handleResponse);
}
function saveTaskReport(formData){
    console.log("save formData: ", formData);
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(formData) };
    return fetch(`${config.apiUrl}/api/v1/taskReports/create`, requestOptions).then(handleResponse);
}
function saveWorkCalendar(formData){
    console.log("save formData: ", formData);
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(formData) };
    return fetch(`${config.apiUrl}/api/v1/workCalendar/create`, requestOptions).then(handleResponse);
}
function editWorkCalendar(formData){
    console.log("save formData: ", formData);
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(formData) };
    return fetch(`${config.apiUrl}/api/v1/workCalendar/edit`, requestOptions).then(handleResponse);
}
function getMessages(formData){
    console.log("save formData: ", formData);
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(formData) };
    return fetch(`${config.apiUrl}/api/v1/internalMessages`, requestOptions).then(handleResponse);
}
function getTaskReports(formData){
    console.log("save formData: ", formData);
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(formData) };
    return fetch(`${config.apiUrl}/api/v1/taskReports`, requestOptions).then(handleResponse);
}
function getWorkCalendar(formData){
    console.log("save formData: ", formData);
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(formData) };
    return fetch(`${config.apiUrl}/api/v1/workCalendar`, requestOptions).then(handleResponse);
}
function createImportHistory(formData){
    console.log("save formData: ", formData);
    var data = new FormData()
    data.append('file', formData.selectedFile)
    data.append('remark', formData.remark)
    let header = authHeader()
    delete header['Content-Type']
    const requestOptions = { method: 'POST', headers:header, body:data };
    return fetch(`${config.apiUrl}/api/v1/importHistory/create`, requestOptions).then(handleResponse);
}
function getMessageById(formData){
    console.log("save formData: ", formData);
    const requestOptions = { method: 'GET', headers: authHeader()};
    return fetch(`${config.apiUrl}/api/v1/internalMessages/${formData}`, requestOptions).then(handleResponse);
}
function getAllImportHistory(formData){
    console.log("save formData: ", formData);
    const requestOptions = { method: 'GET', headers: authHeader()};
    return fetch(`${config.apiUrl}/api/v1/importHistory/`, requestOptions).then(handleResponse);
}

function removeUploadedDocument(formData){
    console.log("removeUploadedDocument formData: ", formData);
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(formData) };
    return fetch(`${config.apiUrl}/api/v1/intakeforms/removeUploadedDocument`, requestOptions).then(handleResponse);
}

function getAllDepartments() {
    const requestOptions = { method: 'GET', headers: authHeader()};
    console.log("requestOptions: ", requestOptions)
    return fetch(`${config.apiUrl}/api/v1/departments`, requestOptions).then(handleResponse);
}

function getAllChartData() {
    const requestOptions = { method: 'GET', headers: authHeader()};
    return fetch(`${config.apiUrl}/api/v1/chartData`, requestOptions).then(handleResponse);
}
function getAllChartDataByYear(data) {
    const requestOptions = { method: 'GET', headers: authHeader()};
    return fetch(`${config.apiUrl}/api/v1/chartData/chartDataByYear?year=${data}`, requestOptions).then(handleResponse);
}

function getAllCountries(){
    const requestOptions = { method: 'GET'};
    return fetch(`${config.getAllCountries}`, requestOptions).then(handleResponse);
}

function intakeFormAccessUpdate(formData){
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(formData) };
    return fetch(`${config.apiUrl}/api/v1/intakeforms/accessUpdate`, requestOptions).then(handleResponse);
}

function globleSearchForm(data){
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(data)};
    return fetch(`${config.apiUrl}/api/v1/intakeforms/globleSearchForm`, requestOptions).then(handleResponse);
}