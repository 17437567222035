import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Route } from "react-router-dom";
import Preloader from "../components/Preloader";
import { userService } from "../_services/admin";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PasswordMask from "react-password-mask";
import MaskedInput from "react-text-mask";
import { authenticationService } from "../_services";
import { Role } from "../_helpers";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import moment from "moment";

function mapStateToProps(state) {
  return {
    count: state.count,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

function validateEmail(value) {
  let error;
  if (!value) {
    error = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
}

class RegistrationPage extends Component {
  constructor(props) {
    super(props);

    // redirect to home if already logged in
    if (authenticationService.currentUserValue) {
      this.props.history.push("/");
    }
    this.state = {
      modal: false,
    };
  }
  componentDidMount() {
    let scripts = ["/assets/js/custom.min.js"];
    let host = window.location.origin;
    console.log("host", host);
    scripts.map((t) => {
      let script = document.createElement("script");
      script.src = host + t;
      script.async = false;
      document.body.appendChild(script);
      return true;
    });
  }

  toggleModal = () => {
    if (this.state.modal) {
      const { from } = this.props.location.state || {
        from: { pathname: "/" },
      };
      this.props.history.push(from);
    }

    this.setState((state) => {
      return { modal: !state.modal };
    });
  };

  render() {
    return (
      <React.Fragment>
        <Preloader />
        <section id="wrapper">
          <div
            className="signup-page"
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="login-box card">
              <div className="card-body">
                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    password: "",
                    confirmPassword: "",
                    email: "",
                    dob: "",
                    phoneNumber: "",
                    gender: "",
                    role: 1,
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().required("Email is required"),
                    password: Yup.string().when({
                      is: (val) => (val && val.length > 0 ? true : false),
                      then: Yup.string().when({
                        is: (inVal) =>
                          inVal && inVal.length > 0 ? true : false,
                        then: Yup.string()
                          .matches(
                            /^(?=.*[a-z])(?=.*[A-Z])/,
                            "Must have both uppercase and lowercase letters"
                          )
                          .matches(/^(?=.*[0-9])/, "Must consist of a number")
                          .matches(
                            /^(?=.{8,})/,
                            "Must be atleast 8 character long"
                          )
                          .required("Password is required"),
                      }),
                      otherwise: Yup.string()
                        .matches(
                          /^(?=.*[a-z])(?=.*[A-Z])/,
                          "Must have both uppercase and lowercase letters"
                        )
                        .matches(/^(?=.*[0-9])/, "Must consist of a number")
                        .matches(
                          /^(?=.{8,})/,
                          "Must be atleast 8 character long"
                        )
                        .required("Password is required"),
                    }),
                    confirmPassword: Yup.string().when("password", {
                      is: (val) => (val && val.length > 0 ? true : false),
                      then: Yup.string()
                        .oneOf(
                          [Yup.ref("password")],
                          "Must be same as Password"
                        )
                        .required("Confirm Password is required"),
                    }),
                    firstName: Yup.string().required("First Name is required"),
                    lastName: Yup.string().required("Last Name is required"),
                    phoneNumber: Yup.string()
                      .min(
                        14,
                        "Phone Number should contain minimum of 10 digits"
                      )
                      .max(
                        14,
                        "Phone Number should contain maximum of 10 digits"
                      ),
                  })}
                  onSubmit={(formData, { setStatus, setSubmitting }) => {
                    setStatus();
                    let userData = {
                      ...formData,
                    };

                    userService.register(userData).then(
                      (user) => {
                        setSubmitting(false);
                        this.setState({ modal: true });
                      },
                      (error) => {
                        setSubmitting(false);
                        setStatus(error);
                      }
                    );
                  }}
                  render={({
                    errors,
                    status,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    values,
                    handleChange,
                  }) => (
                    <Form>
                      <div
                        className="form-horizontal form-material"
                        id="loginform"
                      >
                        <img
                          className="loginlogo"
                          src={
                            window.location.origin +
                            "/assets/images/UHC_logo.png"
                          }
                        />
                        <h3 className="box-title m-b-20">Sign Up</h3>
                        <div className="form-group required">
                          <label className="control-label">First Name</label>
                          <Field
                            name="firstName"
                            type="text"
                            placeholder="Name"
                            className={
                              "form-control" +
                              (errors.firstName && touched.firstName
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="firstName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group required">
                          <label className="control-label"> Last Name</label>
                          <Field
                            name="lastName"
                            type="text"
                            placeholder="Last Name"
                            className={
                              "form-control" +
                              (errors.lastName && touched.lastName
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="lastName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group required">
                          <label className="control-label">Email</label>
                          <Field
                            name="email"
                            type="text"
                            placeholder="Email"
                            validate={validateEmail}
                            className={
                              "form-control" +
                              (errors.email && touched.email
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group required">
                          <label className="control-label">Password</label>
                          <Field
                            name="password"
                            placeholder="Password"
                            autoComplete="off"
                            render={({ field }) => (
                              <PasswordMask
                                inputStyles={{ border: "none" }}
                                buttonStyles={{
                                  top: "5px",
                                  backgroundColor: "transparent",
                                }}
                                showButtonContent={
                                  <i
                                    className="ti-eye"
                                    style={{
                                      color: "#ccc",
                                      backgroundColor: "transparent",
                                    }}
                                  ></i>
                                }
                                hideButtonContent={
                                  <i
                                    className="ti-eye"
                                    style={{ backgroundColor: "transparent" }}
                                  ></i>
                                }
                                onChange={(password) =>
                                  password === null
                                    ? setFieldValue("password", "")
                                    : setFieldValue("password", password)
                                }
                                {...field}
                                className={
                                  "form-control" +
                                  (errors.password && touched.password
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            )}
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group required">
                          <label className="control-label">
                            Confirm Password
                          </label>
                          <Field
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            autoComplete="off"
                            render={({ field }) => (
                              <PasswordMask
                                inputStyles={{ border: "none" }}
                                buttonStyles={{
                                  top: "5px",
                                  backgroundColor: "transparent",
                                }}
                                showButtonContent={
                                  <i
                                    className="ti-eye"
                                    style={{
                                      color: "#ccc",
                                      backgroundColor: "transparent",
                                    }}
                                  ></i>
                                }
                                hideButtonContent={
                                  <i
                                    className="ti-eye"
                                    style={{ backgroundColor: "transparent" }}
                                  ></i>
                                }
                                onChange={(confirmPassword) =>
                                  confirmPassword === null
                                    ? setFieldValue("confirmPassword", "")
                                    : setFieldValue(
                                        "confirmPassword",
                                        confirmPassword
                                      )
                                }
                                {...field}
                                className={
                                  "form-control" +
                                  (errors.confirmPassword &&
                                  touched.confirmPassword
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            )}
                          />
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <label className="control-label"> Phone Number</label>
                          <MaskedInput
                            mask={[
                              "(",
                              /[1-9]/,
                              /\d/,
                              /\d/,
                              ")",
                              " ",
                              /\d/,
                              /\d/,
                              /\d/,
                              "-",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            name="phoneNumber"
                            onChange={handleChange}
                            className={
                              "form-control" +
                              (errors.phoneNumber && touched.phoneNumber
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Phone Number"
                            guide={false}
                            type="text"
                            value={values.phoneNumber}
                            render={(ref, props) => (
                              <input
                                type="text"
                                label="Phone Number"
                                name="phoneNumber"
                                ref={ref}
                                value={values.phoneNumber}
                                {...props}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="phoneNumber"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group text-center mb-0 m-t-20">
                          <div className="col-xs-12">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light"
                            >
                              Submit
                            </button>
                            {isSubmitting && (
                              <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            )}
                          </div>
                        </div>
                        <div className="pt-2 text-center">
                          <span>
                            By creating an account, you agree to UHC's{" "}
                            <Link>Conditions of Use</Link> and{" "}
                            <Link>Privacy Notice.</Link>
                          </span>
                        </div>
                        <div className="pt-2 text-center">
                          <span>
                            Already have an account?{" "}
                            <Link to="/login">Sign in</Link>
                          </span>
                        </div>
                        {status && (
                          <div className={"alert alert-danger mt-2"}>
                            {status}
                          </div>
                        )}
                      </div>
                    </Form>
                  )}
                />
                <form className="form-horizontal" id="recoverform" action="#">
                  <div className="form-group ">
                    <div className="col-xs-12">
                      <h3>Recover Password</h3>
                      <p className="text-muted">
                        Enter your Email and instructions will be sent to you!{" "}
                      </p>
                    </div>
                  </div>
                  <div className="form-group ">
                    <div className="col-xs-12">
                      <input
                        className="form-control"
                        type="text"
                        required=""
                        placeholder="Email"
                      />{" "}
                    </div>
                  </div>
                  <div className="form-group text-center mb-0 m-t-20">
                    <div className="col-xs-12">
                      <button
                        className="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light"
                        type="submit"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Modal
            isOpen={this.state.modal}
            centered={true}
            toggle={this.toggleModal}
          >
            <ModalHeader toggle={this.toggleModal}>
              Account Verification
            </ModalHeader>
            <ModalBody>
              <h3>Thanks for signing up!</h3>
              <p>
                You will need to verify your email address in order to access
                your account.
              </p>
              <p>
                An email has been sent to your mail address with instruction for
                the same.
              </p>
              <p>Thanks, UHC Team.</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggleModal}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
