import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { NotificationManager } from "react-notifications";
import { authenticationService } from "../../_services";
import { eventService } from "../../_services/staff";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import EventInfoBox from "../../components/user/EventInfoBox";
import Avatar from "@material-ui/core/Avatar";
import { Tooltip } from "reactstrap";
import RegistrationConfirmation from "../../shared/RegistrationConfirmation";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ReactStars from "react-rating-stars-component";
import queryString from "query-string";
function mapStateToProps(state) {
  return {
    workshopChangeId: state.workshopChangeId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
  };
}

class WorkshopList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      userFromApi: null,
      eventList: [],
      registeredEventList: [],
      isHovering: false,
      selectedTab: "all",
      loading: true,
      profilePics: [],
      selectedWorkshopId: "",
      hasEvent: false,
      noEvent: false,
      feedbackModal: false,
      hasFoundHelpful: "",
      feedbackComment: "",
    };
    this.getAllCurrentEvents = this.getAllCurrentEvents.bind(this);
    this.handleFeedbackFormSubmit = this.handleFeedbackFormSubmit.bind(this);
  }

  componentDidMount() {
    const { currentUser } = this.state;
    this.getAllCurrentEvents();
    if (window.Websdk) {
      window.Websdk.q.push(["track_pageview", "workshop-list"]);
    }

    let queryParams = queryString.parse(this.props.location.search);
    if (queryParams.feedback) {
      this.toggleFeedbackModal(queryParams.feedback);
    }
  }

  componentDidUpdate(prevProps) {
    let prevWorkshopId = prevProps.workshopChangeId;
    let newWorkshopId = this.props.workshopChangeId;
    if (prevWorkshopId !== newWorkshopId) {
      //alert("Update state now!!");
      if (this.state.selectedTab === "all") {
        this.getAllCurrentEvents();
      }
    }
  }

  deleteRegistered = (id) => {
    let requestBody = {
      eventRegistrationId: id,
      currentUserId: this.state.currentUser.id,
    };

    eventService.deleteRegisteredEvents(requestBody).then((data) => {
      NotificationManager.success("Successfully Removed", "", 5000);
      this.getRegistered();
    });
  };

  deleteWishlisted = (id) => {
    let requestBody = {
      eventWishlistId: id,
      currentUserId: this.state.currentUser.id,
    };

    eventService.deleteWishlistedEvents(requestBody).then((data) => {
      NotificationManager.success("Successfully Removed", "", 5000);
      this.getWishlist();
    });
  };

  registerForEvent(eventId, index, link) {
    let registrationData = {
      userId: this.state.currentUser.id,
      userName: `${this.state.currentUser.firstName} ${this.state.currentUser.lastName}`,
      userEmail: this.state.currentUser.email,
      eventId: eventId,
      currentUserId: this.state.currentUser.id,
    };
    // document.getElementById(index).innerHTML= "Registered"
    eventService
      .registerEvent(registrationData)
      .then((data) => {
        if (data.Status) {
          NotificationManager.success("Successfully Registered", "", 5000);
          this.getAllCurrentEvents();
        } else {
          console.log("register event data on err: ", data);
        }
      })
      .catch((error) => NotificationManager.error(error, "", 5000));
  }

  getAllCurrentEvents() {
    this.setState({
      eventList: [],
    });
    eventService
      .getAllCurrentEvents(
        new Date().getTime(),
        this.state.currentUser.id,
        "workshop"
      )
      .then((data) => {
        if (data.Status && data.Data.length) {
          this.setState({
            eventList: data.Data,
            selectedTab: "all",
            hasEvent: true,
            noEvent: false,
          });
          // data.Data.forEach((element, index) => {
          //   this.generate(index);
          // });
          this.setState({
            loading: false,
          });
          this.getAll();
        } else {
          this.getAll();
          this.setState({
            loading: false,
            hasEvent: false,
            noEvent: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  }

  getAll = () => {
    document.getElementById("all").style.borderBottom = "5px solid #0f4e91";
    document.getElementById("registered").style.borderBottom = "none";
    document.getElementById("wishlist").style.borderBottom = "none";
    // this.getAllCurrentEvents()
  };

  getRegistered = () => {
    this.setState({
      eventList: [],
    });
    document.getElementById("registered").style.borderBottom =
      "5px solid #0f4e91";
    document.getElementById("all").style.borderBottom = "none";
    document.getElementById("wishlist").style.borderBottom = "none";
    eventService
      .getAllRegisteredEvents(this.state.currentUser.id)
      .then((data) => {
        if (data.Status && data.Data.length) {
          data.Data.forEach((item) => {
            if (item && item.eventDetails && item.eventDetails.date) {
              this.setState({
                eventList: [...this.state.eventList, item],
                selectedTab: "registered",
                hasEvent: true,
                noEvent: false,
              });
            }
          });
          // this.state.eventList.forEach((element, index) => {
          //   this.generate(index);
          // });
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            hasEvent: false,
            noEvent: true,
            loading: false,
          });
        }
      });
  };

  getWishlist = () => {
    this.setState({
      eventList: [],
    });
    document.getElementById("wishlist").style.borderBottom =
      "5px solid #0f4e91";
    document.getElementById("all").style.borderBottom = "none";
    document.getElementById("registered").style.borderBottom = "none";
    eventService
      .getAllWishlistEvents(this.state.currentUser.id)
      .then((data) => {
        if (data.Status && data.Data.length) {
          data.Data.forEach((item) => {
            if (item && item.eventDetails && item.eventDetails.date) {
              this.setState({
                eventList: [...this.state.eventList, item],
                selectedTab: "wishlist",
                hasEvent: true,
                noEvent: false,
              });
            }
          });
          // this.state.eventList.forEach((element, index) => {
          //   this.generate(index);
          // });
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            hasEvent: false,
            noEvent: true,
            loading: false,
          });
        }
      });
  };

  generate = (id, gradientColor) => {
    var gradients = [
      "linear-gradient(110deg, #53a2ff, #2a387b)",
      "linear-gradient(240deg, #ffaf8a, #ff6263)",
      "linear-gradient(240deg, #e0ec51, #2eb18d)",
      "linear-gradient(242deg, #f49cae, #b05ce2)",
      "linear-gradient(240deg, #8affe7, #5939e0)",
      "linear-gradient(240deg, #fddb92, #09adef)",
      "linear-gradient(240deg, #ffaf8a, #ff6263)",
      "linear-gradient(240deg, #bdd377, #09adef)",
      "linear-gradient(240deg, #0a7dd8, #21c8db)",
      "linear-gradient(240deg, #ff6364, #ff88bc)",
      "linear-gradient(240deg, #55e4ef, #c23af1)",
    ];
    if (gradientColor) {
      return gradientColor;
    } else {
      if (id >= gradients.length - 1) {
        return gradients[Math.floor(Math.random() * gradients.length)];
      } else {
        return gradients[id];
      }
    }
  };

  changeIconColor = (id, eventId) => {
    if (document.getElementById(id).style[0] === "color") {
      // document.getElementById(id).style.removeProperty('color');
      document.getElementById(id).disabled = true;
    } else {
      document.getElementById(id).style.color = "blue";
    }
    let whishlistData = {
      userId: this.state.currentUser.id,
      userName: `${this.state.currentUser.firstName} ${this.state.currentUser.lastName}`,
      userEmail: this.state.currentUser.email,
      eventId: eventId,
      currentUserId: this.state.currentUser.id,
    };
    eventService
      .addToWishlist(whishlistData)
      .then((data) => {
        if (data.Status) {
          NotificationManager.success("Added to Wishlist", "", 5000);
          this.getAllCurrentEvents();
        }
        // else {
        //   console.log("register event data on err: ", data)
        // }
      })
      .catch((error) => NotificationManager.error(error, "", 5000));
  };

  markAttended = (e, item) => {
    e.preventDefault();
    let requestBody = {
      eventId: item._id,
      currentUserId: this.state.currentUser.id,
    };

    eventService.markAttended(requestBody).then((data) => {});
    window.open(item.accessLink);
  };

  showIsRegistered = (item, index) => {
    var now = moment(new Date(item.dateTime));
    let endTime = moment(item.endTime);
    var end = moment(new Date());
    var duration = moment.duration(now.diff(end));
    let worshopEndDuration = moment.duration(end.diff(endTime));
    let endHours = worshopEndDuration.asHours();
    let startTime = moment(new Date(item.startTime));
    let isJoinable = end.isBetween(startTime.subtract(30, "minutes"), endTime);
    if (isJoinable) {
      return (
        <a
          href={item.accessLink}
          className="btn register_btn mr-3 w-100"
          onClick={(e) => this.markAttended(e, item)}
        >
          Join Now
        </a>
      );
    } else {
      if (
        !item.feedbackGiven &&
        item.hasAttended &&
        endHours > 0 &&
        endHours >= 1
      ) {
        return (
          <a
            id={index}
            className="btn btn-dark mr-3 register_btn w-100"
            onClick={(e) => this.toggleFeedbackModal(item._id)}
          >
            Give feedback
          </a>
        );
      } else {
        return (
          <a
            id={index}
            style={{ color: "white" }}
            className="btn btn-dark mr-3 register_btn w-100 disabled"
          >
            Registered
          </a>
        );
      }
    }
  };

  renderRegisterButton = (item, index) => {
    if (item) {
      let startTime = moment(new Date(item.startTime));
      let currentTime = moment();
      if (currentTime.isBefore(startTime)) {
        return (
          <a
            id={index}
            className="btn register_btn mr-3 w-100"
            onClick={() => document.getElementById(item._id).click()}
          >
            Register
          </a>
        );
      } else {
        return (
          <button id={index} className="btn register_btn mr-3 w-100" disabled>
            Closed
          </button>
        );
      }
    } else {
      return null;
    }
  };

  toggleFeedbackModal = (eventId) => {
    let stateObj = {
      feedbackModal: !this.state.feedbackModal,
    };
    if (eventId) {
      stateObj.selectedWorkshopId = eventId;
    }
    this.setState(stateObj);
  };

  handleRatingChanged = (rating) => {
    this.setState({ feedbackRatings: rating });
  };

  handleFoundHelpfulChanged = (e) => {
    this.setState({ hasFoundHelpful: e.target.value });
  };

  handleFeedbackComment = (event) => {
    this.setState({ feedbackComment: event.target.value });
  };

  handleFeedbackFormSubmit() {
    let {
      hasFoundHelpful,
      feedbackComment,
      feedbackRatings,
      selectedWorkshopId,
    } = this.state;

    if (!feedbackRatings) {
      NotificationManager.warning("Feedback Ratings is required!", "", 5000);
      return null;
    }

    if (!hasFoundHelpful) {
      NotificationManager.warning(
        "Please mention if whether or not you found workshop to be helpful!",
        "",
        5000
      );
      return null;
    }

    let formdata = {
      currentUserId: this.state.currentUser.id,
      hasFoundHelpful: hasFoundHelpful,
      comment: feedbackComment,
      rating: feedbackRatings,
      eventId: selectedWorkshopId,
    };

    eventService.saveWorkshopFeedback(formdata).then((data) => {
      this.toggleFeedbackModal();
      this.setState({
        feedbackComment: "",
        feedbackRatings: 0,
        hasFoundHelpful: "",
      });
      NotificationManager.success("Feedback submitted successfully!", "", 5000);
    });
  }

  render() {
    const { eventList } = this.state;
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Workshops</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card workshopList">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="align-self-center tbl-head">
                          <i className="mdi mdi-calendar-text mr-3"></i>
                          <span>UPCOMING WORKSHOPS</span>
                        </div>
                      </div>
                      <hr />
                      <nav className="navbar navbar-light workshop-Nav">
                        <ul
                          className="navbar-nav mr-auto"
                          style={{
                            flexDirection: "row",
                          }}
                        >
                          <li id="all" className="nav-item tabItems">
                            <a
                              className="nav-link"
                              href="#"
                              onClick={() => this.getAllCurrentEvents()}
                            >
                              All
                            </a>
                          </li>
                          <li id="registered" className="nav-item tabItems">
                            <a
                              className="nav-link"
                              href="#"
                              onClick={this.getRegistered}
                            >
                              Registered
                            </a>
                          </li>
                          <li id="wishlist" className="nav-item tabItems">
                            <a
                              className="nav-link"
                              href="#"
                              onClick={this.getWishlist}
                            >
                              Wishlist
                            </a>
                          </li>
                        </ul>
                      </nav>
                      <div className="d-flex groupofcard">
                        {eventList.length
                          ? eventList.map((item, index) => {
                              if (item && item.eventDetails) {
                                let registrationOrWishlistId = item._id;
                                let feedbackGiven = item.feedbackGiven
                                  ? true
                                  : false;
                                let hasAttended = item.hasAttended
                                  ? true
                                  : false;
                                item = item.eventDetails;
                                if (feedbackGiven) {
                                  item.feedbackGiven = true;
                                }
                                if (hasAttended) {
                                  item.hasAttended = true;
                                }
                                item.registrationOrWishlistId = registrationOrWishlistId;
                                if (this.state.selectedTab === "wishlist") {
                                  item.isWishlisted = true;
                                } else {
                                  item.isRegistered = true;
                                }
                              }
                              return (
                                <React.Fragment key={index}>
                                  <div
                                    className="card m-3 [ info-card ]"
                                    style={{
                                      width: "18rem",
                                    }}
                                  >
                                    <div
                                      id={"grad-" + index}
                                      className="d-flex py-3 border-bottom setheight"
                                      style={{
                                        background: this.generate(
                                          index,
                                          item.gradientColor
                                        ),
                                      }}
                                    >
                                      <div className="author col-xs-2 col-sm-3 align-self-center">
                                        <div className="profile-image d-flex">
                                          <img
                                            async
                                            alt="facilitator"
                                            src={
                                              item.facilitatorImage ||
                                              "/assets/images/user_placeholder.jpg"
                                            }
                                          />
                                        </div>
                                        <div className="facilitorname">
                                          {item.facilitatorName}
                                        </div>
                                      </div>
                                      <div className="event_name align-self-center">
                                        {item.name.toUpperCase()}
                                      </div>
                                    </div>
                                    <div className="datetimebox">
                                      <div>
                                        {moment(item.dateTime).format(
                                          "dddd, MMM Do YYYY"
                                        )}
                                      </div>
                                      {moment(item.startTime).format("hh:mm A")}{" "}
                                      -{moment(item.endTime).format("hh:mm A")}
                                    </div>
                                    {/* <img className="card-img-top" src="..." alt="Card image cap" /> */}
                                    <div className="card-body">
                                      <h3 className="card-title description_title">
                                        Description
                                      </h3>
                                      <div
                                        className="card-text description"
                                        dangerouslySetInnerHTML={{
                                          __html: item.description,
                                        }}
                                      ></div>
                                      <div className="d-flex event_location">
                                        <div className="d-flex mr-3">
                                          <div className="card-title mr-1">
                                            <i className="mdi mdi-map-marker"></i>
                                          </div>
                                          <div>{item.location || "Online"}</div>
                                        </div>
                                        {item.recurrence ? (
                                          <div className="d-flex mr-3">
                                            <div className="card-title mr-1">
                                              <i className="mdi mdi-alarm"></i>
                                            </div>
                                            <div>
                                              Held:{" "}
                                              <span className="text-capitalize">
                                                {item.recurrenceFrequency}
                                              </span>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="[ info-card-details ] animate">
                                      <div className="[ info-card-detail ]">
                                        <div className="details">
                                          {item.isRegistered && (
                                            <div className="">
                                              <i
                                                className="mdi mdi-delete deleteIcon cursor-pointer"
                                                style={{
                                                  right: "-8px",
                                                  color: "red",
                                                }}
                                                onClick={() =>
                                                  document
                                                    .getElementById(
                                                      "deleteConfirmationButton"
                                                    )
                                                    .click()
                                                }
                                              ></i>
                                              <DeleteConfirmation
                                                id={"deleteConfirmationButton"}
                                                confirm={() =>
                                                  this.deleteRegistered(
                                                    item.registrationOrWishlistId
                                                  )
                                                }
                                              ></DeleteConfirmation>
                                            </div>
                                          )}
                                          {item.isWishlisted && (
                                            <div className="">
                                              <i
                                                className="mdi mdi-delete deleteIcon cursor-pointer"
                                                style={{
                                                  right: "-8px",
                                                  color: "red",
                                                }}
                                                onClick={() =>
                                                  document
                                                    .getElementById(
                                                      "deleteConfirmationButton2"
                                                    )
                                                    .click()
                                                }
                                              ></i>
                                              <DeleteConfirmation
                                                id={"deleteConfirmationButton2"}
                                                confirm={() =>
                                                  this.deleteWishlisted(
                                                    item.registrationOrWishlistId
                                                  )
                                                }
                                              ></DeleteConfirmation>
                                            </div>
                                          )}

                                          <b>What You Will Learn:</b>
                                          <div
                                            className="text-left"
                                            dangerouslySetInnerHTML={{
                                              __html: item.whatWillYouLearn,
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card-footer">
                                      <div className="d-flex">
                                        {item.isRegistered ? (
                                          this.showIsRegistered(item, index)
                                        ) : (
                                          <React.Fragment>
                                            {this.renderRegisterButton(
                                              item,
                                              index
                                            )}
                                            <RegistrationConfirmation
                                              id={item._id}
                                              confirm={() =>
                                                this.registerForEvent(
                                                  item._id,
                                                  index,
                                                  item.accessLink
                                                )
                                              }
                                            ></RegistrationConfirmation>
                                          </React.Fragment>
                                        )}
                                        {item.isRegistered ? (
                                          <i
                                            id={"icon-" + item._id}
                                            className="mdi mdi-bookmark mdi-24px d-flex bookmark"
                                          ></i>
                                        ) : (
                                          <React.Fragment>
                                            {item.wishlisted ? (
                                              <i
                                                id={"icon-" + item._id}
                                                style={{
                                                  color: "blue",
                                                }}
                                                className="mdi mdi-bookmark mdi-24px d-flex bookmark"
                                              ></i>
                                            ) : (
                                              <i
                                                id={"icon-" + item._id}
                                                className="mdi mdi-bookmark mdi-24px d-flex bookmark cursor_pointer"
                                                onClick={() =>
                                                  this.changeIconColor(
                                                    "icon-" + item._id,
                                                    item._id
                                                  )
                                                }
                                              ></i>
                                            )}
                                          </React.Fragment>
                                        )}
                                      </div>

                                      {/* <small className="text-muted">Last updated 3 mins ago</small> */}
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            })
                          : this.state.noEvent && (
                              <div className="col-md-12 mt-4 mb-4">
                                <div
                                  style={{
                                    color: "rgba(0,0,0,.5)",
                                  }}
                                  className="text-center"
                                >
                                  <h3
                                    style={{
                                      color: "rgba(0,0,0,.5)",
                                    }}
                                  >
                                    No upcoming Workshops
                                  </h3>
                                </div>
                              </div>
                            )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Modal
              isOpen={this.state.feedbackModal}
              centered={true}
              toggle={this.toggleFeedbackModal}
            >
              <ModalHeader toggle={this.toggleFeedbackModal}>
                Your Feedback
              </ModalHeader>
              <ModalBody>
                <div className="text-center">
                  <p>
                    How likely are you to recommend Employment Ontario Services
                    to someone looking for similar services as those you
                    received?
                  </p>
                </div>
                <div>
                  <ReactStars
                    classNames="mx-auto"
                    count={5}
                    size={50}
                    isHalf={false}
                    activeColor="#ffd700"
                    emptyIcon={<i className="far fa-star" />}
                    filledIcon={<i className="fas fa-star" />}
                    onChange={this.handleRatingChanged}
                  />
                </div>
                <div>
                  <ol reversed="reversed">
                    <li>Strongly recommend</li>
                    <li>Generally recommend</li>
                    <li>No general opinion</li>
                    <li>Rather not recommend</li>
                    <li>Strongly not recommend</li>
                  </ol>
                </div>
                <hr />
                <div className="form-group required">
                  <label className="control-label">
                    Did you find this workshop beneficial to your needs?
                  </label>
                  <br />
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      name="foundHelpful"
                      value="yes"
                      id="found-helpful-yes"
                      checked={this.state.hasFoundHelpful === "yes"}
                      onChange={this.handleFoundHelpfulChanged}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="found-helpful-yes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      name="foundHelpful"
                      value="no"
                      id="found-helpful-no"
                      checked={this.state.hasFoundHelpful === "no"}
                      onChange={this.handleFoundHelpfulChanged}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="found-helpful-no"
                    >
                      No
                    </label>
                  </div>
                </div>
                <hr />
                <label>
                  Can you recommend other resources that may be helpful?
                </label>
                <textarea
                  className="form-control"
                  name="feedback-comment"
                  onChange={this.handleFeedbackComment}
                  value={this.state.feedbackComment}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.handleFeedbackFormSubmit}>
                  Submit
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkshopList);
