import React, { Component } from "react";
// import "./homepage.css";
import { Link, Route } from "react-router-dom";
import Preloader from "../components/Preloader";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { authenticationService } from "../_services";
import { Role } from "../_helpers";
import ReCAPTCHA from "react-google-recaptcha";
import fb from "./images/FB-navtop.gif";
import twi from "./images/Twitter-nav.gif";
import yt from "./images/YT-Nav.gif";
export default class HomePage extends Component {
  constructor(props) {
    super(props);

    this.linkList = [];

    if (authenticationService.currentUserValue) {
      this.props.history.push("/dashboard");
    }
  }

  componentDidMount() {
    document.body.className =
      "page-template page-template-page-community-kitchen page-template-page-community-kitchen-php page page-id-280 page-child parent-pageid-266";
    let link = document.createElement("link");
    link.href = "./homepage.css";
    link.rel = "stylesheet";
    this.linkList.push(link);
    document.head.appendChild(link);
  }

  componentWillUnmount() {
    this.linkList.forEach((link) => {
      link.remove();
    });
    document.body.className = "";
  }

  render() {
    return (
      <div
        className="page-template page-template-template-no-header page-template-template-no-header-php page page-id-4210 groovy_menu_2-6-3 material wpb-js-composer js-comp-ver-6.6.0 vc_responsive"
        data-footer-reveal="false"
        data-footer-reveal-shadow="none"
        data-header-format="default"
        data-body-border="off"
        data-boxed-style=""
        data-header-breakpoint="1610"
        data-dropdown-style="minimal"
        data-cae="easeOutCubic"
        data-cad="750"
        data-megamenu-width="contained"
        data-aie="none"
        data-ls="fancybox"
        data-apte="standard"
        data-hhun="1"
        data-fancy-form-rcs="default"
        data-form-style="default"
        data-form-submit="regular"
        data-is="minimal"
        data-button-style="slightly_rounded_shadow"
        data-user-account-button="false"
        data-flex-cols="true"
        data-col-gap="default"
        data-header-inherit-rc="false"
        data-header-search="false"
        data-animated-anchors="true"
        data-ajax-transitions="false"
        data-full-width-header="true"
        data-slide-out-widget-area="true"
        data-slide-out-widget-area-style="slide-out-from-right"
        data-user-set-ocm="off"
        data-loading-animation="none"
        data-bg-header="true"
        data-responsive="1"
        data-ext-responsive="true"
        data-ext-padding="90"
        data-header-resize="0"
        data-header-color="custom"
        data-cart="false"
        data-remove-m-parallax=""
        data-remove-m-video-bgs=""
        data-m-animate="0"
        data-force-header-trans-color="light"
        data-smooth-scrolling="0"
        data-permanent-transparent="false"
      >

        <header
          className="gm-navbar gm-preset-id-3179 gm-navbar--align-left gm-navbar--style-1 gm-top-links-align-right gm-navbar--toolbar-true gm-navbar--has-shadow gm-navbar--shadow-tiny gm-navbar--has-shadow-sticky gm-navbar--has-shadow-dropdown gm-navbar--shadow-dropdown-medium gm-navbar--hide-gm-caret gm-dropdown-hover-style-fadein-link-color gm-dropdown-appearance-animate-from-bottom gm-dropdown-with-scrollbar"
          id="gm-64232105ed1a3"
          data-version="2.6.3"
        >
          <div className="gm-wrapper">
            <div className="gm-toolbar" id="gm-toolbar">
              <div className="gm-toolbar-bg"></div>
              <div className="gm-container gm-block-container">
                <div
                  id="fws_64232105edf53"
                  data-column-margin="default"
                  data-midnight="dark"
                  className="wpb_row vc_row-fluid vc_row  vc_row-o-equal-height vc_row-flex  vc_row-o-content-middle  "
                  style={{
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    marginBottom: "0px",
                  }}
                >
                  <div
                    className="row-bg-wrap"
                    data-bg-animation="none"
                    data-bg-overlay="false"
                  >
                    <div className="inner-wrap">
                      <div className="row-bg"></div>
                    </div>
                  </div>
                  <div className="row_col_wrap_12 col span_12 dark right">
                    <div
                      style={{ marginTop: "15px", padding: "0px" }}
                      className="vc_col-sm-3 vc_col-lg-3 vc_col-md-4 vc_col-xs-6 wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                      data-padding-pos="all"
                      data-has-bg-color="false"
                      data-bg-color=""
                      data-bg-opacity="1"
                      data-animation=""
                      data-delay="0"
                    >
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <p
                                style={{
                                  textAlign: "left",
                                  fontSize: "12px",
                                  paddingBottom: "18px",
                                }}
                              >
                                <a href="https://www.uhc.ca/create-account-login/">
                                  <i className="icon-tiny fa fa-user accent-color"></i>{" "}
                                  Create Account / Login
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="vc_col-sm-3 vc_col-lg-3 vc_col-md-4 vc_hidden-md vc_hidden-sm vc_hidden-xs wpb_column column_container vc_column_container col centered-text no-extra-padding inherit_tablet inherit_phone "
                      data-padding-pos="all"
                      data-has-bg-color="false"
                      data-bg-color=""
                      data-bg-opacity="1"
                      data-animation=""
                      data-delay="0"
                    >
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper"></div>
                      </div>
                    </div>

                    <div
                      className="vc_col-sm-3 vc_col-lg-3 vc_col-md-4 vc_hidden-sm vc_col-xs-6 vc_hidden-xs wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                      data-padding-pos="all"
                      data-has-bg-color="false"
                      data-bg-color=""
                      data-bg-opacity="1"
                      data-animation=""
                      data-delay="0"
                    >
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <a
                            className="nectar-button small regular accent-color  regular-button small"
                            href="https://www.uhc.ca/donate/"
                            data-color-override="false"
                            data-hover-color-override="false"
                            data-hover-text-color-override="#fff"
                          >
                            <span>Donate</span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div
                      className="vc_col-sm-3 vc_col-lg-3 vc_col-md-4 vc_col-xs-6 wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                      data-padding-pos="all"
                      data-has-bg-color="false"
                      data-bg-color=""
                      data-bg-opacity="1"
                      data-animation=""
                      data-delay="0"
                    >
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <div className="wpb_widgetised_column wpb_content_element">
                            <div
                              id="sidebar"
                              data-nectar-ss=""
                              className="wpb_wrapper"
                            >
                              <div
                                id="search-3"
                                className="widget widget_search"
                              >
                                <form
                                  role="search"
                                  method="get"
                                  className="search-form"
                                  action="https://www.uhc.ca/"
                                >
                                  <input
                                    type="text"
                                    className="search-field"
                                    placeholder="Search..."
                                    value=""
                                    name="s"
                                    title="Search for:"
                                  />
                                  <button
                                    type="submit"
                                    className="search-widget-btn"
                                  >
                                    <span
                                      className="normal icon-salient-search"
                                      aria-hidden="true"
                                    ></span>
                                    <span className="text">Search</span>
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gm-inner" style={{ margin: "0px 0px" }}>
              <div className="gm-inner-bg"></div>
              <div className="gm-container">
                <div className="gm-logo">
                  <a href="https://www.uhc.ca/">
                    <img
                      src="https://www.uhc.ca/wp-content/uploads/2021/04/logo-dark.png"
                      width="277"
                      height="60"
                      className="gm-logo__img gm-logo__img-default"
                      alt="Unemployed Help Centre of Windsor Inc."
                    />
                    <img
                      src="https://www.uhc.ca/wp-content/uploads/2021/04/mobile-dark.png"
                      width="185"
                      height="40"
                      className="gm-logo__img gm-logo__img-sticky"
                      alt="Unemployed Help Centre of Windsor Inc."
                    />
                    <img
                      src="https://www.uhc.ca/wp-content/uploads/2021/04/mobile-dark.png"
                      width="185"
                      height="40"
                      className="gm-logo__img gm-logo__img-mobile"
                      alt="Unemployed Help Centre of Windsor Inc."
                    />
                    <img
                      src="https://www.uhc.ca/wp-content/uploads/2021/04/mobile-dark.png"
                      width="185"
                      height="40"
                      className="gm-logo__img gm-logo__img-sticky-mobile"
                      alt="Unemployed Help Centre of Windsor Inc."
                    />
                  </a>
                </div>
                <div className="gm-menu-btn gm-burger hamburger">
                  <div className="hamburger-box">
                    <div className="hamburger-inner"></div>
                  </div>
                </div>
                <div className="gm-main-menu-wrapper">
                  <nav id="gm-main-menu">
                    <ul id="menu-groovy-menu" className="gm-navbar-nav">
                      <li
                        id="menu-item-3559"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home gm-menu-item gm-menu-item--lvl-0"
                      >
                        <a href="https://www.uhc.ca/" className="gm-anchor">
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">Home</span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-3591"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown mega-gm-dropdown"
                      >
                        <div className="gm-anchor gm-dropdown-toggle gm-anchor--empty">
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt gm-menu-item__txt-empty-url">
                              About
                            </span>
                          </span>
                          <span className="gm-caret" aria-label="dropdown">
                            <i className="fa fa-fw fa-angle-down"></i>
                          </span>
                        </div>
                        <div className="gm-dropdown-menu-wrapper">
                          <ul className="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                            <li>
                              <div className="gm-mega-menu-wrapper">
                                <div className="gm-grid-container">
                                  <div className="gm-grid-row">
                                    <div className="gm-mega-menu__item mobile-grid-100 grid-100">
                                      <div
                                        id="fws_64232105f416a"
                                        data-column-margin="60px"
                                        data-midnight="light"
                                        className="wpb_row vc_row-fluid vc_row  vc_row-o-equal-height vc_row-flex  vc_row-o-content-top  green"
                                        style={{
                                          paddingTop: "20px",
                                          paddingBottom: "20px",
                                        }}
                                      >
                                        <div
                                          className="row-bg-wrap"
                                          data-bg-animation="none"
                                          data-bg-overlay="false"
                                        >
                                          <div className="inner-wrap">
                                            <div className="row-bg"></div>
                                          </div>
                                        </div>
                                        <div className="row_col_wrap_12 col span_12 light left">
                                          <div
                                            className="vc_col-sm-4 vc_hidden-sm vc_hidden-xs wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-using-bg="true"
                                            data-bg-cover="true"
                                            data-padding-pos="all"
                                            data-has-bg-color="false"
                                            data-bg-color=""
                                            data-bg-opacity="1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div
                                                className="column-image-bg-wrap"
                                                data-bg-pos="center center"
                                                data-bg-animation="none"
                                                data-bg-overlay="false"
                                              >
                                                <div className="inner-wrap">
                                                  <div
                                                    className="column-image-bg"
                                                    style={{
                                                      backgroundImage:
                                                        "url('https://www.uhc.ca/wp-content/uploads/2021/02/about_interior.jpg')",
                                                    }}
                                                  ></div>
                                                </div>
                                              </div>
                                              <div className="wpb_wrapper"></div>
                                            </div>
                                          </div>

                                          <div
                                            style={{ color: "#ffffff" }}
                                            className="vc_col-sm-4 vc_hidden-sm vc_hidden-xs wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-padding-pos="all"
                                            data-has-bg-color="false"
                                            data-bg-color=""
                                            data-bg-opacity="1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <p>
                                                      <strong>About</strong>
                                                    </p>
                                                    <p>
                                                      Founded in 1977, UHC- Hub
                                                      of Opportunities is a
                                                      registered not-for-profit
                                                      charitable organization
                                                      dedicated to meeting the
                                                      needs of vulnerable people
                                                      living in Windsor and
                                                      Essex County. We improve
                                                      the quality of lives by
                                                      providing much needed
                                                      programs and services that
                                                      move people to
                                                      independence and allow
                                                      them to achieve their
                                                      desired outcomes. Through
                                                      offering 26 unique
                                                      programs and services, we
                                                      aim to focus on four
                                                      pillars of support &amp;
                                                      guidance for the
                                                      Windsor-Essex community –
                                                      basic human needs,
                                                      employment services,
                                                      training and education,
                                                      and newcomer settlement
                                                      services. We are a ‘hub’
                                                      based in the community,
                                                      for the community.
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="vc_col-sm-4 wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-using-bg="true"
                                            data-padding-pos="all"
                                            data-has-bg-color="true"
                                            data-bg-color="#000000"
                                            data-bg-opacity="0.1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div
                                                className="column-bg-overlay-wrap"
                                                data-bg-animation="none"
                                              >
                                                <div
                                                  className="column-bg-overlay"
                                                  style={{
                                                    opacity: "0.1",
                                                    backgroundColor: "#000000",
                                                  }}
                                                ></div>
                                              </div>
                                              <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <div
                                                      className="wpb_category_n_menu_accordion"
                                                      data-accordion="true"
                                                      data-indicator_icon="+"
                                                      data-iconclass=""
                                                    >
                                                      <ul
                                                        id="menu-about"
                                                        className="wpb_category_n_menu_accordion_list"
                                                      >
                                                        <li
                                                          id="menu-item-3897"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3897"
                                                        >
                                                          <a href="https://www.uhc.ca/about/">
                                                            About Us
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3898"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3898"
                                                        >
                                                          <a href="https://www.uhc.ca/programs-services/">
                                                            Programs &#038;
                                                            Services
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3899"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3899"
                                                        >
                                                          <a href="https://www.uhc.ca/customer-service-charter/">
                                                            Customer Service
                                                            Charter
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3900"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3900"
                                                        >
                                                          <a href="https://www.uhc.ca/privacy-policy/">
                                                            Privacy Policy
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3901"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3901"
                                                        >
                                                          <a href="https://www.uhc.ca/covid-19-update/">
                                                            COVID-19 Update
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3902"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3902"
                                                        >
                                                          <a href="https://www.uhc.ca/uhc-in-the-news/">
                                                            UHC in the News
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3903"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3903"
                                                        >
                                                          <a href="https://www.uhc.ca/contact-us/">
                                                            Contact Us
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li
                        id="menu-item-3654"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown mega-gm-dropdown"
                      >
                        <div className="gm-anchor gm-dropdown-toggle gm-anchor--empty">
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt gm-menu-item__txt-empty-url">
                              Calendars
                            </span>
                          </span>
                          <span className="gm-caret" aria-label="dropdown">
                            <i className="fa fa-fw fa-angle-down"></i>
                          </span>
                        </div>
                        <div className="gm-dropdown-menu-wrapper">
                          <ul className="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                            <li>
                              <div className="gm-mega-menu-wrapper">
                                <div className="gm-grid-container">
                                  <div className="gm-grid-row">
                                    <div className="gm-mega-menu__item mobile-grid-100 grid-100">
                                      <div
                                        id="fws_642321060551c"
                                        data-column-margin="60px"
                                        data-midnight="light"
                                        className="wpb_row vc_row-fluid vc_row  vc_row-o-equal-height vc_row-flex  vc_row-o-content-top  green"
                                        style={{
                                          paddingTop: "20px",
                                          paddingBottom: "20px",
                                        }}
                                      >
                                        <div
                                          className="row-bg-wrap"
                                          data-bg-animation="none"
                                          data-bg-overlay="false"
                                        >
                                          <div className="inner-wrap">
                                            <div className="row-bg"></div>
                                          </div>
                                        </div>
                                        <div className="row_col_wrap_12 col span_12 light left">
                                          <div
                                            className="vc_col-sm-4 vc_hidden-sm vc_hidden-xs wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-using-bg="true"
                                            data-bg-cover="true"
                                            data-padding-pos="all"
                                            data-has-bg-color="false"
                                            data-bg-color=""
                                            data-bg-opacity="1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div
                                                className="column-image-bg-wrap"
                                                data-bg-pos="center center"
                                                data-bg-animation="none"
                                                data-bg-overlay="false"
                                              >
                                                <div className="inner-wrap">
                                                  <div
                                                    className="column-image-bg"
                                                    style={{
                                                      backgroundImage:
                                                        "url('https://www.uhc.ca/wp-content/uploads/2021/02/Calendar-header.jpg')",
                                                    }}
                                                  ></div>
                                                </div>
                                              </div>
                                              <div className="wpb_wrapper"></div>
                                            </div>
                                          </div>

                                          <div
                                            style={{ color: "#ffffff" }}
                                            className="vc_col-sm-4 vc_hidden-sm vc_hidden-xs wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-padding-pos="all"
                                            data-has-bg-color="false"
                                            data-bg-color=""
                                            data-bg-opacity="1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <p>
                                                      <strong>Calendars</strong>
                                                    </p>
                                                    <p>
                                                      There is something for
                                                      everyone at UHC! We have
                                                      gathered our special
                                                      events, workshops, and
                                                      training courses in one
                                                      convenient place for you
                                                      to review and signup.
                                                      Explore all that we offer
                                                      today!
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="vc_col-sm-4 wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-using-bg="true"
                                            data-padding-pos="all"
                                            data-has-bg-color="true"
                                            data-bg-color="#000000"
                                            data-bg-opacity="0.1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div
                                                className="column-bg-overlay-wrap"
                                                data-bg-animation="none"
                                              >
                                                <div
                                                  className="column-bg-overlay"
                                                  style={{
                                                    opacity: "0.1",
                                                    backgroundColor: "#000000",
                                                  }}
                                                ></div>
                                              </div>
                                              <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <div
                                                      className="wpb_category_n_menu_accordion"
                                                      data-accordion="true"
                                                      data-indicator_icon="+"
                                                      data-iconclass=""
                                                    >
                                                      <ul
                                                        id="menu-calendars"
                                                        className="wpb_category_n_menu_accordion_list"
                                                      >
                                                        <li
                                                          id="menu-item-3913"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3913"
                                                        >
                                                          <a href="https://www.uhc.ca/events-workshops/">
                                                            Events &#038;
                                                            Workshops
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li
                        id="menu-item-3560"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown mega-gm-dropdown"
                      >
                        <div className="gm-anchor gm-dropdown-toggle gm-anchor--empty">
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt gm-menu-item__txt-empty-url">
                              Job Seekers
                            </span>
                          </span>
                          <span className="gm-caret" aria-label="dropdown">
                            <i className="fa fa-fw fa-angle-down"></i>
                          </span>
                        </div>
                        <div className="gm-dropdown-menu-wrapper">
                          <ul className="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                            <li>
                              <div className="gm-mega-menu-wrapper">
                                <div className="gm-grid-container">
                                  <div className="gm-grid-row">
                                    <div className="gm-mega-menu__item mobile-grid-100 grid-100">
                                      <div
                                        id="fws_6423210609169"
                                        data-column-margin="60px"
                                        data-midnight="light"
                                        className="wpb_row vc_row-fluid vc_row  vc_row-o-equal-height vc_row-flex  vc_row-o-content-top  green"
                                        style={{
                                          paddingTop: "20px",
                                          paddingBottom: "20px",
                                        }}
                                      >
                                        <div
                                          className="row-bg-wrap"
                                          data-bg-animation="none"
                                          data-bg-overlay="false"
                                        >
                                          <div className="inner-wrap">
                                            <div className="row-bg"></div>
                                          </div>
                                        </div>
                                        <div className="row_col_wrap_12 col span_12 light left">
                                          <div
                                            className="vc_col-sm-4 vc_hidden-sm vc_hidden-xs wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-using-bg="true"
                                            data-bg-cover="true"
                                            data-padding-pos="all"
                                            data-has-bg-color="false"
                                            data-bg-color=""
                                            data-bg-opacity="1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div
                                                className="column-image-bg-wrap"
                                                data-bg-pos="center center"
                                                data-bg-animation="none"
                                                data-bg-overlay="false"
                                              >
                                                <div className="inner-wrap">
                                                  <div
                                                    className="column-image-bg"
                                                    style={{
                                                      backgroundImage:
                                                        "url('https://www.uhc.ca/wp-content/uploads/2021/06/menu-jobseekers.jpg')",
                                                    }}
                                                  ></div>
                                                </div>
                                              </div>
                                              <div className="wpb_wrapper"></div>
                                            </div>
                                          </div>

                                          <div
                                            style={{ color: "#ffffff" }}
                                            className="vc_col-sm-4 vc_hidden-sm vc_hidden-xs wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-padding-pos="all"
                                            data-has-bg-color="false"
                                            data-bg-color=""
                                            data-bg-opacity="1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <p>
                                                      <strong>
                                                        Job Seekers
                                                      </strong>
                                                    </p>
                                                    <p>
                                                      Ready to make a change?
                                                      You can start browsing
                                                      <a href="/job-post-board/">
                                                        current job
                                                        opportunities
                                                      </a>
                                                      today or take advantage of
                                                      UHC’s skills training to
                                                      increase your confidence
                                                      by attending workshop such
                                                      as resume writing,
                                                      interview skills
                                                      techniques, computer
                                                      training or get one-on-one
                                                      support and guidance
                                                      through our Employment
                                                      Counsellors. Don’t forget:
                                                      our{" "}
                                                      <a href="/walk-in-resource-and-information-centre/">
                                                        Walk-in Resource &amp;
                                                        Information Centre
                                                      </a>{" "}
                                                      is available to you during
                                                      our regular business
                                                      hours.
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="vc_col-sm-4 wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-using-bg="true"
                                            data-padding-pos="all"
                                            data-has-bg-color="true"
                                            data-bg-color="#000000"
                                            data-bg-opacity="0.1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div
                                                className="column-bg-overlay-wrap"
                                                data-bg-animation="none"
                                              >
                                                <div
                                                  className="column-bg-overlay"
                                                  style={{
                                                    opacity: "0.1",
                                                    backgroundColor: "#000000",
                                                  }}
                                                ></div>
                                              </div>
                                              <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <div
                                                      className="wpb_category_n_menu_accordion"
                                                      data-accordion="true"
                                                      data-indicator_icon="+"
                                                      data-iconclass=""
                                                    >
                                                      <ul
                                                        id="menu-job-seekers"
                                                        className="wpb_category_n_menu_accordion_list"
                                                      >
                                                        <li
                                                          id="menu-item-4205"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4205"
                                                        >
                                                          <a href="https://www.uhc.ca/job-post-board/">
                                                            Job Board
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3581"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3581"
                                                        >
                                                          <a href="https://www.uhc.ca/walk-in-resource-and-information-centre/">
                                                            Walk-In Resource and
                                                            Information Centre
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3582"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3582"
                                                        >
                                                          <a href="https://www.uhc.ca/employment-counselling/">
                                                            One-On-One Career
                                                            Counselling
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3583"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-3583"
                                                        >
                                                          <a href="https://www.uhc.ca/employment-programs/">
                                                            Employment Programs
                                                          </a>
                                                          <ul className="sub-menu">
                                                            <li
                                                              id="menu-item-3585"
                                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3585"
                                                            >
                                                              <a href="https://www.uhc.ca/youth-job-connection/">
                                                                Youth Job
                                                                Connection
                                                              </a>
                                                            </li>
                                                            <li
                                                              id="menu-item-3584"
                                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3584"
                                                            >
                                                              <a href="https://www.uhc.ca/youth-job-connection-summer/">
                                                                Youth Job
                                                                Connection
                                                                Summer
                                                              </a>
                                                            </li>
                                                            <li
                                                              id="menu-item-3631"
                                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3631"
                                                            >
                                                              <a href="https://www.uhc.ca/cook-pre-apprenticeship-program/">
                                                                Cook
                                                                Pre-Apprenticeship
                                                                Program
                                                              </a>
                                                            </li>
                                                            <li
                                                              id="menu-item-3632"
                                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3632"
                                                            >
                                                              <a href="https://www.uhc.ca/ready-to-work/">
                                                                Ready to Work
                                                                Program
                                                              </a>
                                                            </li>
                                                          </ul>
                                                        </li>
                                                        <li
                                                          id="menu-item-3634"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-3634"
                                                        >
                                                          <a href="https://www.uhc.ca/training-education/">
                                                            Training &#038;
                                                            Education
                                                          </a>
                                                          <ul className="sub-menu">
                                                            <li
                                                              id="menu-item-3635"
                                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3635"
                                                            >
                                                              <a href="https://www.uhc.ca/employment-skills-training/">
                                                                Employment
                                                                Skills Training
                                                              </a>
                                                            </li>
                                                            <li
                                                              id="menu-item-3636"
                                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3636"
                                                            >
                                                              <a href="https://www.uhc.ca/better-jobs-ontario/">
                                                                Better Jobs
                                                                Ontario
                                                              </a>
                                                            </li>
                                                            <li
                                                              id="menu-item-3637"
                                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3637"
                                                            >
                                                              <a href="https://www.uhc.ca/literacy-and-basic-skills/">
                                                                Literacy and
                                                                Basic Skills
                                                              </a>
                                                            </li>
                                                            <li
                                                              id="menu-item-3638"
                                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3638"
                                                            >
                                                              <a href="https://www.uhc.ca/ged-preparation-program/">
                                                                GED Preparation
                                                                Program
                                                              </a>
                                                            </li>
                                                            <li
                                                              id="menu-item-3639"
                                                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3639"
                                                            >
                                                              <a href="https://www.uhc.ca/computer-workshops/">
                                                                Computer
                                                                Workshops
                                                              </a>
                                                            </li>
                                                          </ul>
                                                        </li>
                                                        <li
                                                          id="menu-item-4342"
                                                          className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-4342"
                                                        >
                                                          <a
                                                            href="/create-account-login/"
                                                            aria-current="page"
                                                          >
                                                            Forms, Tools &#038;
                                                            Resources
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li
                        id="menu-item-3655"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown mega-gm-dropdown"
                      >
                        <a
                          href="https://www.uhc.ca/employers/"
                          className="gm-anchor gm-dropdown-toggle"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">Employers</span>
                          </span>
                          <span className="gm-caret" aria-label="dropdown">
                            <i className="fa fa-fw fa-angle-down"></i>
                          </span>
                        </a>
                        <div className="gm-dropdown-menu-wrapper">
                          <ul className="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                            <li>
                              <div className="gm-mega-menu-wrapper">
                                <div className="gm-grid-container">
                                  <div className="gm-grid-row">
                                    <div className="gm-mega-menu__item mobile-grid-100 grid-100">
                                      <div
                                        id="fws_642321060e8e4"
                                        data-column-margin="60px"
                                        data-midnight="light"
                                        className="wpb_row vc_row-fluid vc_row  vc_row-o-equal-height vc_row-flex  vc_row-o-content-top  green"
                                        style={{
                                          paddingTop: "20px",
                                          paddingBottom: "20px",
                                        }}
                                      >
                                        <div
                                          className="row-bg-wrap"
                                          data-bg-animation="none"
                                          data-bg-overlay="false"
                                        >
                                          <div className="inner-wrap">
                                            <div className="row-bg"></div>
                                          </div>
                                        </div>
                                        <div className="row_col_wrap_12 col span_12 light left">
                                          <div
                                            className="vc_col-sm-4 vc_hidden-sm vc_hidden-xs wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-using-bg="true"
                                            data-bg-cover="true"
                                            data-padding-pos="all"
                                            data-has-bg-color="false"
                                            data-bg-color=""
                                            data-bg-opacity="1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div
                                                className="column-image-bg-wrap"
                                                data-bg-pos="center center"
                                                data-bg-animation="none"
                                                data-bg-overlay="false"
                                              >
                                                <div className="inner-wrap">
                                                  <div
                                                    className="column-image-bg"
                                                    style={{
                                                      backgroundImage:
                                                        "url('https://www.uhc.ca/wp-content/uploads/2021/06/forms-tools-employment-services-pg.jpg')",
                                                    }}
                                                  ></div>
                                                </div>
                                              </div>
                                              <div className="wpb_wrapper"></div>
                                            </div>
                                          </div>

                                          <div
                                            style={{ color: "#ffffff" }}
                                            className="vc_col-sm-4 vc_hidden-sm vc_hidden-xs wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-padding-pos="all"
                                            data-has-bg-color="false"
                                            data-bg-color=""
                                            data-bg-opacity="1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <p>
                                                      <strong>Employers</strong>
                                                    </p>
                                                    <p>
                                                      Every community is made
                                                      stronger with good
                                                      partnerships. UHC is here
                                                      to assist employers by
                                                      connecting them with
                                                      potential candidates to
                                                      fulfill jobs within their
                                                      business and/or navigate
                                                      government-led programs
                                                      like Canada-Ontario Job
                                                      Connect. We have
                                                      incentives available to
                                                      help offset your costs.
                                                      Let’s team up and change
                                                      lives!
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="vc_col-sm-4 wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-using-bg="true"
                                            data-padding-pos="all"
                                            data-has-bg-color="true"
                                            data-bg-color="#000000"
                                            data-bg-opacity="0.1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div
                                                className="column-bg-overlay-wrap"
                                                data-bg-animation="none"
                                              >
                                                <div
                                                  className="column-bg-overlay"
                                                  style={{
                                                    opacity: "0.1",
                                                    backgroundColor: "#000000",
                                                  }}
                                                ></div>
                                              </div>
                                              <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <div
                                                      className="wpb_category_n_menu_accordion"
                                                      data-accordion="true"
                                                      data-indicator_icon="+"
                                                      data-iconclass=""
                                                    >
                                                      <ul
                                                        id="menu-employers"
                                                        className="wpb_category_n_menu_accordion_list"
                                                      >
                                                        <li
                                                          id="menu-item-3922"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3922"
                                                        >
                                                          <a href="https://www.uhc.ca/post-a-job/">
                                                            Hiring (Job
                                                            Positions)
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3923"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3923"
                                                        >
                                                          <a href="https://www.uhc.ca/job-matching-placement-incentives/">
                                                            Job Matching
                                                            Placement Incentives
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3924"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3924"
                                                        >
                                                          <a href="https://www.uhc.ca/canada-ontario-job-grant/">
                                                            Canada Ontario Job
                                                            Grant
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3925"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3925"
                                                        >
                                                          <a href="https://www.uhc.ca/closures/">
                                                            Closures
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3926"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3926"
                                                        >
                                                          <a href="https://www.uhc.ca/pre-apprenticeship/">
                                                            Pre-Apprenticeship
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3927"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3927"
                                                        >
                                                          <a href="https://www.uhc.ca/employer-workshops/">
                                                            Employer Workshops
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3928"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3928"
                                                        >
                                                          <a href="https://www.uhc.ca/forms-tools-resources/">
                                                            Forms, Tools &#038;
                                                            Resources
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li
                        id="menu-item-3656"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown mega-gm-dropdown"
                      >
                        <a
                          href="https://www.uhc.ca/newcomers/"
                          className="gm-anchor gm-dropdown-toggle"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">Newcomers</span>
                          </span>
                          <span className="gm-caret" aria-label="dropdown">
                            <i className="fa fa-fw fa-angle-down"></i>
                          </span>
                        </a>
                        <div className="gm-dropdown-menu-wrapper">
                          <ul className="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                            <li>
                              <div className="gm-mega-menu-wrapper">
                                <div className="gm-grid-container">
                                  <div className="gm-grid-row">
                                    <div className="gm-mega-menu__item mobile-grid-100 grid-100">
                                      <div
                                        id="fws_642321061480e"
                                        data-column-margin="60px"
                                        data-midnight="light"
                                        className="wpb_row vc_row-fluid vc_row  vc_row-o-equal-height vc_row-flex  vc_row-o-content-top  green"
                                        style={{
                                          paddingTop: "20px",
                                          paddingBottom: "20px ",
                                        }}
                                      >
                                        <div
                                          className="row-bg-wrap"
                                          data-bg-animation="none"
                                          data-bg-overlay="false"
                                        >
                                          <div className="inner-wrap">
                                            <div className="row-bg"></div>
                                          </div>
                                        </div>
                                        <div className="row_col_wrap_12 col span_12 light left">
                                          <div
                                            className="vc_col-sm-4 vc_hidden-sm vc_hidden-xs wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-using-bg="true"
                                            data-bg-cover="true"
                                            data-padding-pos="all"
                                            data-has-bg-color="false"
                                            data-bg-color=""
                                            data-bg-opacity="1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div
                                                className="column-image-bg-wrap"
                                                data-bg-pos="center center"
                                                data-bg-animation="none"
                                                data-bg-overlay="false"
                                              >
                                                <div className="inner-wrap">
                                                  <div
                                                    className="column-image-bg"
                                                    style={{
                                                      backgroundImage:
                                                        "url('https://www.uhc.ca/wp-content/uploads/2020/12/conversation-circle.jpg')",
                                                    }}
                                                  ></div>
                                                </div>
                                              </div>
                                              <div className="wpb_wrapper"></div>
                                            </div>
                                          </div>

                                          <div
                                            style={{ color: "#ffffff" }}
                                            className="vc_col-sm-4 vc_hidden-sm vc_hidden-xs wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-padding-pos="all"
                                            data-has-bg-color="false"
                                            data-bg-color=""
                                            data-bg-opacity="1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <p>
                                                      <strong>Newcomers</strong>
                                                    </p>
                                                    <p>
                                                      Our Newcomer programs
                                                      offer everything from
                                                      settlement to jobs and
                                                      everything in between! We
                                                      provide onsite care for
                                                      newcomer children (ages 19
                                                      months – 6 years), youth
                                                      programs (ages 7 – 24
                                                      years) and adult programs.
                                                      Come in and visit our
                                                      Settlement Worker to help
                                                      point you in the right
                                                      direction and get your
                                                      needs met today!
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="vc_col-sm-4 wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-using-bg="true"
                                            data-padding-pos="all"
                                            data-has-bg-color="true"
                                            data-bg-color="#000000"
                                            data-bg-opacity="0.1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div
                                                className="column-bg-overlay-wrap"
                                                data-bg-animation="none"
                                              >
                                                <div
                                                  className="column-bg-overlay"
                                                  style={{
                                                    opacity: "0.1",
                                                    backgroundColor: "#000000",
                                                  }}
                                                ></div>
                                              </div>
                                              <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <div
                                                      className="wpb_category_n_menu_accordion"
                                                      data-accordion="true"
                                                      data-indicator_icon="+"
                                                      data-iconclass=""
                                                    >
                                                      <ul
                                                        id="menu-newcomers"
                                                        className="wpb_category_n_menu_accordion_list"
                                                      >
                                                        <li
                                                          id="menu-item-3932"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3932"
                                                        >
                                                          <a href="https://www.uhc.ca/settlement-workers/">
                                                            Settlement Services
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3933"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3933"
                                                        >
                                                          <a href="https://www.uhc.ca/english-classes/">
                                                            English Classes
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3934"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3934"
                                                        >
                                                          <a href="https://www.uhc.ca/care-for-newcomer-children-program/">
                                                            Care for Newcomer
                                                            Children Program
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-4016"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4016"
                                                        >
                                                          <a href="https://www.uhc.ca/computer-workplace-skills-training/">
                                                            Computer &#038;
                                                            Workplace Skills
                                                            Training
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3935"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3935"
                                                        >
                                                          <a href="https://www.uhc.ca/ready-to-work/">
                                                            Ready to Work
                                                            Program
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3936"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3936"
                                                        >
                                                          <a href="https://www.uhc.ca/orientation-workshops/">
                                                            Orientation
                                                            Workshops
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3938"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3938"
                                                        >
                                                          <a href="https://www.uhc.ca/connecting-newcomer-youth-ages-14-24-evening-programs/">
                                                            Connecting Newcomer
                                                            Youth – Ages 14 – 24
                                                            (Evening Programs)
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3937"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3937"
                                                        >
                                                          <a href="https://www.uhc.ca/computers-for-youth-ages-7-12-summer-program/">
                                                            Computers for Youth
                                                            – Ages 7 – 12
                                                            (Summer Program)
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3939"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3939"
                                                        >
                                                          <a href="https://www.uhc.ca/conversation-circle-summer-program/">
                                                            Conversation Circle
                                                            (Summer Program)
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-4478"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4478"
                                                        >
                                                          <a href="https://www.uhc.ca/emotional-support-services-for-newcomers/">
                                                            Emotional Support
                                                            Services for
                                                            Newcomers
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li
                        id="menu-item-3657"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown mega-gm-dropdown"
                      >
                        <a
                          href="https://www.uhc.ca/support-services/"
                          className="gm-anchor gm-dropdown-toggle"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Support Services
                            </span>
                          </span>
                          <span className="gm-caret" aria-label="dropdown">
                            <i className="fa fa-fw fa-angle-down"></i>
                          </span>
                        </a>
                        <div className="gm-dropdown-menu-wrapper">
                          <ul className="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                            <li>
                              <div className="gm-mega-menu-wrapper">
                                <div className="gm-grid-container">
                                  <div className="gm-grid-row">
                                    <div className="gm-mega-menu__item mobile-grid-100 grid-100">
                                      <div
                                        id="fws_64232106195a2"
                                        data-column-margin="60px"
                                        data-midnight="light"
                                        className="wpb_row vc_row-fluid vc_row  vc_row-o-equal-height vc_row-flex  vc_row-o-content-top  green"
                                        style={{
                                          paddingTop: "20px",
                                          paddingBottom: "20px",
                                        }}
                                      >
                                        <div
                                          className="row-bg-wrap"
                                          data-bg-animation="none"
                                          data-bg-overlay="false"
                                        >
                                          <div className="inner-wrap">
                                            <div className="row-bg"></div>
                                          </div>
                                        </div>
                                        <div className="row_col_wrap_12 col span_12 light left">
                                          <div
                                            className="vc_col-sm-4 vc_hidden-sm vc_hidden-xs wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-using-bg="true"
                                            data-bg-cover="true"
                                            data-padding-pos="all"
                                            data-has-bg-color="false"
                                            data-bg-color=""
                                            data-bg-opacity="1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div
                                                className="column-image-bg-wrap"
                                                data-bg-pos="center center"
                                                data-bg-animation="none"
                                                data-bg-overlay="false"
                                              >
                                                <div className="inner-wrap">
                                                  <div
                                                    className="column-image-bg"
                                                    style={{
                                                      backgroundImage:
                                                        "url('https://www.uhc.ca/wp-content/uploads/2021/06/foodbank-support-sm.jpg')",
                                                    }}
                                                  ></div>
                                                </div>
                                              </div>
                                              <div className="wpb_wrapper"></div>
                                            </div>
                                          </div>

                                          <div
                                            style={{ color: "#ffffff" }}
                                            className="vc_col-sm-4 vc_hidden-sm vc_hidden-xs wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-padding-pos="all"
                                            data-has-bg-color="false"
                                            data-bg-color=""
                                            data-bg-opacity="1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <p>
                                                      <strong>
                                                        Support Services
                                                      </strong>
                                                    </p>
                                                    <p>
                                                      We all have basic human
                                                      needs and the UHC is here
                                                      to support them! We have
                                                      an onsite food bank named
                                                      Unifor Local 200 People’s
                                                      Choice Pantry, an energy
                                                      assistance program to help
                                                      you with your bills, a
                                                      Community Kitchen to teach
                                                      you about nutrition, and a
                                                      Community Garden where you
                                                      can come and plant your
                                                      own and reap the benefits!
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="vc_col-sm-4 wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-using-bg="true"
                                            data-padding-pos="all"
                                            data-has-bg-color="true"
                                            data-bg-color="#000000"
                                            data-bg-opacity="0.1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div
                                                className="column-bg-overlay-wrap"
                                                data-bg-animation="none"
                                              >
                                                <div
                                                  className="column-bg-overlay"
                                                  style={{
                                                    opacity: "0.1",
                                                    backgroundColor: "#000000",
                                                  }}
                                                ></div>
                                              </div>
                                              <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <div
                                                      className="wpb_category_n_menu_accordion"
                                                      data-accordion="true"
                                                      data-indicator_icon="+"
                                                      data-iconclass=""
                                                    >
                                                      <ul
                                                        id="menu-support-services"
                                                        className="wpb_category_n_menu_accordion_list"
                                                      >
                                                        <li
                                                          id="menu-item-3944"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3944"
                                                        >
                                                          <a href="https://www.uhc.ca/food-bank/">
                                                            Food Bank Program
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3945"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3945"
                                                        >
                                                          <a href="https://www.uhc.ca/keep-the-heat/">
                                                            Energy Assistance
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3946"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3946"
                                                        >
                                                          <a href="https://www.uhc.ca/coats-for-kids/">
                                                            Coats for Kids
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3947"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3947"
                                                        >
                                                          <a href="https://www.uhc.ca/phone-access/">
                                                            Phone Access
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3949"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3949"
                                                        >
                                                          <a href="https://www.uhc.ca/shuttling-for-success/">
                                                            Shuttle Services
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3950"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3950"
                                                        >
                                                          <a href="https://www.uhc.ca/meals-on-wheels/">
                                                            Meals on Wheels
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li
                        id="menu-item-3658"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown mega-gm-dropdown"
                      >
                        <a
                          href="https://www.uhc.ca/food-assistance/"
                          className="gm-anchor gm-dropdown-toggle"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Food Initiatives
                            </span>
                          </span>
                          <span className="gm-caret" aria-label="dropdown">
                            <i className="fa fa-fw fa-angle-down"></i>
                          </span>
                        </a>
                        <div className="gm-dropdown-menu-wrapper">
                          <ul className="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                            <li>
                              <div className="gm-mega-menu-wrapper">
                                <div className="gm-grid-container">
                                  <div className="gm-grid-row">
                                    <div className="gm-mega-menu__item mobile-grid-100 grid-100">
                                      <div
                                        id="fws_642321061dcfb"
                                        data-column-margin="60px"
                                        data-midnight="light"
                                        className="wpb_row vc_row-fluid vc_row  vc_row-o-equal-height vc_row-flex  vc_row-o-content-top  green"
                                        style={{
                                          paddingTop: "20px",
                                          paddingBottom: "20px",
                                        }}
                                      >
                                        <div
                                          className="row-bg-wrap"
                                          data-bg-animation="none"
                                          data-bg-overlay="false"
                                        >
                                          <div className="inner-wrap">
                                            <div className="row-bg"></div>
                                          </div>
                                        </div>
                                        <div className="row_col_wrap_12 col span_12 light left">
                                          <div
                                            className="vc_col-sm-4 vc_hidden-sm vc_hidden-xs wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-using-bg="true"
                                            data-bg-cover="true"
                                            data-padding-pos="all"
                                            data-has-bg-color="false"
                                            data-bg-color=""
                                            data-bg-opacity="1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div
                                                className="column-image-bg-wrap"
                                                data-bg-pos="center center"
                                                data-bg-animation="none"
                                                data-bg-overlay="false"
                                              >
                                                <div className="inner-wrap">
                                                  <div
                                                    className="column-image-bg"
                                                    style={{
                                                      backgroundImage:
                                                        "url('https://www.uhc.ca/wp-content/uploads/2021/06/farm-to-food-food-assistance.jpg')",
                                                    }}
                                                  ></div>
                                                </div>
                                              </div>
                                              <div className="wpb_wrapper"></div>
                                            </div>
                                          </div>

                                          <div
                                            style={{ color: "#ffffff" }}
                                            className="vc_col-sm-4 vc_hidden-sm vc_hidden-xs wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-padding-pos="all"
                                            data-has-bg-color="false"
                                            data-bg-color=""
                                            data-bg-opacity="1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <p>
                                                      <strong>
                                                        Food Initiatives
                                                      </strong>
                                                    </p>
                                                    <p>
                                                      We have many food
                                                      initiatives that provide
                                                      nutritional food to those
                                                      who are experiencing
                                                      poverty and are unable to
                                                      meet their daily needs.
                                                      Together, these
                                                      initiatives help create
                                                      healthy thriving
                                                      communities locally and
                                                      across Ontario including
                                                      indigenous communities in
                                                      the north!
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="vc_col-sm-4 wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-using-bg="true"
                                            data-padding-pos="all"
                                            data-has-bg-color="true"
                                            data-bg-color="#000000"
                                            data-bg-opacity="0.1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div
                                                className="column-bg-overlay-wrap"
                                                data-bg-animation="none"
                                              >
                                                <div
                                                  className="column-bg-overlay"
                                                  style={{
                                                    opacity: "0.1",
                                                    backgroundColor: "#000000",
                                                  }}
                                                ></div>
                                              </div>
                                              <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <div
                                                      className="wpb_category_n_menu_accordion"
                                                      data-accordion="true"
                                                      data-indicator_icon="+"
                                                      data-iconclass=""
                                                    >
                                                      <ul
                                                        id="menu-food-initiatives"
                                                        className="wpb_category_n_menu_accordion_list"
                                                      >
                                                        <li
                                                          id="menu-item-3954"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3954"
                                                        >
                                                          <a href="https://www.uhc.ca/food-bank/">
                                                            Food Bank Program
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3955"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3955"
                                                        >
                                                          <a href="https://www.uhc.ca/plentiful-harvest-food-rescue/">
                                                            Plentiful Harvest
                                                            Food Rescue
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3957"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3957"
                                                        >
                                                          <a href="https://www.uhc.ca/community-kitchen/">
                                                            Community Kitchen
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3956"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3956"
                                                        >
                                                          <a href="https://www.uhc.ca/farm-to-food/">
                                                            Farm to Food Program
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3958"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3958"
                                                        >
                                                          <a href="https://www.uhc.ca/community-garden/">
                                                            Community Garden
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3959"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3959"
                                                        >
                                                          <a href="https://www.uhc.ca/leamington-regional-food-hub/">
                                                            Leamington Regional
                                                            Food Hub
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li
                        id="menu-item-3659"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown mega-gm-dropdown"
                      >
                        <div className="gm-anchor gm-dropdown-toggle gm-anchor--empty">
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt gm-menu-item__txt-empty-url">
                              Support Us
                            </span>
                          </span>
                          <span className="gm-caret" aria-label="dropdown">
                            <i className="fa fa-fw fa-angle-down"></i>
                          </span>
                        </div>
                        <div className="gm-dropdown-menu-wrapper">
                          <ul className="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                            <li>
                              <div className="gm-mega-menu-wrapper">
                                <div className="gm-grid-container">
                                  <div className="gm-grid-row">
                                    <div className="gm-mega-menu__item mobile-grid-100 grid-100">
                                      <div
                                        id="fws_6423210622258"
                                        data-column-margin="60px"
                                        data-midnight="light"
                                        className="wpb_row vc_row-fluid vc_row  vc_row-o-equal-height vc_row-flex  vc_row-o-content-top  green"
                                        style={{
                                          paddingTop: "20px",
                                          paddingBottom: "20px",
                                        }}
                                      >
                                        <div
                                          className="row-bg-wrap"
                                          data-bg-animation="none"
                                          data-bg-overlay="false"
                                        >
                                          <div className="inner-wrap">
                                            <div className="row-bg"></div>
                                          </div>
                                        </div>
                                        <div className="row_col_wrap_12 col span_12 light left">
                                          <div
                                            className="vc_col-sm-4 vc_hidden-sm vc_hidden-xs wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-using-bg="true"
                                            data-bg-cover="true"
                                            data-padding-pos="all"
                                            data-has-bg-color="false"
                                            data-bg-color=""
                                            data-bg-opacity="1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div
                                                className="column-image-bg-wrap"
                                                data-bg-pos="center center"
                                                data-bg-animation="none"
                                                data-bg-overlay="false"
                                              >
                                                <div className="inner-wrap">
                                                  <div
                                                    className="column-image-bg"
                                                    style={{
                                                      backgroundImage:
                                                        "url('https://www.uhc.ca/wp-content/uploads/2020/11/volunteer_top.jpg')",
                                                    }}
                                                  ></div>
                                                </div>
                                              </div>
                                              <div className="wpb_wrapper"></div>
                                            </div>
                                          </div>

                                          <div
                                            style={{ color: "#ffffff" }}
                                            className="vc_col-sm-4 vc_hidden-sm vc_hidden-xs wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-padding-pos="all"
                                            data-has-bg-color="false"
                                            data-bg-color=""
                                            data-bg-opacity="1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <p>
                                                      <strong>
                                                        Support Us
                                                      </strong>
                                                    </p>
                                                    <p>
                                                      The UHC offers many
                                                      programs and services to
                                                      support people in our
                                                      community with basic human
                                                      needs. These include
                                                      access to nutritional food
                                                      (shelf stable items, and
                                                      fresh and frozen foods), a
                                                      warm winter coat, a hot
                                                      meal and so much more.
                                                      Your support will allow us
                                                      to continue our efforts
                                                      and improve the quality of
                                                      people’s lives by moving
                                                      them to independence and
                                                      creating a healthy and
                                                      thriving community.
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="vc_col-sm-4 wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                                            data-using-bg="true"
                                            data-padding-pos="all"
                                            data-has-bg-color="true"
                                            data-bg-color="#000000"
                                            data-bg-opacity="0.1"
                                            data-animation=""
                                            data-delay="0"
                                          >
                                            <div className="vc_column-inner">
                                              <div
                                                className="column-bg-overlay-wrap"
                                                data-bg-animation="none"
                                              >
                                                <div
                                                  className="column-bg-overlay"
                                                  style={{
                                                    opacity: "0.1",
                                                    backgroundColor: "#000000",
                                                  }}
                                                ></div>
                                              </div>
                                              <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element ">
                                                  <div className="wpb_wrapper">
                                                    <div
                                                      className="wpb_category_n_menu_accordion"
                                                      data-accordion="true"
                                                      data-indicator_icon="+"
                                                      data-iconclass=""
                                                    >
                                                      <ul
                                                        id="menu-support-us"
                                                        className="wpb_category_n_menu_accordion_list"
                                                      >
                                                        <li
                                                          id="menu-item-3968"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3968"
                                                        >
                                                          <a href="https://www.uhc.ca/donate/">
                                                            Monetary Donations
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3969"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3969"
                                                        >
                                                          <a href="https://www.uhc.ca/food-donations/">
                                                            Food Donations
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3971"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3971"
                                                        >
                                                          <a href="https://www.uhc.ca/coats-for-kids/">
                                                            Coats for Kids
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3972"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3972"
                                                        >
                                                          <a href="https://www.uhc.ca/sponsorships/">
                                                            Event Sponsorships
                                                          </a>
                                                        </li>
                                                        <li
                                                          id="menu-item-3973"
                                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3973"
                                                        >
                                                          <a href="https://www.uhc.ca/volunteer/">
                                                            Volunteer
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li
                        id="menu-item-3672"
                        className="social menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-0"
                      >
                        <a
                          target="_blank"
                          href="https://www.facebook.com/UHC.Windsor/"
                          className="gm-anchor"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              <img
                                src={fb}
                                alt="Facebook"
                                style={{ width: "28" }}
                              />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-3673"
                        className="social menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-0"
                      >
                        <a
                          target="_blank"
                          href="https://twitter.com/uhc_"
                          className="gm-anchor"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              <img src={twi} alt="Twitter" width="25" />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-3674"
                        className="social menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-0"
                      >
                        <a
                          target="_blank"
                          href="https://www.youtube.com/channel/UC-w1kuomxKRfdSc9WtqugxA"
                          className="gm-anchor"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              <img src={yt} alt="YouTube" width="25" />
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="gm-padding"></div>
        </header>
        <aside className="gm-navigation-drawer gm-navigation-drawer--mobile gm-hidden">
          <div className="gm-grid-container d-flex flex-column h-100">
            <div
              className="gm-menu-btn-close-mobile-drawer gm-hamburger-close"
              aria-label="close"
            >
              <div className="hamburger is-active hamburger--squeeze">
                <div className="hamburger-box">
                  <div className="hamburger-inner"></div>
                </div>
              </div>
            </div>
            <div className="gm-mobile-menu-container">
              <ul id="menu-main-nav" className="gm-navbar-nav">
                <li
                  id="menu-item-mobile-4633"
                  className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-0"
                >
                  <a href="https://www.uhc.ca/donate/" className="gm-anchor">
                    <span className="gm-menu-item__txt-wrapper">
                      <span className="gm-menu-item__txt">Donate</span>
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-mobile-32"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home gm-menu-item gm-menu-item--lvl-0"
                >
                  <a href="https://www.uhc.ca/" className="gm-anchor">
                    <span className="gm-menu-item__txt-wrapper">
                      <span className="gm-menu-item__txt">Home</span>
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-mobile-3293"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown"
                >
                  <div className="gm-anchor gm-dropdown-toggle gm-anchor--empty">
                    <span className="gm-menu-item__txt-wrapper">
                      <span className="gm-menu-item__txt gm-menu-item__txt-empty-url">
                        About
                      </span>
                    </span>
                    <span className="gm-caret" aria-label="dropdown">
                      <i className="fa fa-fw fa-angle-down"></i>
                    </span>
                  </div>
                  <div className="gm-dropdown-menu-wrapper">
                    <div className="gm-dropdown-menu-title"></div>
                    <ul className="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                      <li
                        id="menu-item-mobile-3297"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/about/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">About Us</span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-3292"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/programs-services/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Programs &#038; Services
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-3295"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/employment-opportunities/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Employment Opportunities
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-3294"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/customer-service-charter/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Customer Service Charter
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-3296"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/privacy-policy/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Privacy Policy
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-3299"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/covid-19-update/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              COVID-19 Update
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-3298"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/uhc-in-the-news/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              UHC in the News
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-200"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/contact-us/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Contact Us
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  id="menu-item-mobile-44"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown"
                >
                  <div className="gm-anchor gm-dropdown-toggle gm-anchor--empty">
                    <span className="gm-menu-item__txt-wrapper">
                      <span className="gm-menu-item__txt gm-menu-item__txt-empty-url">
                        Calendars
                      </span>
                    </span>
                    <span className="gm-caret" aria-label="dropdown">
                      <i className="fa fa-fw fa-angle-down"></i>
                    </span>
                  </div>
                  <div className="gm-dropdown-menu-wrapper">
                    <div className="gm-dropdown-menu-title"></div>
                    <ul className="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                      <li
                        id="menu-item-mobile-179"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/events-workshops/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Events &#038; Workshops
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  id="menu-item-mobile-3311"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown"
                >
                  <a
                    href="/employment-services/"
                    className="gm-anchor gm-dropdown-toggle"
                  >
                    <span className="gm-menu-item__txt-wrapper">
                      <span className="gm-menu-item__txt">Job Seekers</span>
                    </span>
                    <span className="gm-caret" aria-label="dropdown">
                      <i className="fa fa-fw fa-angle-down"></i>
                    </span>
                  </a>
                  <div className="gm-dropdown-menu-wrapper">
                    <div className="gm-dropdown-menu-title"></div>
                    <ul className="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                      <li
                        id="menu-item-mobile-4206"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/job-post-board/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">Job Board</span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-2156"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/walk-in-resource-and-information-centre/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Walk-In Resource and Information Centre
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-2155"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/employment-counselling/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              One-On-One Career Counselling
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-3389"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children gm-menu-item gm-menu-item--lvl-1 gm-dropdown gm-dropdown-submenu"
                      >
                        <a
                          href="https://www.uhc.ca/employment-programs/"
                          className="gm-anchor gm-dropdown-toggle gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Employment Programs
                            </span>
                          </span>
                          <span className="gm-caret" aria-label="submenu">
                            <i className="fa fa-fw fa-angle-right"></i>
                          </span>
                        </a>
                        <div className="gm-dropdown-menu-wrapper">
                          <div className="gm-dropdown-menu-title"></div>
                          <ul className="gm-dropdown-menu gm-dropdown-menu--lvl-2">
                            <li
                              id="menu-item-mobile-71"
                              className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-2"
                            >
                              <a
                                href="https://www.uhc.ca/youth-job-connection/"
                                className="gm-anchor gm-menu-item__link"
                              >
                                <span className="gm-menu-item__txt-wrapper">
                                  <span className="gm-menu-item__txt">
                                    Youth Job Connection
                                  </span>
                                </span>
                              </a>
                            </li>
                            <li
                              id="menu-item-mobile-1501"
                              className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-2"
                            >
                              <a
                                href="https://www.uhc.ca/youth-job-connection-summer/"
                                className="gm-anchor gm-menu-item__link"
                              >
                                <span className="gm-menu-item__txt-wrapper">
                                  <span className="gm-menu-item__txt">
                                    Youth Job Connection Summer
                                  </span>
                                </span>
                              </a>
                            </li>
                            <li
                              id="menu-item-mobile-73"
                              className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-2"
                            >
                              <a
                                href="https://www.uhc.ca/cook-pre-apprenticeship-program/"
                                className="gm-anchor gm-menu-item__link"
                              >
                                <span className="gm-menu-item__txt-wrapper">
                                  <span className="gm-menu-item__txt">
                                    Cook Pre-Apprenticeship Program
                                  </span>
                                </span>
                              </a>
                            </li>
                            <li
                              id="menu-item-mobile-104"
                              className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-2"
                            >
                              <a
                                href="https://www.uhc.ca/ready-to-work/"
                                className="gm-anchor gm-menu-item__link"
                              >
                                <span className="gm-menu-item__txt-wrapper">
                                  <span className="gm-menu-item__txt">
                                    Ready to Work Program
                                  </span>
                                </span>
                              </a>
                            </li>
                            <li
                              id="menu-item-mobile-3313"
                              className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-2"
                            >
                              <a
                                href="https://www.uhc.ca/?page_id=3280"
                                className="gm-anchor gm-menu-item__link"
                              >
                                <span className="gm-menu-item__txt-wrapper">
                                  <span className="gm-menu-item__txt">
                                    Beyond COVID Job Preparedness
                                  </span>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li
                        id="menu-item-mobile-3386"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children gm-menu-item gm-menu-item--lvl-1 gm-dropdown gm-dropdown-submenu"
                      >
                        <a
                          href="https://www.uhc.ca/training-education/"
                          className="gm-anchor gm-dropdown-toggle gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Training &#038; Education
                            </span>
                          </span>
                          <span className="gm-caret" aria-label="submenu">
                            <i className="fa fa-fw fa-angle-right"></i>
                          </span>
                        </a>
                        <div className="gm-dropdown-menu-wrapper">
                          <div className="gm-dropdown-menu-title"></div>
                          <ul className="gm-dropdown-menu gm-dropdown-menu--lvl-2">
                            <li
                              id="menu-item-mobile-1954"
                              className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-2"
                            >
                              <a
                                href="https://www.uhc.ca/employment-skills-training/"
                                className="gm-anchor gm-menu-item__link"
                              >
                                <span className="gm-menu-item__txt-wrapper">
                                  <span className="gm-menu-item__txt">
                                    Employment Skills Training
                                  </span>
                                </span>
                              </a>
                            </li>
                            <li
                              id="menu-item-mobile-2157"
                              className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-2"
                            >
                              <a
                                href="https://www.uhc.ca/better-jobs-ontario/"
                                className="gm-anchor gm-menu-item__link"
                              >
                                <span className="gm-menu-item__txt-wrapper">
                                  <span className="gm-menu-item__txt">
                                    Better Jobs Ontario
                                  </span>
                                </span>
                              </a>
                            </li>
                            <li
                              id="menu-item-mobile-92"
                              className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-2"
                            >
                              <a
                                href="https://www.uhc.ca/literacy-and-basic-skills/"
                                className="gm-anchor gm-menu-item__link"
                              >
                                <span className="gm-menu-item__txt-wrapper">
                                  <span className="gm-menu-item__txt">
                                    Literacy and Basic Skills
                                  </span>
                                </span>
                              </a>
                            </li>
                            <li
                              id="menu-item-mobile-93"
                              className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-2"
                            >
                              <a
                                href="https://www.uhc.ca/ged-preparation-program/"
                                className="gm-anchor gm-menu-item__link"
                              >
                                <span className="gm-menu-item__txt-wrapper">
                                  <span className="gm-menu-item__txt">
                                    GED Preparation Program
                                  </span>
                                </span>
                              </a>
                            </li>
                            <li
                              id="menu-item-mobile-3315"
                              className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-2"
                            >
                              <a
                                href="https://www.uhc.ca/computer-workshops/"
                                className="gm-anchor gm-menu-item__link"
                              >
                                <span className="gm-menu-item__txt-wrapper">
                                  <span className="gm-menu-item__txt">
                                    Computer Workshops
                                  </span>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li
                        id="menu-item-mobile-4343"
                        className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="/create-account-login/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Forms, Tools &#038; Resources
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  id="menu-item-mobile-41"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown"
                >
                  <a
                    href="/employers/"
                    className="gm-anchor gm-dropdown-toggle"
                  >
                    <span className="gm-menu-item__txt-wrapper">
                      <span className="gm-menu-item__txt">Employers</span>
                    </span>
                    <span className="gm-caret" aria-label="dropdown">
                      <i className="fa fa-fw fa-angle-down"></i>
                    </span>
                  </a>
                  <div className="gm-dropdown-menu-wrapper">
                    <div className="gm-dropdown-menu-title"></div>
                    <ul className="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                      <li
                        id="menu-item-mobile-171"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/post-a-job/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Hiring (Job Positions)
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-173"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/job-matching-placement-incentives/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Job Matching Placement &#038; Incentives
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-174"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/canada-ontario-job-grant/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Canada Ontario Job Grant
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-3317"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/closures/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">Closures</span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-3318"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/pre-apprenticeship/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Pre-Apprenticeship
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-1321"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/employer-workshops/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Employer Workshops
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-3319"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/forms-tools-resources/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Forms, Tools &#038; Resources
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  id="menu-item-mobile-3320"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown"
                >
                  <a
                    href="/newcomers/"
                    className="gm-anchor gm-dropdown-toggle"
                  >
                    <span className="gm-menu-item__txt-wrapper">
                      <span className="gm-menu-item__txt">Newcomers</span>
                    </span>
                    <span className="gm-caret" aria-label="dropdown">
                      <i className="fa fa-fw fa-angle-down"></i>
                    </span>
                  </a>
                  <div className="gm-dropdown-menu-wrapper">
                    <div className="gm-dropdown-menu-title"></div>
                    <ul className="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                      <li
                        id="menu-item-mobile-123"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/settlement-workers/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Settlement Services
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-106"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/english-classes/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              English Classes
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-103"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/care-for-newcomer-children-program/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Care for Newcomer Children Program
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-105"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/computer-workplace-skills-training/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Computer &#038; Workplace Skills Training
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-3321"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/ready-to-work/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Ready to Work Program
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-122"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/orientation-workshops/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Orientation Workshops
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-121"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/connecting-newcomer-youth-ages-14-24-evening-programs/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Connecting Newcomer Youth &#8211; Ages 14-24
                              (Evening Program)
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-120"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/computers-for-youth-ages-7-12-summer-program/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Computers for Youth &#8211; Ages 7-12 (Summer
                              Program)
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-119"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/conversation-circle-summer-program/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Conversation Circle (Summer Program)
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  id="menu-item-mobile-3322"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown"
                >
                  <a
                    href="/support-services/"
                    className="gm-anchor gm-dropdown-toggle"
                  >
                    <span className="gm-menu-item__txt-wrapper">
                      <span className="gm-menu-item__txt">
                        Support Services
                      </span>
                    </span>
                    <span className="gm-caret" aria-label="dropdown">
                      <i className="fa fa-fw fa-angle-down"></i>
                    </span>
                  </a>
                  <div className="gm-dropdown-menu-wrapper">
                    <div className="gm-dropdown-menu-title"></div>
                    <ul className="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                      <li
                        id="menu-item-mobile-133"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/food-bank/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Food Bank Program
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-157"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/keep-the-heat/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Energy Assistance
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-158"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/coats-for-kids/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Coats for Kids
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-156"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/phone-access/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Phone Access
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-155"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/?page_id=149"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Income Tax Assistance
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-154"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/shuttling-for-success/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Shuttle Services
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-140"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/meals-on-wheels/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Meals on Wheels
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  id="menu-item-mobile-3323"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown"
                >
                  <a
                    href="/food-assistance/"
                    className="gm-anchor gm-dropdown-toggle"
                  >
                    <span className="gm-menu-item__txt-wrapper">
                      <span className="gm-menu-item__txt">
                        Food Initiatives
                      </span>
                    </span>
                    <span className="gm-caret" aria-label="dropdown">
                      <i className="fa fa-fw fa-angle-down"></i>
                    </span>
                  </a>
                  <div className="gm-dropdown-menu-wrapper">
                    <div className="gm-dropdown-menu-title"></div>
                    <ul className="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                      <li
                        id="menu-item-mobile-3324"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/food-bank/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Food Bank Program
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-131"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/plentiful-harvest-food-rescue/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Plentiful Harvest Food Rescue Program
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-142"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/community-kitchen/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Community Kitchen
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-132"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/farm-to-food/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Farm to Food Program
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-141"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/community-garden/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Community Garden
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-3336"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/leamington-regional-food-hub/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Leamington Regional Food Hub
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  id="menu-item-mobile-45"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown"
                >
                  <div className="gm-anchor gm-dropdown-toggle gm-anchor--empty">
                    <span className="gm-menu-item__txt-wrapper">
                      <span className="gm-menu-item__txt gm-menu-item__txt-empty-url">
                        Support Us
                      </span>
                    </span>
                    <span className="gm-caret" aria-label="dropdown">
                      <i className="fa fa-fw fa-angle-down"></i>
                    </span>
                  </div>
                  <div className="gm-dropdown-menu-wrapper">
                    <div className="gm-dropdown-menu-title"></div>
                    <ul className="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                      <li
                        id="menu-item-mobile-4556"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/?page_id=4535"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              CBC – Sounds of the Season
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-189"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/donate/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Monetary Donations
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-3512"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/food-donations/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Food Donations
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-187"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/?page_id=185"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Gift Giving Tree
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-190"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/coats-for-kids/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Coats for Kids
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-194"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/sponsorships/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">
                              Event Sponsorships
                            </span>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-mobile-203"
                        className="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1"
                      >
                        <a
                          href="https://www.uhc.ca/volunteer/"
                          className="gm-anchor gm-menu-item__link"
                        >
                          <span className="gm-menu-item__txt-wrapper">
                            <span className="gm-menu-item__txt">Volunteer</span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  id="menu-item-mobile-2775"
                  className="social menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-0"
                >
                  <a
                    target="_blank"
                    href="https://www.facebook.com/UHC.Windsor/"
                    className="gm-anchor"
                  >
                    <span className="gm-menu-item__txt-wrapper">
                      <span className="gm-menu-item__txt">
                        <img src={fb} alt="Facebook" style={{ width: "25" }} />
                      </span>
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-mobile-2782"
                  className="social menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-0"
                >
                  <a
                    target="_blank"
                    href="https://twitter.com/uhc_"
                    className="gm-anchor"
                  >
                    <span className="gm-menu-item__txt-wrapper">
                      <span className="gm-menu-item__txt">
                        <img src={twi} alt="Twitter" style={{ width: "25" }} />
                      </span>
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-mobile-2784"
                  className="social menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-0"
                >
                  <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UC-w1kuomxKRfdSc9WtqugxA"
                    className="gm-anchor"
                  >
                    <span className="gm-menu-item__txt-wrapper">
                      <span className="gm-menu-item__txt">
                        <img src={yt} alt="YouTube" width="25" />
                      </span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-grow-1"></div>
            <div className="gm-mobile-action-area-wrapper d-flex justify-content-center align-items-center text-center mb-4 mt-5"></div>
          </div>
          <div className="gm-mobile-postwrap"></div>
        </aside>

        <script type="text/javascript">
          {(function (window, document) {
            if (
              navigator.userAgent.match(
                /(Android|iPod|iPhone|iPad|BlackBerry|IEMobile|Opera Mini)/
              )
            ) {
              document.body.className += " using-mobile-browser ";
            }

            if (!("ontouchstart" in window)) {
              var body = document.querySelector("body");
              var winW = window.innerWidth;
              var bodyW = body.clientWidth;

              if (winW > bodyW + 4) {
                body.setAttribute(
                  "style",
                  "--scroll-bar-w: " + (winW - bodyW - 4) + "px"
                );
              } else {
                body.setAttribute("style", "--scroll-bar-w: 0px");
              }
            }
          })(window, document)}
        </script>
        <a href="#ajax-content-wrap" className="nectar-skip-to-content">
          Skip to main content
        </a>
        <div className="ocm-effect-wrap">
          <div className="ocm-effect-wrap-inner">
            <div
              id="header-outer"
              data-has-menu="true"
              data-has-buttons="no"
              data-header-button_style="default"
              data-using-pr-menu="false"
              data-mobile-fixed="1"
              data-ptnm="false"
              data-lhe="animated_underline"
              data-user-set-bg="#ffffff"
              data-format="default"
              data-permanent-transparent="false"
              data-megamenu-rt="0"
              data-remove-fixed="1"
              data-header-resize="0"
              data-cart="false"
              data-transparency-option=""
              data-box-shadow="large"
              data-shrink-num="6"
              data-using-secondary="0"
              data-using-logo="1"
              data-logo-height="60"
              data-m-logo-height="40"
              data-padding="28"
              data-full-width="true"
              data-condense="false"
              data-transparent-header="true"
              data-transparent-shadow-helper="true"
              data-remove-border="true"
              className="transparent"
            >
              <header id="top">
                <div className="span_3"></div>
                <div className="span_9"></div>
              </header>
            </div>

            <div id="ajax-content-wrap">
              <div
                id="page-header-wrap"
                data-animate-in-effect="none"
                data-midnight="light"
                className=""
                style={{ height: "350px" }}
              >
                <div
                  id="page-header-bg"
                  className="not-loaded "
                  data-padding-amt="normal"
                  data-animate-in-effect="none"
                  data-midnight="light"
                  data-text-effect="none"
                  data-bg-pos="center"
                  data-alignment="left"
                  data-alignment-v="middle"
                  data-parallax="1"
                  data-height="350"
                  style={{ backgroundColor: "#000", height: "350px" }}
                >
                  <div
                    className="page-header-bg-image-wrap"
                    id="nectar-page-header-p-wrap"
                    data-parallax-speed="fast"
                  >
                    <div
                      className="page-header-bg-image"
                      style={{
                        backgroundImage:
                          "url(https://www.uhc.ca/wp-content/uploads/2020/12/Job-Postings-Header.jpg)",
                      }}
                    ></div>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col span_6 empty-title">
                        <div className="inner-wrap">
                          <span className="subheader"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-wrap">
                <div className="container main-content">
                  <div className="row">
                    <div
                      id="fws_642321063f523"
                      data-column-margin="default"
                      data-midnight="dark"
                      className="wpb_row vc_row-fluid vc_row full-width-section   top_padding_phone_10px bottom_padding_phone_15px "
                      data-using-ctc="true"
                      style={{
                        paddingTop: "15px",
                        paddingBottom: "25px",
                        color: "#ffffff ",
                      }}
                    >
                      <div
                        className="row-bg-wrap"
                        data-bg-animation="none"
                        data-bg-overlay="false"
                      >
                        <div className="inner-wrap">
                          <div
                            className="row-bg using-bg-color"
                            style={{ backgroundColor: "#5956a5" }}
                          ></div>
                        </div>
                      </div>
                      <div
                        className="nectar-shape-divider-wrap "
                        style={{ height: "50px" }}
                        data-height="50"
                        data-front="true"
                        data-style="mountains"
                        data-position="bottom"
                      >
                        <svg
                          className="nectar-shape-divider"
                          fill="#99ff00"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 1000 300"
                          preserveAspectRatio="none"
                        >
                          <path d="M 1014 264 v 122 h -808 l -172 -86 s 310.42 -22.84 402 -79 c 106 -65 154 -61 268 -12 c 107 46 195.11 5.94 275 137 z"></path>
                          <path d="M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"></path>
                          <path d="M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"></path>
                          <path d="M -24 69 s 299.68 301.66 413 245 c 8 -4 233 2 284 42 c 17.47 13.7 172 -132 217 -174 c 54.8 -51.15 128 -90 188 -39 c 76.12 64.7 118 99 118 99 l -12 132 l -1212 12 z"></path>
                          <path d="M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"></path>
                        </svg>
                      </div>
                      <div className="row_col_wrap_12 col span_12 custom center">
                        <div
                          className="vc_col-sm-12 wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                          data-using-bg="true"
                          data-padding-pos="all"
                          data-has-bg-color="true"
                          data-bg-color="#5956a5"
                          data-bg-opacity="1"
                          data-animation=""
                          data-delay="0"
                        >
                          <div className="vc_column-inner">
                            <div
                              className="column-bg-overlay-wrap"
                              data-bg-animation="none"
                            >
                              <div
                                className="column-bg-overlay"
                                style={{
                                  opacity: "1",
                                  backgroundColor: "#5956a5",
                                }}
                              ></div>
                            </div>
                            <div className="wpb_wrapper">
                              <div className="wpb_text_column wpb_content_element  purplebg">
                                <div className="wpb_wrapper">
                                  <h1>Create Account / Login</h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="fws_642321064007a"
                      data-column-margin="default"
                      data-midnight="light"
                      className="wpb_row vc_row-fluid vc_row full-width-section  "
                      style={{ paddingTop: "20px", paddingBottom: "20px" }}
                    >
                      <div
                        className="row-bg-wrap"
                        data-bg-animation="none"
                        data-bg-overlay="false"
                      >
                        <div className="inner-wrap">
                          <div className="row-bg using-bg-color"></div>
                        </div>
                      </div>
                      <div
                        className="nectar-shape-divider-wrap "
                        style={{ height: "30px" }}
                        data-height="30"
                        data-front="true"
                        data-style="mountains"
                        data-position="top"
                      >
                        <svg
                          className="nectar-shape-divider"
                          fill="#99ff00"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 1000 300"
                          preserveAspectRatio="none"
                        >
                          <path d="M 1014 264 v 122 h -808 l -172 -86 s 310.42 -22.84 402 -79 c 106 -65 154 -61 268 -12 c 107 46 195.11 5.94 275 137 z"></path>
                          <path d="M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"></path>
                          <path d="M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"></path>
                          <path d="M -24 69 s 299.68 301.66 413 245 c 8 -4 233 2 284 42 c 17.47 13.7 172 -132 217 -174 c 54.8 -51.15 128 -90 188 -39 c 76.12 64.7 118 99 118 99 l -12 132 l -1212 12 z"></path>
                          <path d="M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"></path>
                        </svg>
                      </div>
                      <div className="row_col_wrap_12 col span_12 light center">
                        <div
                          className="vc_col-sm-12 wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                          data-padding-pos="all"
                          data-has-bg-color="false"
                          data-bg-color=""
                          data-bg-opacity="1"
                          data-animation=""
                          data-delay="0"
                        >
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="fws_6423210640a2d"
                      data-column-margin="default"
                      data-midnight="dark"
                      className="wpb_row vc_row-fluid vc_row container"
                      style={{ paddingTop: "10px", paddingBottom: "20px " }}
                    >
                      <div
                        className="row-bg-wrap"
                        data-bg-animation="none"
                        data-bg-overlay="false"
                      >
                        <div className="inner-wrap">
                          <div className="row-bg"></div>
                        </div>
                      </div>
                      <div className="row_col_wrap_12 col span_12 dark left">
                        <div
                          className="vc_col-sm-12 wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                          data-padding-pos="all"
                          data-has-bg-color="false"
                          data-bg-color=""
                          data-bg-opacity="1"
                          data-animation=""
                          data-delay="0"
                        >
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div className="offset-sm-3 offset-md-3 col-sm-6 col-md-6">
                                <Formik
                                  initialValues={{
                                    email: "",
                                    password: "",
                                    captchaValue: "",
                                  }}
                                  validationSchema={Yup.object().shape({
                                    email: Yup.string().required("Email is required"),
                                    password: Yup.string().required("Password is required"),
                                  })}
                                  onSubmit={(
                                    { email, password, captchaValue },
                                    { setStatus, setSubmitting }
                                  ) => {
                                    setStatus();
                                    authenticationService.login(email, password, captchaValue).then(
                                      (user) => {
                                        console.log("isPasswordChanged user", user);
                                        if (!user.isPasswordChanged) {
                                          let roleUrl =
                                            user.role == Role.Admin
                                              ? "/admin/change-password"
                                              : "/staff/change-password";
                                          const { from } = this.props.location.state || {
                                            from: { pathname: roleUrl },
                                          };
                                          this.props.history.push(from);
                                        } else {
                                          const { from } = this.props.location.state || {
                                            from: { pathname: "/dashboard" },
                                          };
                                          this.props.history.push(from);
                                        }
                                      },
                                      (error) => {
                                        setSubmitting(false);
                                        setStatus(error);
                                      }
                                    );
                                  }}
                                  render={({
                                    errors,
                                    status,
                                    touched,
                                    isSubmitting,
                                    setFieldValue,
                                    values,
                                  }) => (
                                    <Form>
                                      <div className="form-horizontal form-material" id="loginform">
                                        <h2 className="box-title m-b-20" style={{ textAlign: "center" }}>Sign In</h2>
                                        <div className="form-group ">
                                          <div className="col-xs-12">
                                            <Field
                                              name="email"
                                              type="text"
                                              placeholder="Username"
                                              className={
                                                "form-control" +
                                                (errors.email && touched.email ? " is-invalid" : "")
                                              }
                                            />
                                            <ErrorMessage
                                              name="email"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <div className="col-xs-12">
                                            <Field
                                              name="password"
                                              type="password"
                                              placeholder="Password"
                                              className={
                                                "form-control" +
                                                (errors.password && touched.password
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            />
                                            <ErrorMessage
                                              name="password"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <div className="col-xs-12">
                                            <div className="d-flex no-block align-items-center">
                                              <div className="checkbox checkbox-primary p-t-0">
                                                <input id="checkbox-signup" type="checkbox" />
                                                <label htmlFor="checkbox-signup"> Remember me </label>
                                              </div>
                                              <div className="ml-auto">
                                                <Link
                                                  to="/forgot-password"
                                                  id="to-recover"
                                                  className="text-muted"
                                                >
                                                  <i className="fa fa-lock m-r-5"></i> Forgot pwd?
                                                </Link>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <ReCAPTCHA
                                          sitekey="6LcMfOIZAAAAAD1MVaVY12vdIaGR9GHKt9h3wDmm"
                                          onChange={(value) => setFieldValue("captchaValue", value)}
                                        />
                                        <div className="form-group text-center mb-0 m-t-20">
                                          <div className="col-xs-12">
                                            <button
                                              type="submit"
                                              disabled={isSubmitting}
                                              className="btn homepage-login-btn btn-lg btn-block text-uppercase waves-effect waves-light"
                                              style={{ width: "80%", backgroundColor: "#98CA3C" }}
                                            >
                                              Log In
                                            </button>
                                            {isSubmitting && (
                                              <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            )}
                                          </div>
                                        </div>
                                        <div className="pt-2 text-center">
                                          <span>
                                            Not Registered?{" "}
                                            <Link to="/register">Create an account</Link>
                                          </span>
                                        </div>
                                        {status && <div className={"alert alert-danger"}>{status}</div>}
                                      </div>
                                    </Form>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="fws_6423210643e0b"
                      data-column-margin="default"
                      data-midnight="dark"
                      className="wpb_row vc_row-fluid vc_row full-width-section  "
                      style={{ paddingTop: "30px", paddingBottom: "30px" }}
                    >
                      <div
                        className="row-bg-wrap"
                        data-bg-animation="none"
                        data-bg-overlay="false"
                      >
                        <div className="inner-wrap">
                          <div
                            className="row-bg using-bg-color"
                            style={{ backgroundColor: "#e8e8e8" }}
                          ></div>
                        </div>
                      </div>
                      <div className="row_col_wrap_12 col span_12 dark left">
                        <div
                          className="vc_col-sm-12 wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone "
                          data-padding-pos="all"
                          data-has-bg-color="false"
                          data-bg-color=""
                          data-bg-opacity="1"
                          data-animation=""
                          data-delay="0"
                        >
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <h3 style={{ padding: "" }}>
                                    Stay tuned for our upcoming &#8230;
                                  </h3>
                                </div>
                              </div>

                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p>
                                    Virtual Resource &amp; Information Hub,
                                    which will be promoted on our social media
                                    platforms!
                                  </p>
                                </div>
                              </div>

                              <div className="row_col_wrap_12_inner col span_12 center" style={{ paddingLeft: "0" }}>
                                <div
                                  className="vc_col-sm-1/5 wpb_column column_container vc_column_container col child_column boxed no-extra-padding inherit_tablet inherit_phone"
                                  data-shadow="small_depth"
                                  data-padding-pos="all"
                                  data-has-bg-color="false"
                                  data-bg-color=""
                                  data-bg-opacity="1"
                                  data-animation=""
                                  data-delay="0"
                                  style={{ marginLeft: "0", paddingLeft: "0" }}
                                >
                                  <div className="vc_column-inner">
                                    <a
                                      className="column-link"
                                      target="_blank"
                                      href="https://www.facebook.com/UHC.Windsor/"
                                    >
                                      <span className="screen-reader-text">
                                        Link to UHC's Facebook page
                                      </span>
                                    </a>
                                    <div
                                      className="column-bg-overlay-wrap"
                                      data-bg-animation="none"
                                    >
                                      <div className="column-bg-overlay"></div>
                                    </div>
                                    <div className="wpb_wrapper">
                                      <div
                                        className="img-with-aniamtion-wrap"
                                        data-max-width="100%"
                                        data-max-width-mobile="default"
                                        data-shadow="none"
                                        data-animation="fade-in"
                                      >
                                        <div className="inner">
                                          <div
                                            className="hover-wrap"
                                            style={{ opacity: "1" }}
                                          >
                                            <div className="hover-wrap-inner">
                                              <img
                                                className="img-with-animation skip-lazy animated-in"
                                                data-delay="0"
                                                height="120"
                                                width="380"
                                                data-animation="fade-in"
                                                src="https://www.uhc.ca/wp-content/uploads/2021/02/FB.png"
                                                alt="Facebook icon"
                                                srcSet="
                                https://www.uhc.ca/wp-content/uploads/2021/02/FB.png        380w,
                                https://www.uhc.ca/wp-content/uploads/2021/02/FB-300x95.png 300w
                              "
                                                sizes="(min-width: 1450px) 75vw, (min-width: 1000px) 85vw, 100vw"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="vc_col-sm-1/5 wpb_column column_container vc_column_container col child_column boxed no-extra-padding inherit_tablet inherit_phone"
                                  data-shadow="small_depth"
                                  data-padding-pos="all"
                                  data-has-bg-color="false"
                                  data-bg-color=""
                                  data-bg-opacity="1"
                                  data-animation=""
                                  data-delay="0"
                                >
                                  <div className="vc_column-inner">
                                    <a
                                      className="column-link"
                                      target="_blank"
                                      href="https://twitter.com/uhc_"
                                    >
                                      <span className="screen-reader-text">
                                        Link to UHC's Twitter page
                                      </span>
                                    </a>
                                    <div
                                      className="column-bg-overlay-wrap"
                                      data-bg-animation="none"
                                    >
                                      <div className="column-bg-overlay"></div>
                                    </div>
                                    <div className="wpb_wrapper">
                                      <div
                                        className="img-with-aniamtion-wrap"
                                        data-max-width="100%"
                                        data-max-width-mobile="default"
                                        data-shadow="none"
                                        data-animation="fade-in"
                                      >
                                        <div className="inner">
                                          <div
                                            className="hover-wrap"
                                            style={{ opacity: "1" }}
                                          >
                                            <div className="hover-wrap-inner">
                                              <img
                                                className="img-with-animation skip-lazy animated-in"
                                                data-delay="0"
                                                height="120"
                                                width="380"
                                                data-animation="fade-in"
                                                src="https://www.uhc.ca/wp-content/uploads/2021/02/Twitter.png"
                                                alt="Twitter icon"
                                                srcSet="
                                https://www.uhc.ca/wp-content/uploads/2021/02/Twitter.png        380w,
                                https://www.uhc.ca/wp-content/uploads/2021/02/Twitter-300x95.png 300w
                              "
                                                sizes="(min-width: 1450px) 75vw, (min-width: 1000px) 85vw, 100vw"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="vc_col-sm-1/5 wpb_column column_container vc_column_container col child_column boxed no-extra-padding inherit_tablet inherit_phone"
                                  data-shadow="small_depth"
                                  data-padding-pos="all"
                                  data-has-bg-color="false"
                                  data-bg-color=""
                                  data-bg-opacity="1"
                                  data-animation=""
                                  data-delay="0"
                                >
                                  <div className="vc_column-inner">
                                    <a
                                      className="column-link"
                                      target="_blank"
                                      href="https://www.youtube.com/channel/UC-w1kuomxKRfdSc9WtqugxA"
                                    >
                                      <span className="screen-reader-text">
                                        Link to UHC's YouTube page
                                      </span>
                                    </a>
                                    <div
                                      className="column-bg-overlay-wrap"
                                      data-bg-animation="none"
                                    >
                                      <div className="column-bg-overlay"></div>
                                    </div>
                                    <div className="wpb_wrapper">
                                      <div
                                        className="img-with-aniamtion-wrap"
                                        data-max-width="100%"
                                        data-max-width-mobile="default"
                                        data-shadow="none"
                                        data-animation="fade-in"
                                      >
                                        <div className="inner">
                                          <div
                                            className="hover-wrap"
                                            style={{ opacity: "1" }}
                                          >
                                            <div className="hover-wrap-inner">
                                              <img
                                                className="img-with-animation skip-lazy animated-in"
                                                data-delay="0"
                                                height="120"
                                                width="380"
                                                data-animation="fade-in"
                                                src="https://www.uhc.ca/wp-content/uploads/2021/02/YT.png"
                                                alt="YouTube icon"
                                                srcSet="
                                https://www.uhc.ca/wp-content/uploads/2021/02/YT.png        380w,
                                https://www.uhc.ca/wp-content/uploads/2021/02/YT-300x95.png 300w
                              "
                                                sizes="(min-width: 1450px) 75vw, (min-width: 1000px) 85vw, 100vw"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="vc_col-sm-1/5 wpb_column column_container vc_column_container col child_column no-extra-padding inherit_tablet inherit_phone"
                                  data-padding-pos="all"
                                  data-has-bg-color="false"
                                  data-bg-color=""
                                  data-bg-opacity="1"
                                  data-animation=""
                                  data-delay="0"
                                >
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper"></div>
                                  </div>
                                </div>

                                <div
                                  className="vc_col-sm-1/5 wpb_column column_container vc_column_container col child_column no-extra-padding inherit_tablet inherit_phone"
                                  data-padding-pos="all"
                                  data-has-bg-color="false"
                                  data-bg-color=""
                                  data-bg-opacity="1"
                                  data-animation=""
                                  data-delay="0"
                                >
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="footer-outer"
                data-midnight="light"
                data-cols="4"
                data-custom-color="true"
                data-disable-copyright="false"
                data-matching-section-color="true"
                data-copyright-line="true"
                data-using-bg-img="false"
                data-bg-img-overlay="0.8"
                data-full-width="false"
                data-using-widget-area="true"
                data-link-hover="default"
              >
                <div id="footer-widgets" data-has-widgets="true" data-cols="4">
                  <div className="container">
                    <div className="row">
                      <div className="col span_3">
                        <div id="text-2" className="widget widget_text">
                          <h4>Windsor Location</h4>
                          <div className="textwidget">
                            <p>
                              <a
                                href="https://goo.gl/maps/6Z2oiziCTaHkQ7Ko6"
                                target="_blank"
                                rel="noopener"
                              >
                                <strong>
                                  UHC &#8211; Hub of Opportunities
                                </strong>
                                <br />
                                6955 Cantelon Dr
                                <br />
                                Windsor ON N8T 3J9
                              </a>
                            </p>
                            <p>
                              <strong>Phone:</strong>{" "}
                              <a href="tel:519-944-4900">(519) 944-4900</a>
                              <br />
                              <strong>Email:</strong>{" "}
                              <a href="mailto:uhc@uhc.ca">uhc@uhc.ca</a>
                            </p>
                            <p>
                              <a
                                href="https://www.facebook.com/UHC.Windsor/"
                                target="_blank"
                                rel="noopener"
                              >
                                <img
                                  loading="lazy"
                                  className="alignleft wp-image-2543 size-full"
                                  src="https://www.uhc.ca/wp-content/uploads/2021/04/fb.png"
                                  alt="facebook"
                                  width="21"
                                  height="30"
                                />
                              </a>{" "}
                              <a
                                href="https://twitter.com/uhc_"
                                target="_blank"
                                rel="noopener"
                              >
                                <img
                                  loading="lazy"
                                  className="alignleft wp-image-2544 size-full"
                                  src="https://www.uhc.ca/wp-content/uploads/2021/04/tw.png"
                                  alt="twitter"
                                  width="38"
                                  height="30"
                                />
                              </a>{" "}
                              <a
                                href="https://www.youtube.com/channel/UC-w1kuomxKRfdSc9WtqugxA"
                                target="_blank"
                                rel="noopener"
                              >
                                <img
                                  loading="lazy"
                                  className="alignleft wp-image-2545 size-full"
                                  src="https://www.uhc.ca/wp-content/uploads/2021/04/yt.png"
                                  alt="youtube"
                                  width="38"
                                  height="30"
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col span_3">
                        <div id="text-3" className="widget widget_text">
                          <h4>Belle River Location</h4>
                          <div className="textwidget">
                            <p>
                              <a
                                href="https://goo.gl/maps/SFAau41MtngYoMo17"
                                target="_blank"
                                rel="noopener"
                              >
                                <strong>
                                  UHC &#8211; Hub of Opportunities
                                </strong>
                                <br />
                                612 Notre Dame St
                                <br />
                                Belle River ON N0R 1A0
                              </a>
                            </p>
                            <p>
                              <strong>Phone:</strong>{" "}
                              <a href="tel:519-944-4900">(519) 944-4900</a>
                              <br />
                              <strong>Email:</strong>{" "}
                              <a href="mailto:uhc@uhc.ca">uhc@uhc.ca</a>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col span_3">
                        <div id="nav_menu-3" className="widget widget_nav_menu">
                          <h4>Additional Information</h4>
                          <div className="menu-footer-menu-container">
                            <ul id="menu-footer-menu" className="menu">
                              <li
                                id="menu-item-3340"
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3340"
                              >
                                <a href="https://www.uhc.ca/contact-us/">
                                  Contact Us
                                </a>
                              </li>
                              <li
                                id="menu-item-60"
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-60"
                              >
                                <a href="https://www.uhc.ca/careers/">
                                  Careers with UHC
                                </a>
                              </li>
                              <li
                                id="menu-item-59"
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-59"
                              >
                                <a href="https://www.uhc.ca/partners/">
                                  Partners
                                </a>
                              </li>
                              <li
                                id="menu-item-58"
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-58"
                              >
                                <a href="https://www.uhc.ca/privacy-policy/">
                                  Privacy
                                </a>
                              </li>
                              <li
                                id="menu-item-57"
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-57"
                              >
                                <a href="https://www.uhc.ca/community-agencies-and-resources/">
                                  Resources
                                </a>
                              </li>
                              <li
                                id="menu-item-3892"
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3892"
                              >
                                <a href="https://www.uhc.ca/accessibility/">
                                  Accessibility
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col span_3">
                        <div
                          id="mc4wp_form_widget-2"
                          className="widget widget_mc4wp_form_widget"
                        >
                          <h4>Sign up for UHC Newsletters and Events</h4>
                          <script>
                            {(function () {
                              window.mc4wp = window.mc4wp || {
                                listeners: [],
                                forms: {
                                  on: function (evt, cb) {
                                    window.mc4wp.listeners.push({
                                      event: evt,
                                      callback: cb,
                                    });
                                  },
                                },
                              };
                            })()}
                          </script>

                          <form
                            id="mc4wp-form-1"
                            className="mc4wp-form mc4wp-form-2116 mc4wp-form-theme mc4wp-form-theme-dark"
                            method="post"
                            data-id="2116"
                            data-name="Sign up for UHC Newsletters and Events"
                          >
                            <div className="mc4wp-form-fields">
                              <p>
                                <input
                                  type="text"
                                  name="FNAME"
                                  title="First Name"
                                  placeholder="First Name"
                                  required=""
                                />
                              </p>
                              <p>
                                <input
                                  type="email"
                                  name="EMAIL"
                                  title="Email Address"
                                  placeholder="Email Address"
                                  required
                                />
                              </p>

                              <p>
                                <input
                                  type="submit"
                                  className="signUp"
                                  value="Sign up"
                                />
                              </p>
                            </div>
                            <label style={{ display: "none " }}>
                              Leave this field empty if you're human:{" "}
                              <input
                                type="text"
                                name="_mc4wp_honeypot"
                                value=""
                                tabIndex="-1"
                                autoComplete="off"
                              />
                            </label>
                            <input
                              type="hidden"
                              name="_mc4wp_timestamp"
                              value="1680023814"
                            />
                            <input
                              type="hidden"
                              name="_mc4wp_form_id"
                              value="2116"
                            />
                            <input
                              type="hidden"
                              name="_mc4wp_form_element_id"
                              value="mc4wp-form-1"
                            />
                            <div className="mc4wp-response"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row" id="copyright" data-layout="default">
                  <div className="container">
                    <div className="col span_5">
                      <p>
                        © UHC - Hub of Opportunities |{" "}
                        <a href="https://www.webplanet.ca" target="_blank">
                          Website designed by WP
                        </a>{" "}
                        <br />
                        Charitable Registration Number: 119275485 RR 0001{" "}
                      </p>
                    </div>

                    <div className="col span_7 col_last">
                      <ul className="social"></ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="slide-out-widget-area-bg"
                className="slide-out-from-right dark"
              ></div>

              <div
                id="slide-out-widget-area"
                className="slide-out-from-right"
                data-dropdown-func="separate-dropdown-parent-link"
                data-back-txt="Back"
              >
                <div className="inner-wrap">
                  <div className="inner" data-prepend-menu-mobile="false">
                    <a className="slide_out_area_close" href="#">
                      <span className="screen-reader-text">Close Menu</span>
                      <span className="close-wrap">
                        {" "}
                        <span className="close-line close-line1"></span>{" "}
                        <span className="close-line close-line2"></span>{" "}
                      </span>{" "}
                    </a>

                    <div className="off-canvas-menu-container mobile-only">
                      <ul className="menu">
                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-icon menu-item-4633">
                          <a href="https://www.uhc.ca/donate/">
                            <i className="nectar-menu-icon fa fa-heart"></i>
                            <span className="menu-title-text">Donate</span>
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-32">
                          <a href="https://www.uhc.ca/">Home</a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-3293">
                          <a>About</a>
                          <ul className="sub-menu">
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3297">
                              <a href="https://www.uhc.ca/about/">About Us</a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3292">
                              <a href="https://www.uhc.ca/programs-services/">
                                Programs &#038; Services
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3295">
                              <a href="https://www.uhc.ca/employment-opportunities/">
                                Employment Opportunities
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3294">
                              <a href="https://www.uhc.ca/customer-service-charter/">
                                Customer Service Charter
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3296">
                              <a href="https://www.uhc.ca/privacy-policy/">
                                Privacy Policy
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3299">
                              <a href="https://www.uhc.ca/covid-19-update/">
                                COVID-19 Update
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3298">
                              <a href="https://www.uhc.ca/uhc-in-the-news/">
                                UHC in the News
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-200">
                              <a href="https://www.uhc.ca/contact-us/">
                                Contact Us
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-44">
                          <a>Calendars</a>
                          <ul className="sub-menu">
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-179">
                              <a href="https://www.uhc.ca/events-workshops/">
                                Events &#038; Workshops
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-3311">
                          <a href="/employment-services/">Job Seekers</a>
                          <ul className="sub-menu">
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4206">
                              <a href="https://www.uhc.ca/job-post-board/">
                                Job Board
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2156">
                              <a href="https://www.uhc.ca/walk-in-resource-and-information-centre/">
                                Walk-In Resource and Information Centre
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2155">
                              <a href="https://www.uhc.ca/employment-counselling/">
                                One-On-One Career Counselling
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-3389">
                              <a href="https://www.uhc.ca/employment-programs/">
                                Employment Programs
                              </a>
                              <ul className="sub-menu">
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-71">
                                  <a href="https://www.uhc.ca/youth-job-connection/">
                                    Youth Job Connection
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1501">
                                  <a href="https://www.uhc.ca/youth-job-connection-summer/">
                                    Youth Job Connection Summer
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-73">
                                  <a href="https://www.uhc.ca/cook-pre-apprenticeship-program/">
                                    Cook Pre-Apprenticeship Program
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-104">
                                  <a href="https://www.uhc.ca/ready-to-work/">
                                    Ready to Work Program
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3313">
                                  <a href="https://www.uhc.ca/?page_id=3280">
                                    Beyond COVID Job Preparedness
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-3386">
                              <a href="https://www.uhc.ca/training-education/">
                                Training &#038; Education
                              </a>
                              <ul className="sub-menu">
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1954">
                                  <a href="https://www.uhc.ca/employment-skills-training/">
                                    Employment Skills Training
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2157">
                                  <a href="https://www.uhc.ca/better-jobs-ontario/">
                                    Better Jobs Ontario
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-92">
                                  <a href="https://www.uhc.ca/literacy-and-basic-skills/">
                                    Literacy and Basic Skills
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-93">
                                  <a href="https://www.uhc.ca/ged-preparation-program/">
                                    GED Preparation Program
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3315">
                                  <a href="https://www.uhc.ca/computer-workshops/">
                                    Computer Workshops
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-4343">
                              <a
                                href="/create-account-login/"
                                aria-current="page"
                              >
                                Forms, Tools &#038; Resources
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-41">
                          <a href="/employers/">Employers</a>
                          <ul className="sub-menu">
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-171">
                              <a href="https://www.uhc.ca/post-a-job/">
                                Hiring (Job Positions)
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-173">
                              <a href="https://www.uhc.ca/job-matching-placement-incentives/">
                                Job Matching Placement &#038; Incentives
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-174">
                              <a href="https://www.uhc.ca/canada-ontario-job-grant/">
                                Canada Ontario Job Grant
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3317">
                              <a href="https://www.uhc.ca/closures/">
                                Closures
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3318">
                              <a href="https://www.uhc.ca/pre-apprenticeship/">
                                Pre-Apprenticeship
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1321">
                              <a href="https://www.uhc.ca/employer-workshops/">
                                Employer Workshops
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3319">
                              <a href="https://www.uhc.ca/forms-tools-resources/">
                                Forms, Tools &#038; Resources
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-3320">
                          <a href="/newcomers/">Newcomers</a>
                          <ul className="sub-menu">
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-123">
                              <a href="https://www.uhc.ca/settlement-workers/">
                                Settlement Services
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-106">
                              <a href="https://www.uhc.ca/english-classes/">
                                English Classes
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-103">
                              <a href="https://www.uhc.ca/care-for-newcomer-children-program/">
                                Care for Newcomer Children Program
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-105">
                              <a href="https://www.uhc.ca/computer-workplace-skills-training/">
                                Computer &#038; Workplace Skills Training
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3321">
                              <a href="https://www.uhc.ca/ready-to-work/">
                                Ready to Work Program
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-122">
                              <a href="https://www.uhc.ca/orientation-workshops/">
                                Orientation Workshops
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-121">
                              <a href="https://www.uhc.ca/connecting-newcomer-youth-ages-14-24-evening-programs/">
                                Connecting Newcomer Youth &#8211; Ages 14-24
                                (Evening Program)
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-120">
                              <a href="https://www.uhc.ca/computers-for-youth-ages-7-12-summer-program/">
                                Computers for Youth &#8211; Ages 7-12 (Summer
                                Program)
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-119">
                              <a href="https://www.uhc.ca/conversation-circle-summer-program/">
                                Conversation Circle (Summer Program)
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-3322">
                          <a href="/support-services/">Support Services</a>
                          <ul className="sub-menu">
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-133">
                              <a href="https://www.uhc.ca/food-bank/">
                                Food Bank Program
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-157">
                              <a href="https://www.uhc.ca/keep-the-heat/">
                                Energy Assistance
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-158">
                              <a href="https://www.uhc.ca/coats-for-kids/">
                                Coats for Kids
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-156">
                              <a href="https://www.uhc.ca/phone-access/">
                                Phone Access
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-155">
                              <a href="https://www.uhc.ca/?page_id=149">
                                Income Tax Assistance
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-154">
                              <a href="https://www.uhc.ca/shuttling-for-success/">
                                Shuttle Services
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-140">
                              <a href="https://www.uhc.ca/meals-on-wheels/">
                                Meals on Wheels
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-3323">
                          <a href="/food-assistance/">Food Initiatives</a>
                          <ul className="sub-menu">
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3324">
                              <a href="https://www.uhc.ca/food-bank/">
                                Food Bank Program
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-131">
                              <a href="https://www.uhc.ca/plentiful-harvest-food-rescue/">
                                Plentiful Harvest Food Rescue Program
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-142">
                              <a href="https://www.uhc.ca/community-kitchen/">
                                Community Kitchen
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-132">
                              <a href="https://www.uhc.ca/farm-to-food/">
                                Farm to Food Program
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-141">
                              <a href="https://www.uhc.ca/community-garden/">
                                Community Garden
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3336">
                              <a href="https://www.uhc.ca/leamington-regional-food-hub/">
                                Leamington Regional Food Hub
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-45">
                          <a>Support Us</a>
                          <ul className="sub-menu">
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4556">
                              <a href="https://www.uhc.ca/?page_id=4535">
                                CBC – Sounds of the Season
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-189">
                              <a href="https://www.uhc.ca/donate/">
                                Monetary Donations
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3512">
                              <a href="https://www.uhc.ca/food-donations/">
                                Food Donations
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-187">
                              <a href="https://www.uhc.ca/?page_id=185">
                                Gift Giving Tree
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-190">
                              <a href="https://www.uhc.ca/coats-for-kids/">
                                Coats for Kids
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-194">
                              <a href="https://www.uhc.ca/sponsorships/">
                                Event Sponsorships
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-203">
                              <a href="https://www.uhc.ca/volunteer/">
                                Volunteer
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="social menu-item menu-item-type-custom menu-item-object-custom menu-item-2775">
                          <a
                            target="_blank"
                            rel="noopener"
                            href="https://www.facebook.com/UHC.Windsor/"
                          >
                            <img
                              src="/wp-content/uploads/2021/04/FB-navtop.gif"
                              alt="Facebook"
                              style={{ width: "25px" }}
                            />
                          </a>
                        </li>
                        <li className="social menu-item menu-item-type-custom menu-item-object-custom menu-item-2782">
                          <a
                            target="_blank"
                            rel="noopener"
                            href="https://twitter.com/uhc_"
                          >
                            <img
                              src="/wp-content/uploads/2021/04/Twitter-nav.gif"
                              alt="Twitter"
                              width="25"
                            />
                          </a>
                        </li>
                        <li className="social menu-item menu-item-type-custom menu-item-object-custom menu-item-2784">
                          <a
                            target="_blank"
                            rel="noopener"
                            href="https://www.youtube.com/channel/UC-w1kuomxKRfdSc9WtqugxA"
                          >
                            <img
                              src="/wp-content/uploads/2021/04/YT-Nav.gif"
                              alt="YouTube"
                              width="25"
                            />
                          </a>
                        </li>
                      </ul>

                      <ul className="menu secondary-header-items"></ul>
                    </div>
                  </div>

                  <div className="bottom-meta-wrap">
                    <ul className="off-canvas-social-links mobile-only"></ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
