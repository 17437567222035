import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { NotificationManager } from "react-notifications";
import { authenticationService } from "../../_services";
import { eventService } from "../../_services/staff";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import EventInfoBox from "../../components/user/EventInfoBox";
import Avatar from "@material-ui/core/Avatar";
import { Tooltip } from "reactstrap";
import RegistrationConfirmation from "../../shared/RegistrationConfirmation";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
function mapStateToProps(state) {
  return {
    workshopChangeId: state.workshopChangeId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
  };
}

class EventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      userFromApi: null,
      eventList: [],
      registeredEventList: [],
      isHovering: false,
      selectedTab: "all",
      loading: true,
      profilePics: [],
      hasEvent: false,
      noEvent: false,
      contactModal: false,
    };
    this.getAllCurrentEvents = this.getAllCurrentEvents.bind(this);
  }

  componentDidMount() {
    const { currentUser } = this.state;
    this.getAllCurrentEvents();
    if (window.Websdk) {
      window.Websdk.q.push(["track_pageview", "event-list"]);
    }
  }

  componentDidUpdate(prevProps) {
    let prevWorkshopId = prevProps.workshopChangeId;
    let newWorkshopId = this.props.workshopChangeId;
    if (prevWorkshopId !== newWorkshopId) {
      //alert("Update state now!!");
      if (this.state.selectedTab === "all") {
        this.getAllCurrentEvents();
      }
    }
  }

  toggleContact = () => {
    this.setState((state) => {
      return { contactModal: !state.contactModal };
    });
  };

  deleteRegistered = (id) => {
    let requestBody = {
      eventRegistrationId: id,
      currentUserId: this.state.currentUser.id,
    };
    console.log("..........", requestBody);
    eventService.deleteRegisteredEvents(requestBody).then((data) => {
      console.log("resssss", data);
      NotificationManager.success("Successfully Removed", "", 5000);
      this.getRegistered();
    });
  };

  deleteWishlisted = (id) => {
    let requestBody = {
      eventWishlistId: id,
      currentUserId: this.state.currentUser.id,
    };
    console.log("..........", requestBody);
    eventService.deleteWishlistedEvents(requestBody).then((data) => {
      console.log("resssss", data);
      NotificationManager.success("Successfully Removed", "", 5000);
      this.getWishlist();
    });
  };

  registerForEvent(eventId, index, link) {
    let registrationData = {
      userId: this.state.currentUser.id,
      userName: `${this.state.currentUser.firstName} ${this.state.currentUser.lastName}`,
      userEmail: this.state.currentUser.email,
      eventId: eventId,
      currentUserId: this.state.currentUser.id,
    };
    // document.getElementById(index).innerHTML= "Registered"
    eventService
      .registerEvent(registrationData)
      .then((data) => {
        console.log("data: ", data);
        if (data.Status) {
          NotificationManager.success("Successfully Registered", "", 5000);
          this.getAllCurrentEvents();
        } else {
          console.log("register event data on err: ", data);
        }
      })
      .catch((error) => NotificationManager.error(error, "", 5000));
  }

  getAllCurrentEvents() {
    this.setState({
      eventList: [],
    });
    eventService
      .getAllCurrentEvents(
        new Date().getTime(),
        this.state.currentUser.id,
        "event"
      )
      .then((data) => {
        if (data.Status && data.Data.length) {
          console.log(data.Data);
          this.setState({
            eventList: data.Data,
            selectedTab: "all",
            hasEvent: true,
            noEvent: false,
          });
          // data.Data.forEach((element, index) => {
          //   this.generate(index);
          // });
          this.setState({
            loading: false,
          });
          //this.getAll();
        } else {
          //this.getAll();
          this.setState({
            loading: false,
            hasEvent: false,
            noEvent: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  }

  getAll = () => {
    document.getElementById("all").style.borderBottom = "5px solid #0f4e91";
    document.getElementById("registered").style.borderBottom = "none";
    document.getElementById("wishlist").style.borderBottom = "none";
    // this.getAllCurrentEvents()
  };

  getRegistered = () => {
    this.setState({
      eventList: [],
    });
    document.getElementById("registered").style.borderBottom =
      "5px solid #0f4e91";
    document.getElementById("all").style.borderBottom = "none";
    document.getElementById("wishlist").style.borderBottom = "none";
    eventService
      .getAllRegisteredEvents(this.state.currentUser.id)
      .then((data) => {
        if (data.Status && data.Data.length) {
          console.log(data.Data);
          data.Data.forEach((item) => {
            if (item && item.eventDetails && item.eventDetails.date) {
              this.setState({
                eventList: [...this.state.eventList, item],
                selectedTab: "registered",
                hasEvent: true,
                noEvent: false,
              });
            }
          });
          // this.state.eventList.forEach((element, index) => {
          //   this.generate(index);
          // });
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            hasEvent: false,
            noEvent: true,
            loading: false,
          });
        }
      });
  };

  getWishlist = () => {
    this.setState({
      eventList: [],
    });
    document.getElementById("wishlist").style.borderBottom =
      "5px solid #0f4e91";
    document.getElementById("all").style.borderBottom = "none";
    document.getElementById("registered").style.borderBottom = "none";
    eventService
      .getAllWishlistEvents(this.state.currentUser.id)
      .then((data) => {
        if (data.Status && data.Data.length) {
          console.log(data.Data);
          data.Data.forEach((item) => {
            if (item && item.eventDetails && item.eventDetails.date) {
              this.setState({
                eventList: [...this.state.eventList, item],
                selectedTab: "wishlist",
                hasEvent: true,
                noEvent: false,
              });
            }
          });
          // this.state.eventList.forEach((element, index) => {
          //   this.generate(index);
          // });
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            hasEvent: false,
            noEvent: true,
            loading: false,
          });
        }
      });
  };

  generate = (id, gradientColor) => {
    var gradients = [
      "linear-gradient(110deg, #53a2ff, #2a387b)",
      "linear-gradient(240deg, #ffaf8a, #ff6263)",
      "linear-gradient(240deg, #e0ec51, #2eb18d)",
      "linear-gradient(242deg, #f49cae, #b05ce2)",
      "linear-gradient(240deg, #8affe7, #5939e0)",
      "linear-gradient(240deg, #fddb92, #09adef)",
      "linear-gradient(240deg, #ffaf8a, #ff6263)",
      "linear-gradient(240deg, #bdd377, #09adef)",
      "linear-gradient(240deg, #0a7dd8, #21c8db)",
      "linear-gradient(240deg, #ff6364, #ff88bc)",
      "linear-gradient(240deg, #55e4ef, #c23af1)",
    ];
    if (gradientColor) {
      return gradientColor;
    } else {
      if (id >= gradients.length - 1) {
        return gradients[Math.floor(Math.random() * gradients.length)];
      } else {
        return gradients[id];
      }
    }
  };

  changeIconColor = (id, eventId) => {
    if (document.getElementById(id).style[0] === "color") {
      // document.getElementById(id).style.removeProperty('color');
      document.getElementById(id).disabled = true;
    } else {
      document.getElementById(id).style.color = "blue";
    }
    let whishlistData = {
      userId: this.state.currentUser.id,
      userName: `${this.state.currentUser.firstName} ${this.state.currentUser.lastName}`,
      userEmail: this.state.currentUser.email,
      eventId: eventId,
      currentUserId: this.state.currentUser.id,
    };
    eventService
      .addToWishlist(whishlistData)
      .then((data) => {
        if (data.Status) {
          NotificationManager.success("Added to Wishlist", "", 5000);
          this.getAllCurrentEvents();
        }
        // else {
        //   console.log("register event data on err: ", data)
        // }
      })
      .catch((error) => NotificationManager.error(error, "", 5000));
  };

  showIsRegistered = (item, index) => {
    var now = moment(new Date(item.dateTime));
    var end = moment(new Date());
    var duration = moment.duration(now.diff(end));
    var hours = duration.asHours();
    return (
      <React.Fragment>
        {hours > 0 && hours <= 0.5 ? (
          <a
            href={item.accessLink}
            target="_blank"
            className="btn register_btn mr-3 w-100"
          >
            Join Now
          </a>
        ) : (
          <a
            id={index}
            style={{ color: "white" }}
            className="btn btn-dark mr-3 register_btn w-100 disabled"
          >
            Registered
          </a>
        )}
      </React.Fragment>
    );
  };

  render() {
    const { eventList } = this.state;
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Events</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card eventList">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="align-self-center tbl-head">
                          <i className="mdi mdi-calendar-text mr-3"></i>
                          <span>UPCOMING EVENTS</span>
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex groupofcard">
                        {eventList.length
                          ? eventList.map((item, index) => {
                              if (item && item.eventDetails) {
                                let registrationOrWishlistId = item._id;
                                item = item.eventDetails;
                                item.registrationOrWishlistId = registrationOrWishlistId;
                                if (this.state.selectedTab === "wishlist") {
                                  item.isWishlisted = true;
                                } else {
                                  item.isRegistered = true;
                                }
                              }
                              return (
                                <React.Fragment key={index}>
                                  <div
                                    className="card m-3 [ info-card ]"
                                    style={{
                                      width: "18rem",
                                    }}
                                  >
                                    {item.bannerImage ? (
                                      <div
                                        style={{
                                          backgroundImage: `url(${item.bannerImage})`,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                        }}
                                        className="d-flex py-3 border-bottom setheight justify-content-center"
                                      >
                                        <div className="event_name align-self-center transbox">
                                          {item.name.toUpperCase()}
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        id={"grad-" + index}
                                        className="d-flex py-3 border-bottom setheight justify-content-center"
                                        style={{
                                          background: this.generate(
                                            index,
                                            item.gradientColor
                                          ),
                                        }}
                                      >
                                        <div className="event_name align-self-center">
                                          {item.name.toUpperCase()}
                                        </div>
                                      </div>
                                    )}
                                    <div className="datetimebox">
                                      <div>
                                        {moment(item.dateTime).format(
                                          "dddd, MMM Do YYYY"
                                        )}
                                      </div>
                                      {moment(item.startTime).format("hh:mm A")}{" "}
                                      -{moment(item.endTime).format("hh:mm A")}
                                    </div>
                                    {/* <img className="card-img-top" src="..." alt="Card image cap" /> */}
                                    <div className="card-body">
                                      <h3 className="card-title description_title">
                                        Description
                                      </h3>
                                      <div
                                        className="card-text description"
                                        dangerouslySetInnerHTML={{
                                          __html: item.description,
                                        }}
                                      ></div>
                                      <div className="d-flex event_location">
                                        <div className="d-flex mr-3">
                                          <div className="card-title mr-1">
                                            <i className="mdi mdi-map-marker"></i>
                                          </div>
                                          <div>{item.location || "Online"}</div>
                                        </div>
                                        {item.pdfLink ? (
                                          <div className="d-flex mr-3">
                                            <div className="card-title mr-1">
                                              <i className="mdi mdi-map-marker"></i>
                                            </div>
                                            <a
                                              href={item.pdfLink}
                                              rel="noopener noreferrer"
                                              target="_blank"
                                            >
                                              More Info
                                            </a>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>

                                    <div className="card-footer">
                                      <a
                                        href={item.accessLink}
                                        className="btn register_btn mr-3 w-100"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Register
                                      </a>

                                      {/* <small className="text-muted">Last updated 3 mins ago</small> */}
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            })
                          : this.state.noEvent && (
                              <div className="col-md-12 mt-4 mb-4">
                                <div
                                  style={{
                                    color: "rgba(0,0,0,.5)",
                                  }}
                                  className="text-center"
                                >
                                  <h3
                                    style={{
                                      color: "rgba(0,0,0,.5)",
                                    }}
                                  >
                                    No upcoming Events
                                  </h3>
                                </div>
                              </div>
                            )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
        <Modal
          isOpen={this.state.contactModal}
          toggle={this.toggleContact}
          centered
        >
          <ModalHeader toggle={this.toggleContact}>Registration</ModalHeader>
          <ModalBody>
            Please Contact UHC for details regarding registation.
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
