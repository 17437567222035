import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import { Link } from "react-router-dom";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import FeedbackCategory from "../../components/user/FeedbackCategory";
import { setUserData } from "../../redux-store/action";
import { authenticationService } from "../../_services";
import { NotificationManager } from "react-notifications";
import { contentLibraryService } from "../../_services/staff";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  FormText,
  Col,
} from "reactstrap";
import { FileIcon, defaultStyles } from "react-file-icon";
import ReactPlayer from "react-player";
import VideoThumbnail from "react-video-thumbnail";
import ReactStars from "react-rating-stars-component";
import { Card } from "react-bootstrap";
import { getFileExtension } from "../../_helpers/_helperFunctions";
import {
  AUDIO_FILES,
  VIDEO_FILES,
  DOCS_AND_IMAGES,
  IMAGE_TYPES,
} from "../../_helpers";
import { CustomModal } from "../../components/CustomModal";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

function startFileWatchEvent() {
  if (window.Websdk) {
    window.Websdk.q.push(["start_event", "fileWatch"]);
  }
}

function stopFileWatchEvent(file, user) {
  if (window.Websdk) {
    window.Websdk.q.push([
      "end_event",
      {
        key: "fileWatch",
        segmentation: {
          fileId: file._id,
          fileName: file.originalname,
          fileTitle: file.title,
          userName: user.firstName + " " + user.lastName,
          userId: user.id,
          userEmail: user.email,
        },
      },
    ]);
  }
}

function flagFileAsViewed(file, user) {
  if (file.fileView?.length === 0) {
    let flagFileData = {
      fileId: file._id,
      currentUserId: user.id,
    };
    contentLibraryService.flagAsViewed(flagFileData).then((data) => {});
  }
}

function recordFileWatchEvent(file, user) {
  if (window.Websdk) {
    window.Websdk.q.push([
      "add_event",
      {
        key: "fileWatch",
        segmentation: {
          fileId: file._id,
          fileName: file.originalname,
          fileTitle: file.title,
          userName: user.firstName + " " + user.lastName,
          userId: user.id,
          userEmail: user.email,
        },
      },
    ]);
  }
}

class CategoryDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      loading: true,
      contentCategory: {},
      contentFiles: [],
      staffOptions: [],
      isModalOpen: false,
      search: "",
      contentFilesCopy: [],
      audioModal: false,
      videoModal: false,
      selectedFileType: "",
      feedbackModal: false,
      feedbackCategory: "",
      feedbackComment: "",
      allowFeedback: false,
      mediaUrl: "",
      filename: "",
      fileId: "",
      fileViewer: false,
      selectedFile: null,
      fileType: "",
    };

    this.toggleAddFileModal = this.toggleAddFileModal.bind(this);
    this.toggleFeedbackModal = this.toggleFeedbackModal.bind(this);
    this.handleFeedbackCategory = this.handleFeedbackCategory.bind(this);
    this.handleRatingChanged = this.handleRatingChanged.bind(this);
    this.handleFeedbackFormSubmit = this.handleFeedbackFormSubmit.bind(this);
  }
  componentDidMount() {
    this.props.setUserData(this.state.currentUser);
    this.getCategroryDetail();
    this.getFilesByCategoryId();
    this.setState({ loading: false });
  }

  toggleAudio = () => {
    // this.setState({
    //   audioModal: !(this.state.audioModal),
    //   selectedFileType: 'audio'
    // })

    this.setState((state) => {
      if (state.videoModal) {
        stopFileWatchEvent(state.selectedFile, state.currentUser);
      }
      return {
        audioModal: !state.audioModal,
        selectedFileType: "audio",
      };
    });
  };

  toggleVideo = () => {
    // this.setState({
    //   videoModal: !(this.state.videoModal),
    //   selectedFileType: 'video'
    // })
    this.setState((state) => {
      if (state.videoModal) {
        stopFileWatchEvent(state.selectedFile, state.currentUser);
      }
      return {
        videoModal: !state.videoModal,
        selectedFileType: "video",
      };
    });
  };

  toggleFeedbackModal = () => {
    if (
      this.state.selectedFile.feedback &&
      !this.state.selectedFile.feedbackData.length
    ) {
      this.setState({
        feedbackModal: !this.state.feedbackModal,
      });
    }
  };

  togglefileViewer = () => {
    this.setState((state) => {
      let setStateObj = { fileViewer: !state.fileViewer };
      if (state.fileViewer) {
        if (
          this.state.selectedFile.feedback &&
          !this.state.selectedFile.feedbackData.length
        ) {
          setStateObj.feedbackModal = true;
        }
        return setStateObj;
      }
      return setStateObj;
    });
  };

  getCategroryDetail() {
    this.props.match.params.id &&
      contentLibraryService
        .getContentLibaryCategroryById(this.props.match.params.id)
        .then((data) => {
          // track content library viewed
          if (window.Websdk) {
            window.Websdk.q.push(["track_pageview", data.Data.name]);
          }
          this.setState({ contentCategory: data.Data, loading: false });
        });
  }

  getFilesByCategoryId() {
    this.props.match.params.id &&
      contentLibraryService
        .getFilesByCategoryId(
          this.props.match.params.id,
          this.state.currentUser.id
        )
        .then((data) => {
          console.log("Files --------> : ", data.Data);
          this.setState({
            contentFiles: data.Data,
            loading: false,
            contentFilesCopy: data.Data,
          });
        });
  }

  handleFeedbackCategory(value) {
    this.setState({ feedbackCategory: value });
  }

  handleRatingChanged(rating) {
    console.log("rating: ", rating);
    this.setState({ feedbackRatings: rating });
  }

  handleFeedbackFormSubmit() {
    let { hasFoundHelpful, feedbackComment, feedbackRatings } = this.state;

    if (!feedbackRatings) {
      NotificationManager.error("Feedback Ratings is required!", "", 5000);
      return null;
    }

    if (!hasFoundHelpful) {
      NotificationManager.error(
        "Please mention if whether or not you found resource to be helpful!",
        "",
        5000
      );
      return null;
    }

    let formdata = {
      currentUserId: this.state.currentUser.id,
      userName:
        this.state.currentUser.firstName +
        " " +
        this.state.currentUser.lastName,
      userEmail: this.state.currentUser.email,
      contentFileId: this.state.fileId,
      hasFoundHelpful: hasFoundHelpful,
      comment: feedbackComment,
      rating: feedbackRatings,
    };

    contentLibraryService.resourcefeedback(formdata).then((data) => {
      this.toggleFeedbackModal();
      this.setState({
        feedbackCategory: "",
        feedbackComment: "",
        feedbackRatings: 0,
        hasFoundHelpful: "",
      });
      NotificationManager.success("Feedback submitted successfully!", "", 5000);
    });
  }

  getFileFromKey(key, filename, fileId, file) {
    let requestBody = { key, fileId };
    contentLibraryService.getSignedLinkFromKey(requestBody).then((data) => {
      let fileExtension = getFileExtension(key);
      if (AUDIO_FILES.includes(fileExtension)) {
        this.setState({
          mediaUrl: data.Data.url,
          filename: filename,
          fileId: fileId,
          selectedFile: file,
        });
        this.toggleAudio();
      } else if (VIDEO_FILES.includes(fileExtension)) {
        this.setState({
          mediaUrl: data.Data.url,
          filename: filename,
          fileId: fileId,
          selectedFile: file,
        });
        this.toggleVideo();
      } else if (DOCS_AND_IMAGES.includes(fileExtension)) {
        this.setState({
          mediaUrl: data.Data.url,
          filename: filename,
          fileId: fileId,
          selectedFile: file,
          fileType: getFileExtension(key),
          selectedFileType: "file",
        });
        flagFileAsViewed(file, this.state.currentUser);

        this.togglefileViewer();
      } else {
        recordFileWatchEvent(file, this.state.currentUser);
        flagFileAsViewed(file, this.state.currentUser);
        window.open(data.Data.url, "_blank");
      }
    });
  }

  deleteFile(fileId) {
    let requestBody = {
      fileId: fileId,
      categoryId: this.state.contentCategory._id,
      currentUserId: this.state.currentUser.id,
    };
    contentLibraryService.deleteFile(requestBody).then((data) => {
      this.getFilesByCategoryId();
      this.getCategroryDetail();
    });
  }

  deleteCategrory() {
    if (this.state.contentCategory._id) {
      contentLibraryService
        .deleteContentCategory(
          this.state.contentCategory._id,
          this.state.currentUser.id
        )
        .then((data) => {
          const { from } = this.props.location.state || {
            from: { pathname: "/staff/contents-library" },
          };
          this.props.history.push(from);
        });
    }
  }

  toggleAddFileModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  onChangeFileHandler = (event) => {
    this.setState({ selectedFiles: event.target.files });
  };

  uploadFile = () => {
    if (this.state.contentCategory._id) {
      let data = new FormData();
      data.append("name", this.state.contentCategory.name);
      data.append("currentUserId", this.state.currentUser.id);
      if (this.state.selectedFiles) {
        for (var x = 0; x < this.state.selectedFiles.length; x++) {
          data.append("files", this.state.selectedFiles[x]);
        }
      }
      contentLibraryService
        .uploadFileToContentLibrary(this.state.contentCategory._id, data)
        .then((result) => {
          this.toggleAddFileModal();
          this.getFilesByCategoryId();
          this.getCategroryDetail();
        });
    } else {
      this.toggleAddFileModal();
    }
  };

  filterCard = (event) => {
    // this.setState({
    //   search: event.target.value
    // })
    if (event.target.value) {
      let filtered = this.state.contentFilesCopy.filter((value) => {
        console.log(value);
        return value.title
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      this.setState({
        contentFiles: filtered,
      });
    } else {
      this.setState({
        contentFiles: this.state.contentFilesCopy,
      });
    }
  };

  handleFeedbackComment = (event) => {
    this.setState({ feedbackComment: event.target.value });
  };

  getThumbnail = (file) => {
    let fileExtension = file.originalname.split(".");

    if (VIDEO_FILES.includes(fileExtension[1])) {
      return <VideoThumbnail videoUrl={file.location} />;
    } else if (IMAGE_TYPES.includes(fileExtension[1].toLowerCase())) {
      return (
        <Card.Img
          top
          width="100%"
          src={file.location}
          alt={file.title ? file.title : file.originalname}
          className="cursor-pointer cardRatio"
          onClick={() => {
            this.getFileFromKey(
              file.key,
              file.title || file.originalname,
              file._id,
              file
            );
          }}
        />
      );
    } else {
      return (
        <FileIcon
          extension={fileExtension[1]}
          {...defaultStyles[fileExtension[1]]}
        />
      );
    }
  };

  handleFoundHelpfulChanged = (e) => {
    this.setState({ hasFoundHelpful: e.target.value });
  };

  getConfig = (downloadOption) => {
    if (downloadOption) {
      return {};
    } else {
      return {
        file: {
          attributes: {
            controlslist: "nodownload",
          },
        },
      };
    }
  };

  render() {
    let { contentFiles, contentCategory } = this.state;
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading ? <Preloader /> : ""}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">Category Detail</h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Resource Library</li>
                  <li className="breadcrumb-item active">Category Detail</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <div>
                              <Link
                                to="/contents-library"
                                className="btn btn-primary"
                              >
                                <i className="mdi mdi-chevron-left"></i>
                                <span className="hide-menu">Back</span>
                              </Link>
                            </div>
                            <div className="d-flex">
                              <div className="mr-2">
                                <input
                                  type="text"
                                  onChange={this.filterCard}
                                  className="form-control"
                                  placeholder="search file"
                                />
                                <i className="mdi mdi-magnify search-icon"></i>
                              </div>
                              {this.state.currentUser.accessType == 2 && (
                                <div className="mr-2">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.toggleAddFileModal()}
                                  >
                                    <i className="mdi mdi-plus"></i>
                                    Add Files
                                  </button>

                                  <Modal
                                    isOpen={this.state.isModalOpen}
                                    toggle={this.toggleAddFileModal}
                                  >
                                    <ModalHeader
                                      toggle={this.toggleAddFileModal}
                                    >
                                      Add Files
                                    </ModalHeader>
                                    <ModalBody>
                                      <Form>
                                        <FormGroup row>
                                          <Col sm={12}>
                                            <Input
                                              type="file"
                                              name="file"
                                              id="exampleFile"
                                              multiple
                                              onChange={
                                                this.onChangeFileHandler
                                              }
                                            />
                                            <FormText color="muted">
                                              Maximum 4 files allowed.
                                            </FormText>
                                          </Col>
                                        </FormGroup>
                                      </Form>
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button
                                        color="primary"
                                        onClick={this.uploadFile}
                                      >
                                        Upload Files
                                      </Button>{" "}
                                      <Button
                                        color="secondary"
                                        onClick={this.toggleAddFileModal}
                                      >
                                        Cancel
                                      </Button>
                                    </ModalFooter>
                                  </Modal>
                                </div>
                              )}
                              {this.state.currentUser.accessType == 2 && (
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.deleteCategrory()}
                                  >
                                    Delete Category
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="d-flex line-background justify-content-between">
                            <span className="">
                              {contentCategory.name ? contentCategory.name : ""}
                            </span>
                          </div>

                          <div className="pt-3">
                            <div className="row">
                              {contentFiles.length ? (
                                contentFiles.map((file, index) => {
                                  let filename = file.originalname;
                                  let ext = filename.split(".");

                                  return (
                                    <div className="col-md-3" key={index}>
                                      <Card>
                                        <div className="image-parent">
                                          {/* <Card.Img top width="100%" src={(file.thumbnail)? file.thumbnail: imageList[index] || defaultImage} 
                                      alt={(file.title)? file.title : file.originalname}
                                      className="cursor-pointer"
                                      onClick={() => { this.getFileFromKey(file.key,file.title || file.originalname, file._id) }} 
                                      /> */}
                                          {this.getThumbnail(file)}

                                          <Card.Body className="p-1 px-2 card-bg-color">
                                            <Card.Text
                                              className="cursor-pointer mb-0"
                                              onClick={() => {
                                                this.getFileFromKey(
                                                  file.key,
                                                  file.title ||
                                                    file.originalname,
                                                  file._id,
                                                  file
                                                );
                                              }}
                                            >
                                              {file.title
                                                ? file.title
                                                : file.originalname}
                                              {file.fileView.length ? (
                                                <span className="text-primary">
                                                  {" "}
                                                  <i class="fas fa-check-circle"></i>
                                                </span>
                                              ) : null}
                                            </Card.Text>

                                            <ReactStars
                                              count={5}
                                              size={20}
                                              isHalf={false}
                                              activeColor="#ffd700"
                                              edit={false}
                                              value={
                                                file.ratings
                                                  ? Math.round(file.ratings)
                                                  : 0
                                              }
                                            />
                                            {file.views ? (
                                              <div>{file.views} views</div>
                                            ) : (
                                              <div>0 views</div>
                                            )}
                                          </Card.Body>
                                          <div
                                            className="middle cursor-pointer"
                                            onClick={() => {
                                              this.getFileFromKey(
                                                file.key,
                                                file.title || file.originalname,
                                                file._id,
                                                file
                                              );
                                            }}
                                          >
                                            <div className="file-card-description px-2 pt-2">
                                              {file.description
                                                ? file.description
                                                : "No Information Available"}
                                            </div>
                                          </div>
                                        </div>
                                      </Card>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="col-md-12 text-center">
                                  <p>No files have been uploaded.</p>
                                </div>
                              )}
                            </div>
                          </div>

                          <div>
                            <Modal
                              isOpen={this.state.audioModal}
                              centered={true}
                              toggle={this.toggleAudio}
                            >
                              <ModalHeader toggle={this.toggleAudio}>
                                {this.state.filename}
                              </ModalHeader>
                              <ModalBody>
                                <div className="player-wrapper">
                                  <ReactPlayer
                                    className="react-player"
                                    url={this.state.mediaUrl}
                                    controls
                                    width="100%"
                                    height="55px"
                                    onStart={() => {
                                      startFileWatchEvent();
                                    }}
                                    onEnded={() => {
                                      this.toggleFeedbackModal();
                                      stopFileWatchEvent(
                                        this.state.selectedFile,
                                        this.state.currentUser
                                      );
                                      flagFileAsViewed(
                                        this.state.selectedFile,
                                        this.state.currentUser
                                      );
                                    }}
                                    config={this.getConfig(
                                      this.state.selectedFile?.downloadOption
                                    )}
                                  />
                                </div>
                              </ModalBody>
                            </Modal>
                          </div>
                          <div>
                            <Modal
                              isOpen={this.state.videoModal}
                              centered={true}
                              toggle={this.toggleVideo}
                              size="lg"
                            >
                              <ModalHeader toggle={this.toggleVideo}>
                                {this.state.filename}
                              </ModalHeader>
                              <ModalBody className="p-0">
                                <div className="player-wrapper">
                                  <ReactPlayer
                                    className="react-player"
                                    url={this.state.mediaUrl}
                                    controls
                                    width="100%"
                                    height="100%"
                                    onStart={() => {
                                      startFileWatchEvent();
                                    }}
                                    onEnded={() => {
                                      this.toggleFeedbackModal();
                                      stopFileWatchEvent(
                                        this.state.selectedFile,
                                        this.state.currentUser
                                      );
                                      flagFileAsViewed(
                                        this.state.selectedFile,
                                        this.state.currentUser
                                      );
                                    }}
                                    config={this.getConfig(
                                      this.state.selectedFile?.downloadOption
                                    )}
                                  />
                                </div>
                              </ModalBody>
                            </Modal>
                          </div>

                          <div>
                            <Modal
                              isOpen={this.state.feedbackModal}
                              centered="true"
                              toggle={this.toggleFeedbackModal}
                            >
                              <ModalHeader toggle={this.toggleFeedbackModal}>
                                Your Feedback
                              </ModalHeader>
                              <ModalBody>
                                <div className="text-center">
                                  <p>
                                    How likely are you to recommend Employment
                                    Ontario Services to someone looking for
                                    similar services as those you received?
                                  </p>
                                </div>
                                <div>
                                  <ReactStars
                                    classNames="mx-auto"
                                    count={5}
                                    size={50}
                                    isHalf={false}
                                    activeColor="#ffd700"
                                    emptyIcon={<i className="far fa-star" />}
                                    filledIcon={<i className="fas fa-star" />}
                                    onChange={this.handleRatingChanged}
                                  />
                                </div>
                                <div>
                                  <ol reversed="reversed">
                                    <li>Strongly recommend</li>
                                    <li>Generally recommend</li>
                                    <li>No general opinion</li>
                                    <li>Rather not recommend</li>
                                    <li>Strongly not recommend</li>
                                  </ol>
                                </div>
                                <hr />
                                <div className="form-group required">
                                  <label className="control-label">
                                    Did you find everything you needed today to
                                    assist you with your job search needs?
                                  </label>
                                  <div className="form-check form-check-inline">
                                    <input
                                      type="radio"
                                      name="foundHelpful"
                                      value="yes"
                                      id="found-helpful-yes"
                                      checked={
                                        this.state.hasFoundHelpful === "yes"
                                      }
                                      onChange={this.handleFoundHelpfulChanged}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="found-helpful-yes"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      type="radio"
                                      name="foundHelpful"
                                      value="no"
                                      id="found-helpful-no"
                                      checked={
                                        this.state.hasFoundHelpful === "no"
                                      }
                                      onChange={this.handleFoundHelpfulChanged}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="found-helpful-no"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                                <hr />
                                <label>
                                  Can you recommend other resources that may be
                                  helpful?
                                </label>
                                <textarea
                                  className="form-control"
                                  name="feedback-comment"
                                  onChange={this.handleFeedbackComment}
                                  value={this.state.feedbackComment}
                                />
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="primary"
                                  onClick={this.handleFeedbackFormSubmit}
                                >
                                  Submit
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </div>
                          <div>
                            {/* <Modal isOpen={this.state.fileViewer} size="lg" centered={true} toggle={this.togglefileViewer}>
                              <ModalHeader toggle={this.togglefileViewer}>{this.state.filename}</ModalHeader>
                              <ModalBody>
                                <FileViewer
                                  fileType={this.state.fileType}
                                  filePath={this.state.mediaUrl}
                                />
                              </ModalBody>
                              <ModalFooter>
                                <Button color="primary" onClick={this.togglefileViewer}>
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </Modal> */}
                            <CustomModal
                              open={this.state.fileViewer}
                              onCloseModal={this.togglefileViewer}
                              fileType={this.state.fileType}
                              filePath={this.state.mediaUrl}
                              fileName={this.state.filename}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetail);
