import config from "../../config";
import { authHeader, handleResponse } from "../../_helpers";
import { encData } from "../../_helpers/_helperFunctions";
export const userService = {
  getAll,
  getUserById,
  changePassword,
  create,
  update,
  deleteUser,
  register,
  forgotPassword,
  setPassword,
  verifyEmail,
  updateProfile,
  updatePrefference,
  setNewUserFalse,
  updateNocDetail,
  fetchAllJobByUsersNocDetails,
  getJobDetailByJobId,
  updateUserActiveStatus,
};

function getAll(currentUserId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData({ currentUserId: currentUserId }),
  };
  console.log("requestOptions: ", requestOptions);
  return fetch(`${config.apiUrl}/api/v1/users`, requestOptions).then(
    handleResponse
  );
}

function getUserById(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.apiUrl}/api/v1/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function changePassword(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${config.apiUrl}/api/v1/users/changepassword`,
    requestOptions
  ).then(handleResponse);
}

function create(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(`${config.apiUrl}/api/v1/users/create`, requestOptions).then(
    handleResponse
  );
}

function update(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(`${config.apiUrl}/api/v1/users/create`, requestOptions).then(
    handleResponse
  );
}

function deleteUser(userId, currentUserId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData({ userId, currentUserId }),
  };
  return fetch(`${config.apiUrl}/api/v1/users/delete`, requestOptions).then(
    handleResponse
  );
}

function register(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": `application/json`,
      isenc: localStorage.getItem("isenc")
        ? parseInt(localStorage.getItem("isenc"))
        : 0,
    },
    body: encData(data),
  };
  return fetch(`${config.apiUrl}/api/v1/users/create`, requestOptions).then(
    handleResponse
  );
}

function forgotPassword(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": `application/json`,
      isenc: localStorage.getItem("isenc")
        ? parseInt(localStorage.getItem("isenc"))
        : 0,
    },
    body: encData(data),
  };
  return fetch(
    `${config.apiUrl}/api/v1/users/forgotpassword`,
    requestOptions
  ).then(handleResponse);
}

function setPassword(data, token) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": `application/json`,
      isenc: localStorage.getItem("isenc")
        ? parseInt(localStorage.getItem("isenc"))
        : 0,
    },
    body: encData(data),
  };
  return fetch(
    `${config.apiUrl}/api/v1/users/setpassword/${token}`,
    requestOptions
  ).then(handleResponse);
}

function verifyEmail(token) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": `application/json`,
      isenc: localStorage.getItem("isenc")
        ? parseInt(localStorage.getItem("isenc"))
        : 0,
    },
  };
  return fetch(
    `${config.apiUrl}/api/v1/users/verification/${token}`,
    requestOptions
  ).then(handleResponse);
}

function updateProfile(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(`${config.apiUrl}/api/v1/users/profile`, requestOptions).then(
    handleResponse
  );
}

function updatePrefference(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${config.apiUrl}/api/v1/users/preferrence`,
    requestOptions
  ).then(handleResponse);
}

function setNewUserFalse(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${config.apiUrl}/api/v1/users/setNewUserFalse`,
    requestOptions
  ).then(handleResponse);
}

function updateNocDetail(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${config.apiUrl}/api/v1/users/updateNocDetail`,
    requestOptions
  ).then(handleResponse);
}

function fetchAllJobByUsersNocDetails(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${config.apiUrl}/api/v1/job-data/get-job-data/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getJobDetailByJobId(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${config.apiUrl}/api/v1/job-data/get-job-detail-by-job-id/${id}`,
    requestOptions
  ).then(handleResponse);
}

function updateUserActiveStatus(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${config.apiUrl}/api/v1/users/update-user-active-status`,
    requestOptions
  ).then(handleResponse);
}
