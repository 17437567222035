import EncDec from '../e2e/e2e_functions/index';
import moment from 'moment';
export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  export const isEmpty = value => {
    return (
      value == null || // From standard.js: Always use === - but obj == null is allowed to check null || undefined
      (typeof value === 'object' && Object.keys(value).length === 0) ||
      (typeof value === 'string' && value.trim().length === 0)
    )
  }


  export const encData = function(data) {
    console.log('encData::',data)
    let isenc =localStorage.getItem('isenc')?parseInt(localStorage.getItem('isenc')):0
    if (isenc === 1) {        
        return JSON.stringify({
            enc_string:EncDec.encryptResponse(
                JSON.stringify(data)
            )
        })
    } else {
        return JSON.stringify(data)
    }
}

export const getBirthYear = function(dob){
  if (typeof dob === "string" && dob.length){
    return dob.split("/")[dob.length - 1];
  }
  if(typeof dob === "object"){
    return moment(dob).format('YYYY');
  }
  return null
}

export const getFileExtension = (filename) => {
  return filename.split('.').pop();
}

export const capitalizeAndJoin = (data) => {
  let capitalizedWordsList = data.map((elem) => {
    return capitalize(elem);
  })

  return capitalizedWordsList.join(", ")
}

export const formatedTimeList = {
  "9:00": "9:00 AM",
  "10:00": "10:00 AM",
  "11:00": "11:00 AM",
  "12:00": "12:00 PM",
  "1:00": "1:00 PM",
  "2:00": "2:00 PM",
  "3:00": "3:00 PM",
  "4:00": "4:00 PM"
}