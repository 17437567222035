import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { MDBDataTable, MDBBtn } from "mdbreact";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { formService } from "../../_services/user";
import { authenticationService } from "../../_services";
import { departmentService, userService } from "../../_services/admin";
import { Form, Formik, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import DatePicker from "react-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import { setUserData, setSelectedForm } from "../../redux-store/action";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { NotificationManager } from "react-notifications";
import $ from "jquery";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import { appointmentService } from "../../_services/user/appointment.service";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";
import {
  capitalizeAndJoin,
  formatedTimeList,
} from "../../_helpers/_helperFunctions";
import { programType, MySwal } from "../../_helpers";
import swal from "sweetalert";
// import AppointmentForm from './AppointmentForm'

function mapStateToProps(state) {
  return {
    selectedForm: state.selectedForm,
    updatedAppointment: state.updatedAppointment,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
    setSelectedForm: (data) => {
      dispatch(setSelectedForm(data));
    },
  };
}

class MyAppointments extends Component {
  constructor(props) {
    super(props);
    let scripts = ["/assets/js/custom.min.js", "/assets/js/jquery.min.js"];
    let host = window.location.origin;

    // scripts.map((t) => {
    //   let script = document.createElement("script");
    //   script.src = host + t;
    //   script.async = true;
    //   document.body.appendChild(script);
    //   return true;
    // });
    let AM = "AM";
    let PM = "PM";
    this.state = {
      currentUser: authenticationService.currentUserValue,
      currentDepartment: authenticationService.currentDepartmentValue,
      observedDate: new Date(),
      start: "",
      end: "",
      detail: "",
      view: "month",
      timeOptions: [],
      staffOptions: [],
      selectedStartTime: [],
      selectedEndTime: "",
      date: "",
      bookingModal: false,
      isFormSubmitting: false,
      bookingData: [],
      loading: true,
      appointmentDetails: {},
      modal: false,
      newForm: false,
      oldForm: true,
      user: {},
      reason: "",
      checkedService: "",
      noAppointment: false,
      cancelBookingPopup: false,
      bookingId: "",
      alert: null,
    };
  }

  consoleFunc = (event) => {
    console.log(event.target.value);
    this.setState({
      checkedService: event.target.value,
    });
  };

  cancelBooking = (bookingId) => {
    let requestData = {
      bookingId: this.state.bookingId,
      currentUserId: this.state.currentUser.id,
    };
    appointmentService.cancelBooking(requestData).then((data) => {
      if (data.Status) {
        NotificationManager.success("Booking Cancelled!", null, 5000);
        this.toggleCancelBooking();
        this.getBookingsbyId();
      }
    });
  };

  onChange(date) {
    this.setState({ observedDate: date });
  }

  Event({ event }) {
    let _that = this;
    return (
      <span className="eventBox">
        <strong>{event.detail}</strong>
        <div>
          {event?.room}{" "}
          <small>
            {event.reccurance ? "Reccuring (" + event.frequency + ")" : ""}
          </small>
        </div>
        <div className="popup">
          {/* <div style={{borderBottom:'1px solid #ccc',paddingBottom:'2px'}}> */}
          {/* <i className="ti ti-trash ml-auto"  onClick={()=>{_that.removeEvent(event)}} ></i> */}
          {/* </div> */}
          <strong>{event.detail}</strong>
          <div>Room: {event?.room}</div>
          <div>Staff Contact: {event.contactStaffName || "Not Provided"}</div>
          <div>
            Start:{" "}
            {event?.start.toDateString() +
              " | " +
              (event.start
                ? this.startTimeFormatterForEvents(event.start)
                : "")}
          </div>
          <div>
            End:{" "}
            {event?.end.toDateString() +
              " | " +
              (event.end ? this.startTimeFormatterForEvents(event.end) : "")}
          </div>
        </div>
      </span>
    );
  }

  editMode(event) {
    // appointment clicked
    $(document).ready(function () {
      window.$("#basicExampleModal").modal("show");
    });
    this.setState({ ...event, editMode: true });
  }
  toggleBookingModal = () => {
    this.setState({ bookingModal: !this.state.bookingModal });
  };
  prepareEvents() {
    let events = this.props.selectedForm.refferedSchedule.map((elem) => {
      return {
        id: elem._id,
        detail: elem.detail,
        staff_contact: elem.staff_contact || "",
        room: elem.room,
        start: new Date(elem.start),
        end: new Date(elem.end),
        allDay: elem.allDay ? elem.allDay : false,
        reccurance: elem.reccurance || false,
        reccuranceEnd: elem.reccuranceEnd ? new Date(elem.reccuranceEnd) : "",
        frequency: elem.frequency || "",
        contactStaffName: elem.contactStaffName || "",
      };
    });
    let dataArray = [];
    events.forEach((elem) => {
      if (elem.reccurance == false) {
        dataArray.push(elem);
      } else {
        let start = moment(elem.start.getTime());
        let end = moment(elem.end.getTime());
        let rec_end = moment(elem.reccuranceEnd.getTime());
        while (start < rec_end) {
          let a = {
            id: elem.id,
            detail: elem.detail,
            staff_contact: elem.staff_contact || "",
            room: elem.room,
            start: start.toDate(),
            end: end.toDate(),
            allDay: elem.allDay ? elem.allDay : false,
            frequency: elem.frequency || "",
            contactStaffName: elem.contactStaffName || "",
          };
          dataArray.push(a);
          switch (elem.frequency) {
            case "Day":
              start = start.add(1, "days");
              end = end.add(1, "days");
              break;
            case "Week":
              start = start.add(7, "days");
              end = end.add(7, "days");
              break;
            case "Month":
              start = start.add(1, "months");
              end = end.add(1, "months");
              break;

            default:
              break;
          }
        }
      }
    });
    return dataArray;
  }
  componentDidMount() {
    this.getBookingsbyId();
    console.log(this.state.currentUser.id);
    let scripts = ["/assets/js/custom.min.js"];
    let host = window.location.origin;
    // scripts.map((t) => {
    //   let script = document.createElement("script");
    //   script.src = host + t;
    //   script.async = true;
    //   document.body.appendChild(script);
    //   return true;
    // });

    if (window.Websdk) {
      window.Websdk.q.push(["track_pageview", "my-appointments"]);
    }

    userService.getUserById(this.state.currentUser.id).then((data, error) => {
      this.setState({
        user: data.Data,
        loading: false,
        reason: data.Data.needAssistanceWith[0],
      });
    });
  }

  componentDidUpdate(prevProps) {
    let prevAppointment = JSON.stringify(prevProps.updatedAppointment);
    let newAppointment = JSON.stringify(this.props.updatedAppointment);
    if (prevAppointment !== newAppointment) {
      //alert("Update state now!!");
      let bookingData = this.state.bookingData;
      let rows = this.prepData(bookingData.rows, this.props.updatedAppointment);
      let newBookingData = {
        columns: bookingData.columns,
        rows: rows,
      };
      this.setState({ bookingData: newBookingData });
    }
  }

  startTimeFormatter(time) {
    let hour =
      this.state.start.getHours() < 10
        ? "0" + this.state.start.getHours()
        : this.state.start.getHours();
    let minute =
      this.state.start.getMinutes() < 10
        ? "0" + this.state.start.getMinutes()
        : this.state.start.getMinutes();
    return hour + ":" + minute;
  }
  startTimeFormatterForEvents(time) {
    if (time && time.getHours()) {
      let hour = time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
      let minute =
        time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
      return hour + ":" + minute;
    } else {
      return "00:00";
    }
  }
  isGreater(start, end) {
    let s = new Date(start);
    let sm = moment(s);
    let e = new Date(end);
    let em = moment(e);
    return sm > em;
  }

  getDetails = (user) => {
    this.setState({
      appointmentDetails: user,
    });
    this.toggle();
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  toggleCancelBooking = (bookingId) => {
    this.setState((state) => {
      return {
        cancelBookingPopup: !state.cancelBookingPopup,
        bookingId: bookingId ? bookingId : "",
      };
    });
  };
  prepData = (data, change) => {
    let rowsArray = data.map((booking, index) => {
      if (booking.bookingId !== change._id) {
        return booking;
      } else {
        return {
          key: index + 1,
          bookingId: change._id,
          bookingdate: change.date || "pending...",
          bookingtime: formatedTimeList[change.time] || "pending...",
          // bookingdate: moment(user.date).format("dddd, MMM Do YYYY"),
          preferredDay: change.preferredDay
            ? capitalizeAndJoin(change.preferredDay)
            : "-",
          // bookingtime: moment(user.time?.startTime, "HH:mm").format("hh:mm A") + " - " + moment(user.time?.endTime, "HH:mm").format("hh:mm A"),
          preferredTime: change.preferredTime
            ? capitalizeAndJoin(change.preferredTime)
            : "-",
          reason: change.reason
            .map((item) => {
              return programType[item];
            })
            .join(", "),
          bookingstatus: (
            <React.Fragment>
              {change.status == 1 && (
                <div className="btn-success status px-1">Pending</div>
              )}
              {change.status == 2 && (
                <div className="btn-primary status px-1">Approved</div>
              )}
              {change.status == 3 && (
                <div className="btn-danger status px-1">Cancelled request</div>
              )}
              {change.status == 4 && (
                <div
                  style={{
                    backgroundColor: "lightcoral",
                  }}
                  className="btn-danger status px-1"
                >
                  Cancelled
                </div>
              )}
              {change.status == 5 && (
                <div className="btn-danger status px-1">Unable to Contact</div>
              )}
            </React.Fragment>
          ),
          action: (
            <React.Fragment>
              <Link
                onClick={() => this.getDetails(change)}
                // to={"/staff/availability/" + '/' + user._id}
                title="View details"
              >
                <i className="mdi mdi-eye-outline mr-2 view"></i>
              </Link>
              {change.status === 1 ? (
                <span
                  className="mr-1 cursor-pointer"
                  onClick={() => this.toggleCancelBooking(change._id)}
                  title="Cancel Appoinment"
                >
                  <i style={{ color: "red" }} class="fas fa-times-circle"></i>
                </span>
              ) : (
                ""
              )}
            </React.Fragment>
          ),
        };
      }
    });

    return rowsArray;
  };
  getBookingsbyId = () => {
    appointmentService
      .getAllBookingsByUserId(this.state.currentUser.id)
      .then((data) => {
        console.log(data.Data);
        let rowsArray = [];
        if (data?.Data && data?.Data?.length) {
          this.setState({
            noAppointment: false,
          });
          data.Data.map((user, index) => {
            rowsArray.push({
              key: index + 1,
              bookingId: user._id,
              bookingdate: user.date || "pending...",
              bookingtime: formatedTimeList[user.time] || "pending...",
              // bookingdate: moment(user.date).format("dddd, MMM Do YYYY"),
              preferredDay: user.preferredDay
                ? capitalizeAndJoin(user.preferredDay)
                : "-",
              // bookingtime: moment(user.time?.startTime, "HH:mm").format("hh:mm A") + " - " + moment(user.time?.endTime, "HH:mm").format("hh:mm A"),
              preferredTime: user.preferredTime
                ? capitalizeAndJoin(user.preferredTime)
                : "-",
              reason: user.reason
                .map((item) => {
                  return programType[item];
                })
                .join(", "),
              bookingstatus: (
                <React.Fragment>
                  {user.status == 1 && (
                    <div className="btn-success status px-1">Pending</div>
                  )}
                  {user.status == 2 && (
                    <div className="btn-primary status px-1">Approved</div>
                  )}
                  {user.status == 3 && (
                    <div className="btn-danger status px-1">
                      Cancelled request
                    </div>
                  )}
                  {user.status == 4 && (
                    <div
                      style={{
                        backgroundColor: "lightcoral",
                      }}
                      className="btn-danger status px-1"
                    >
                      Cancelled
                    </div>
                  )}
                  {user.status == 5 && (
                    <div className="btn-danger status px-1">
                      Unable to Contact
                    </div>
                  )}
                </React.Fragment>
              ),
              action: (
                <React.Fragment>
                  <Link
                    onClick={() => this.getDetails(user)}
                    // to={"/staff/availability/" + '/' + user._id}
                    title="View details"
                  >
                    <i className="mdi mdi-eye-outline mr-2 view"></i>
                  </Link>
                  {user.status === 1 ? (
                    <span
                      className="mr-1 cursor-pointer"
                      onClick={() => this.toggleCancelBooking(user._id)}
                      title="Cancel Appoinment"
                    >
                      <i
                        style={{ color: "red" }}
                        class="fas fa-times-circle"
                      ></i>
                    </span>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ),
            });
          });
        } else {
          this.setState({
            noAppointment: true,
          });
        }
        const tableData = {
          columns: [
            // {
            //     label: "No.",
            //     field: "key",
            //     sort: "asc",
            //     width: 270,
            // },
            {
              label: "Program",
              field: "reason",
              sort: "asc",
              width: 167,
            },

            {
              label: "Preferred Day",
              field: "preferredDay",
              sort: "asc",
              width: 150,
            },
            {
              label: "Preferred Time",
              field: "preferredTime",
              sort: "asc",
              width: 200,
            },
            {
              label: "Appointment Date",
              field: "bookingdate",
              sort: "asc",
              width: 150,
            },
            {
              label: "Appointment Time",
              field: "bookingtime",
              sort: "asc",
              width: 200,
            },
            {
              label: "Status",
              field: "bookingstatus",
              sort: "disabled",
              width: 270,
            },
            {
              label: "Action",
              field: "action",
              sort: "disabled",
              width: 270,
            },
          ],
          rows: rowsArray,
        };
        this.setState({
          bookingData: tableData,
          loading: false,
        });
      });
  };

  submitForm = () => {
    if (
      document.getElementById("detail").value &&
      this.state.date &&
      this.state.selectedStartTime &&
      this.state.selectedEndTime
    ) {
      this.setState({
        isFormSubmitting: true,
      });
      let requestData = {
        date: moment(this.state.date).format("MM/DD/yyyy"),
        day: moment(this.state.date).format("dddd"),
        time: {
          startTime: this.state.selectedStartTime.split(" - ")[0],
          endTime: this.state.selectedEndTime,
        },
        currentUserId: this.state.currentUser.id,
        name:
          this.state.currentUser.firstName +
          " " +
          this.state.currentUser.lastName,
        reason: document.getElementById("detail").value,
      };
      appointmentService.bookAppointment(requestData).then((data) => {
        this.setState({
          isFormSubmitting: false,
          selectedStartTime: [],
          selectedEndTime: "",
          date: "",
          detail: "",
          reason: "",
        });
        document.getElementById("close-modal").click();
        this.getBookingsbyId();
      });
    }
  };

  clearData = () => {
    this.setState({
      selectedStartTime: [],
      selectedEndTime: "",
      date: "",
      detail: "",
      reason: "",
    });
  };

  render() {
    const {
      list,
      getFormDataById,
      openFormInEditMode,
      selectedForm,
    } = this.props;
    let allowForUsers = this.state.currentUser.id;
    let allowForDepartments = this.state.currentDepartment;
    const localizer = momentLocalizer(moment);
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border ">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>

                  <li className="breadcrumb-item active">My Appointment</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card-body myAppointments">
                          <div className="d-flex justify-content-between">
                            <div className="align-self-center tbl-head">
                              <i className="mdi mdi-calendar-check"></i>{" "}
                              <span>My Appointment</span>
                            </div>
                            <div>
                              <button
                                onClick={this.toggleBookingModal}
                                className="btn btn-primary creatNewBtn"
                              >
                                <i className="mdi mdi-plus"></i>
                                <span className="hide-menu">Create New</span>
                              </button>
                            </div>
                          </div>
                          <hr />
                          {!this.state.noAppointment ? (
                            <MDBDataTable
                              responsive
                              id="appointments"
                              striped
                              bordered
                              noBottomColumns
                              hover
                              data={this.state.bookingData}
                            />
                          ) : (
                            <div className="text-center">
                              <h3>You don't have any appointment</h3>
                            </div>
                          )}

                          <div>
                            <Modal
                              isOpen={this.state.bookingModal}
                              centered="true"
                              toggle={this.toggleBookingModal}
                              size="lg"
                            >
                              <ModalHeader toggle={this.toggleBookingModal}>
                                Book Appointment
                              </ModalHeader>
                              <ModalBody>
                                <Formik
                                  enableReinitialize={true}
                                  initialValues={{
                                    reason: [],
                                    preferredDay: [],
                                    preferredTime: [],
                                    preferredMethodOfContact: [],
                                    // userId: this.state.currentUser.id || "",
                                    name:
                                      this.state.currentUser.firstName +
                                      " " +
                                      this.state.currentUser.lastName,
                                    currentUserId:
                                      this.state.currentUser.id || "",
                                    email: this.state.currentUser.email,
                                    phoneNumber: this.state.currentUser
                                      .phoneNumber,
                                  }}
                                  onSubmit={(
                                    formData,
                                    { setStatus, setSubmitting, resetForm }
                                  ) => {
                                    setStatus();
                                    console.log(formData);
                                    appointmentService
                                      .bookAppointment(formData)
                                      .then(
                                        (user) => {
                                          //const { from } = this.props.location.state || { from: { pathname: "/login" } };
                                          //this.props.history.push(from);
                                          swal(
                                            "Appointment Booked!",
                                            "",
                                            "success"
                                          );
                                          // NotificationManager.success('Appointment Booked Successfully');
                                          setSubmitting(false);
                                          this.toggleBookingModal();
                                          resetForm();
                                          this.getBookingsbyId();
                                        },
                                        (error) => {
                                          setSubmitting(false);
                                          setStatus(error);
                                          resetForm();
                                        }
                                      );
                                  }}
                                  render={({
                                    errors,
                                    status,
                                    touched,
                                    isSubmitting,
                                    setFieldValue,
                                    values,
                                    handleChange,
                                  }) => (
                                    <Form>
                                      <div
                                        className="form-horizontal form-material"
                                        id="loginform"
                                      >
                                        <div className="form-group">
                                          <label className="control-label">
                                            I need assistance with:
                                          </label>
                                          <div class="form-check">
                                            <Field
                                              name="reason"
                                              type="checkbox"
                                              onClick={this.consoleFunc}
                                              value="Employment-Services"
                                              id="employment-services"
                                              className="form-check-input"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="employment-services"
                                            >
                                              Employment Services (one-on-one
                                              career counseling, job placement,
                                              job matching, etc.)
                                            </label>
                                          </div>
                                          <div class="form-check">
                                            <Field
                                              name="reason"
                                              type="checkbox"
                                              onClick={this.consoleFunc}
                                              value="newcomer-services"
                                              id="newcomer-services"
                                              className="form-check-input"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="newcomer-services"
                                            >
                                              Newcomer Services (English
                                              Classes. Computer Training,
                                              Workplace Essentials, Connecting
                                              Newcomer Youth, etc.)
                                            </label>
                                          </div>
                                          <div class="form-check">
                                            <Field
                                              name="reason"
                                              type="checkbox"
                                              onClick={this.consoleFunc}
                                              value="Youth-Programs"
                                              id="youth-programs"
                                              className="form-check-input"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="youth-programs"
                                            >
                                              Youth Programs (paid
                                              pre-employment training and paid
                                              job placement ages 15 – 29)
                                            </label>
                                          </div>
                                          <div class="form-check">
                                            <Field
                                              name="reason"
                                              type="checkbox"
                                              onClick={this.consoleFunc}
                                              value="energy-assistance"
                                              id="energy-assistance"
                                              className="form-check-input"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="energy-assistance"
                                            >
                                              Energy Assistance (trouble paying
                                              your utility bills)
                                            </label>
                                          </div>
                                          <div class="form-check">
                                            <Field
                                              name="reason"
                                              type="checkbox"
                                              onClick={this.consoleFunc}
                                              value="food-assistance"
                                              id="food-assistance"
                                              className="form-check-input"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="food-assistance"
                                            >
                                              Food Assistance (provide you with
                                              a nutritional food hamper)
                                            </label>
                                          </div>
                                          <div class="form-check">
                                            <Field
                                              name="reason"
                                              type="checkbox"
                                              onClick={this.consoleFunc}
                                              value="literacy-basic-skills"
                                              id="literacy-basic-skills"
                                              className="form-check-input"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="literacy-basic-skills"
                                            >
                                              Literacy and Basic Skills (improve
                                              your communication, computer,
                                              math, and management skills)
                                            </label>
                                          </div>
                                          <div class="form-check">
                                            <Field
                                              name="reason"
                                              type="checkbox"
                                              onClick={this.consoleFunc}
                                              value="ged"
                                              id="ged"
                                              className="form-check-input"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="ged"
                                            >
                                              GED (prepares you to write your
                                              GED® test)
                                            </label>
                                          </div>
                                          <div class="form-check">
                                            <Field
                                              name="reason"
                                              type="checkbox"
                                              onClick={this.consoleFunc}
                                              value="pre-apprenticeship"
                                              id="pre-apprenticeship"
                                              className="form-check-input"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="pre-apprenticeship"
                                            >
                                              Cooks Pre-Apprenticeship Program
                                              (prepare to become an apprentice
                                              cook with our Red Seal Chefs in
                                              our state-of-the-art Community
                                              Kitchen)
                                            </label>
                                          </div>
                                          <div class="form-check">
                                            <Field
                                              name="reason"
                                              type="checkbox"
                                              onClick={this.consoleFunc}
                                              value="employment-workshops"
                                              id="employment-workshops"
                                              className="form-check-input"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="employment-workshops"
                                            >
                                              Employment Workshops (Resume
                                              Writing, Interview Techniques,
                                              etc.)
                                            </label>
                                          </div>
                                          <div class="form-check">
                                            <Field
                                              name="reason"
                                              type="checkbox"
                                              onClick={this.consoleFunc}
                                              value="workshops"
                                              id="workshops"
                                              className="form-check-input"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="workshops"
                                            >
                                              Workshops (Word, Excel, Business
                                              Writing)
                                            </label>
                                          </div>
                                          <div class="form-check">
                                            <Field
                                              name="reason"
                                              type="checkbox"
                                              onClick={this.consoleFunc}
                                              value="cooking-classes"
                                              id="cooking-classes"
                                              className="form-check-input"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="cooking-classes"
                                            >
                                              Cooking Classes (cook along with
                                              our Red Seal Chef while learning
                                              about nutrition and food
                                              preparation in our Community
                                              Kitchen)
                                            </label>
                                          </div>
                                          <div class="form-check">
                                            <Field
                                              name="reason"
                                              type="checkbox"
                                              onClick={this.consoleFunc}
                                              value="resource-information-centre"
                                              id="resource-information-centre"
                                              className="form-check-input"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="resource-information-centre"
                                            >
                                              Resource &amp; Information Centre
                                              (access to computers, WI-FI,
                                              printer, fax, video conferencing,
                                              labour market information, etc.)
                                            </label>
                                          </div>
                                          <div class="form-check">
                                            <Field
                                              name="reason"
                                              type="checkbox"
                                              onClick={this.consoleFunc}
                                              value="second-career"
                                              id="second-career"
                                              className="form-check-input"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="second-career"
                                            >
                                              Second Career (a program designed
                                              to get you on the right track to a
                                              successful career)
                                            </label>
                                          </div>
                                          <div class="form-check">
                                            <Field
                                              name="reason"
                                              type="checkbox"
                                              onClick={this.consoleFunc}
                                              value="other"
                                              id="other"
                                              className="form-check-input"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="other"
                                            >
                                              Other
                                            </label>
                                          </div>
                                        </div>

                                        {true && (
                                          <React.Fragment>
                                            <div className="form-group">
                                              <label className="control-label">
                                                Preferred Day:
                                              </label>
                                              <div class="form-check">
                                                <Field
                                                  name="preferredDay"
                                                  type="checkbox"
                                                  value="monday"
                                                  id="monday"
                                                  className="form-check-input"
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="monday"
                                                >
                                                  Monday
                                                </label>
                                              </div>
                                              <div class="form-check">
                                                <Field
                                                  name="preferredDay"
                                                  type="checkbox"
                                                  value="tuesday"
                                                  id="tuesday"
                                                  className="form-check-input"
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="tuesday"
                                                >
                                                  Tuesday
                                                </label>
                                              </div>
                                              <div class="form-check">
                                                <Field
                                                  name="preferredDay"
                                                  type="checkbox"
                                                  value="wednesday"
                                                  id="wednesday"
                                                  className="form-check-input"
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="wednesday"
                                                >
                                                  Wednesday
                                                </label>
                                              </div>
                                              <div class="form-check">
                                                <Field
                                                  name="preferredDay"
                                                  type="checkbox"
                                                  value="thursday"
                                                  id="thursday"
                                                  className="form-check-input"
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="thursday"
                                                >
                                                  Thursday
                                                </label>
                                              </div>
                                              <div class="form-check">
                                                <Field
                                                  name="preferredDay"
                                                  type="checkbox"
                                                  value="friday"
                                                  id="friday"
                                                  className="form-check-input"
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="friday"
                                                >
                                                  Friday
                                                </label>
                                              </div>
                                            </div>

                                            <div className="form-group">
                                              <label className="control-label">
                                                Preferred Time:
                                              </label>
                                              <div class="form-check">
                                                <Field
                                                  name="preferredTime"
                                                  type="checkbox"
                                                  value="morning-9-11"
                                                  id="morning-9-11"
                                                  className="form-check-input"
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="morning-9-11"
                                                >
                                                  Morning (9-11)
                                                </label>
                                              </div>
                                              <div class="form-check">
                                                <Field
                                                  name="preferredTime"
                                                  type="checkbox"
                                                  value="afternoon-1-4"
                                                  id="afternoon-1-4"
                                                  className="form-check-input"
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="afternoon-1-4"
                                                >
                                                  Afternoon (1-4)
                                                </label>
                                              </div>
                                              <div class="form-check">
                                                <Field
                                                  name="preferredTime"
                                                  type="checkbox"
                                                  value="my-time-falls-outside-these-hours"
                                                  id="my-time-falls-outside-these-hours"
                                                  className="form-check-input"
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="my-time-falls-outside-these-hours"
                                                >
                                                  My time falls outside of these
                                                  hours
                                                </label>
                                              </div>
                                            </div>

                                            <div className="form-group">
                                              <label className="control-label">
                                                Preferred method of contact:
                                              </label>
                                              <div class="form-check">
                                                <Field
                                                  name="preferredMethodOfContact"
                                                  type="checkbox"
                                                  value="phone"
                                                  id="phone"
                                                  className="form-check-input"
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="phone"
                                                >
                                                  Phone
                                                </label>
                                              </div>
                                              <div class="form-check">
                                                <Field
                                                  name="preferredMethodOfContact"
                                                  type="checkbox"
                                                  value="text"
                                                  id="text"
                                                  className="form-check-input"
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="text"
                                                >
                                                  Text
                                                </label>
                                              </div>
                                              <div class="form-check">
                                                <Field
                                                  name="preferredMethodOfContact"
                                                  type="checkbox"
                                                  value="email"
                                                  id="email"
                                                  className="form-check-input"
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="email"
                                                >
                                                  Email
                                                </label>
                                              </div>
                                            </div>
                                          </React.Fragment>
                                        )}
                                        <div className="form-group text-right mb-0 m-t-20">
                                          <div className="col-xs-12">
                                            <button
                                              type="submit"
                                              disabled={isSubmitting}
                                              className="btn btn-primary text-uppercase waves-effect waves-light"
                                            >
                                              Book Appointment
                                            </button>
                                            {isSubmitting && (
                                              <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </Form>
                                  )}
                                />
                              </ModalBody>
                            </Modal>
                          </div>
                          <div>
                            <Modal
                              isOpen={this.state.modal}
                              centered="true"
                              toggle={this.toggle}
                            >
                              <ModalHeader toggle={this.toggle}>
                                Details of Appointment
                              </ModalHeader>
                              <ModalBody>
                                {/* {this.state.appointmentDetails.status == 3 ? */}
                                <div>
                                  {this.state.appointmentDetails.notes && (
                                    <div className="full_width myappointment_modal">
                                      <div>Counsellor's Note</div>
                                      <div
                                        id="notes"
                                        dangerouslySetInnerHTML={{
                                          __html: this.state.appointmentDetails
                                            .notes,
                                        }}
                                      ></div>
                                    </div>
                                  )}

                                  {this.state.appointmentDetails.status ===
                                    5 && (
                                    <div className="full_width myappointment_modal">
                                      <div>
                                        Unfortunately we are unable to reach you
                                        please contact us via phone{" "}
                                        <span>
                                          <i class="fas fa-phone"></i>{" "}
                                        </span>
                                        (519) 944-4900 or email{" "}
                                        <span>
                                          <i class="fas fa-envelope-square"></i>{" "}
                                        </span>
                                        (uhc@uhc.ca)
                                      </div>
                                    </div>
                                  )}
                                  {this.state.appointmentDetails.status ===
                                    3 && (
                                    <div className="full_width myappointment_modal">
                                      <div>
                                        Your request for an appointment has been
                                        canceled, if this is done with an error
                                        please contact us immediately via phone{" "}
                                        <span>
                                          <i class="fas fa-phone"></i>{" "}
                                        </span>
                                        (519) 944-4900 or email{" "}
                                        <span>
                                          <i class="fas fa-envelope-square"></i>{" "}
                                        </span>
                                        (uhc@uhc.ca)
                                      </div>
                                    </div>
                                  )}

                                  {this.state.appointmentDetails.status ===
                                    1 && (
                                    <div className="full_width myappointment_modal">
                                      <div className="row">
                                        <div className="col-lg-6 col-sm-6">
                                          {" "}
                                          <span>
                                            Name :{" "}
                                            {`${this?.state?.appointmentDetails?.name}`}
                                          </span>
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                          {" "}
                                          <span>
                                            Email :{" "}
                                            {`${this?.state?.appointmentDetails?.email}`}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="row pt-3">
                                        <div className="col-lg-6 col-sm-6">
                                          {" "}
                                          <span>
                                            Phone Number :{" "}
                                            {`${this?.state?.appointmentDetails?.phoneNumber}`}
                                          </span>
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                          {" "}
                                        </div>
                                      </div>
                                      {this?.state?.appointmentDetails
                                        ?.preferredDay.length > 0 && (
                                        <div className="row pt-3">
                                          <div className="col-lg-12 col-sm-12">
                                            {" "}
                                            <span>Preferred Day : </span>
                                            <span>
                                              {this?.state?.appointmentDetails?.preferredDay.map(
                                                (days) => {
                                                  return (
                                                    <Badge
                                                      bg="primary"
                                                      className="ml-2"
                                                    >
                                                      {days.toUpperCase()}
                                                    </Badge>
                                                  );
                                                }
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                      {this?.state?.appointmentDetails
                                        ?.preferredTime.length > 0 && (
                                        <div className="row pt-3">
                                          <div className="col-lg-12 col-sm-12">
                                            {" "}
                                            <span>Preferred Time </span>
                                            <span>
                                              {this?.state?.appointmentDetails?.preferredTime.map(
                                                (days) => {
                                                  return (
                                                    <Badge
                                                      bg="secondary"
                                                      className="ml-2"
                                                    >
                                                      {days.toUpperCase()}
                                                    </Badge>
                                                  );
                                                }
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                      {this?.state?.appointmentDetails
                                        ?.preferredMethodOfContact.length >
                                        0 && (
                                        <div className="row pt-3">
                                          <div className="col-lg-12 col-sm-12">
                                            {" "}
                                            <span>
                                              Preferred Method of Contact{" "}
                                            </span>
                                            <span>
                                              {this?.state?.appointmentDetails?.preferredMethodOfContact.map(
                                                (method) => {
                                                  return (
                                                    <Badge
                                                      bg="secondary"
                                                      className="ml-2"
                                                    >
                                                      {method.toUpperCase()}
                                                    </Badge>
                                                  );
                                                }
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                      {this?.state?.appointmentDetails?.reason
                                        .length > 0 && (
                                        <div className="row pt-3">
                                          <div className="col-lg-12 col-sm-12">
                                            {" "}
                                            <span>
                                              Preferred Method of Contact{" "}
                                            </span>
                                            <span>
                                              {this?.state?.appointmentDetails?.reason.map(
                                                (reason) => {
                                                  return (
                                                    <Badge
                                                      bg="secondary"
                                                      className="ml-2"
                                                    >
                                                      {reason.toUpperCase()}
                                                    </Badge>
                                                  );
                                                }
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  {this.state.appointmentDetails.status ===
                                    2 && (
                                    <div className="full_width myappointment_modal">
                                      <div className="row pt-3">
                                        <div className="col-lg-6 col-sm-6">
                                          {" "}
                                          <span>
                                            Name :{" "}
                                            {`${this?.state?.appointmentDetails?.name}`}
                                          </span>
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                          {" "}
                                          <span>
                                            Email :{" "}
                                            {`${this?.state?.appointmentDetails?.email}`}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="row pt-3">
                                        <div className="col-lg-6 col-sm-6">
                                          {" "}
                                          <span>
                                            Appointment Type :{" "}
                                            {`${this?.state?.appointmentDetails?.appointmentType}`}
                                          </span>
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                          {" "}
                                          <span>
                                            Booking Status :{" "}
                                            {`${this?.state?.appointmentDetails?.bookingStatus}`}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="row pt-3">
                                        <div className="col-lg-6 col-sm-6">
                                          {" "}
                                          <span>
                                            codeStatus :{" "}
                                            {`${this?.state?.appointmentDetails?.codeStatus}`}
                                          </span>
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                          {" "}
                                          <span>
                                            Date and Time :{" "}
                                            {`${this?.state?.appointmentDetails?.date} ${this?.state?.appointmentDetails?.time}`}
                                          </span>
                                        </div>
                                      </div>

                                      {this?.state?.appointmentDetails
                                        ?.preferredDay.length > 0 && (
                                        <div className="row pt-3">
                                          <div className="col-lg-12 col-sm-12">
                                            {" "}
                                            <span>Preferred Day : </span>
                                            <span>
                                              {this?.state?.appointmentDetails?.preferredDay.map(
                                                (days) => {
                                                  return (
                                                    <Badge
                                                      bg="primary"
                                                      className="ml-2"
                                                    >
                                                      {days.toUpperCase()}
                                                    </Badge>
                                                  );
                                                }
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                      {this?.state?.appointmentDetails
                                        ?.preferredTime.length > 0 && (
                                        <div className="row pt-3">
                                          <div className="col-lg-12 col-sm-12">
                                            {" "}
                                            <span>Preferred Time </span>
                                            <span>
                                              {this?.state?.appointmentDetails?.preferredTime.map(
                                                (days) => {
                                                  return (
                                                    <Badge
                                                      bg="secondary"
                                                      className="ml-2"
                                                    >
                                                      {days.toUpperCase()}
                                                    </Badge>
                                                  );
                                                }
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                      {this?.state?.appointmentDetails
                                        ?.preferredMethodOfContact.length >
                                        0 && (
                                        <div className="row pt-3">
                                          <div className="col-lg-12 col-sm-12">
                                            {" "}
                                            <span>
                                              Preferred Method of Contact{" "}
                                            </span>
                                            <span>
                                              {this?.state?.appointmentDetails?.preferredMethodOfContact.map(
                                                (method) => {
                                                  return (
                                                    <Badge
                                                      bg="secondary"
                                                      className="ml-2"
                                                    >
                                                      {method.toUpperCase()}
                                                    </Badge>
                                                  );
                                                }
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                      {this?.state?.appointmentDetails?.reason
                                        .length > 0 && (
                                        <div className="row pt-3">
                                          <div className="col-lg-12 col-sm-12">
                                            {" "}
                                            <span>
                                              Preferred Method of Contact{" "}
                                            </span>
                                            <span>
                                              {this?.state?.appointmentDetails?.reason.map(
                                                (reason) => {
                                                  return (
                                                    <Badge
                                                      bg="secondary"
                                                      className="ml-2"
                                                    >
                                                      {reason.toUpperCase()}
                                                    </Badge>
                                                  );
                                                }
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </ModalBody>
                            </Modal>
                          </div>
                          <div>
                            <Modal
                              isOpen={this.state.cancelBookingPopup}
                              centered="true"
                              toggle={this.toggleCancelBooking}
                            >
                              <ModalHeader toggle={this.toggleCancelBooking}>
                                User Details
                              </ModalHeader>
                              <ModalBody>
                                <h4>
                                  Are you sure you want to cancel the your
                                  appointment booking?
                                </h4>
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  className="disableColor"
                                  onClick={this.toggleCancelBooking}
                                  color="secondary"
                                >
                                  No
                                </Button>
                                <Button
                                  className="disableColor"
                                  onClick={this.cancelBooking}
                                  color="primary"
                                >
                                  Yes
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAppointments);
