import React, { Component } from "react";
import { connect } from "react-redux";
import { history, Role } from "../_helpers";
import { authenticationService } from "../_services";
import { Link } from "react-router-dom";
import { formService } from "../_services/user";
import { setDepartmentsData } from "../redux-store/action";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setDepartments: (data) => {
      dispatch(setDepartmentsData(data));
    },
  };
}

const searchFields = [
  { name: "First Name", value: "firstName" },
  { name: "Last Name", value: "lastName" },
  // {name:"PR Number",value:"prCard"},
  // {name:"Date of birth",value:"dateOfBirth"},
  { name: "Phone", value: "phone" },
  { name: "Date Of Birth", value: "dateOfBirth" },
  { name: "Postal code", value: "postalCode" },
];
class HeaderAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      currentUserSubject: authenticationService.currentUser,
      currentDepartment: authenticationService.currentDepartmentValue,
      departments: [],
      currentDepartmentName: "No Department",
      searchFields: searchFields,
      searchResult: [],
    };
  }
  logout() {
    authenticationService.logout();
    history.push("/");
  }
  setCurrentDepartment(departmentId, currentDepartmentName) {
    if (departmentId == this.state.currentDepartment) return false;
    authenticationService.changeDepartment(departmentId);
    this.setState({ currentDepartmentName });
  }
  componentDidMount() {
    //this.getAllDepartments();
    this.state.currentUserSubject.subscribe({
      next: (v) => {
        this.setState({ currentUser: v });
      },
    });
  }

  getAllDepartments() {
    // alert('getting all depts')
    formService.getAllDepartments().then((data) => {
      let departments = [];
      let currentDepartmentName = "";
      data &&
        this.state.currentUser &&
        data.Data &&
        data.Data.map((item, i) => {
          if (this.state.currentUser.departments.indexOf(item._id) !== -1) {
            departments.push(item);
          }
          if (item._id == this.state.currentDepartment)
            currentDepartmentName = item.name;
        });
      this.setState({
        departments: departments,
        currentDepartmentName,
      });
      this.props.setDepartments(data.Data);
    });
  }
  openForm() {
    this.setState({ searchResult: [], searchTextTemp: "" });
  }

  toggleSidebar = (event) => {
    event.preventDefault();
    return window.jQuery("body").hasClass("mini-sidebar")
      ? (window.jQuery("body").trigger("resize"),
        window
          .jQuery(".scroll-sidebar, .slimScrollDiv")
          .css({ overflow: "hidden" })
          .parent()
          .css("overflow", "visible"),
        window.jQuery("body").removeClass("mini-sidebar"),
        window.jQuery(".navbar-brand span").show())
      : (window.jQuery("body").trigger("resize"),
        window
          .jQuery(".scroll-sidebar, .slimScrollDiv")
          .css("overflow-x", "visible")
          .parent()
          .css("overflow", "visible"),
        window.jQuery("body").addClass("mini-sidebar"),
        window.jQuery(".navbar-brand span").hide());
  };

  render() {
    const { currentUser, currentDepartment } = this.state;
    return (
      <header className="topbar">
        <nav className="navbar top-navbar navbar-expand-md navbar-light">
          <div className="navbar-header header-color">
            <Link className="navbar-brand" to="/dashboard">
              <b>
                <img
                  src={
                    window.location.origin + "/assets/images/mo-uhc-logo.png"
                  }
                  alt="logo"
                  className="dark-logo"
                />
              </b>
              <span>
                <img
                  src={window.location.origin + "/assets/images/UHC_logo.png"}
                  alt="Logo text"
                  className="light-logo"
                />
              </span>
            </Link>
          </div>

          <div className="navbar-collapse">
            <ul className="navbar-nav mr-auto mt-md-0">
              <li className="nav-item">
                {" "}
                <a
                  className="nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark"
                  href="#"
                  onClick={this.toggleSidebar}
                >
                  <i className="mdi mdi-menu"></i>
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a
                  className="nav-link hidden-sm-down text-muted waves-effect waves-dark"
                  href="#"
                  onClick={this.toggleSidebar}
                >
                  <i className="ti-menu"></i>
                </a>{" "}
              </li>
              {currentUser?.role == Role.User && (
                <>
                  <li className="nav-item searching_li">
                    <Formik
                      initialValues={{
                        searchText: "",
                        fieldName: "firstName",
                      }}
                      validationSchema={Yup.object().shape({
                        searchText: Yup.string().required(
                          "Please enter a text"
                        ),
                      })}
                      onSubmit={(formData, { setStatus, setSubmitting }) => {
                        setStatus();
                        let data = {
                          search_field: formData.fieldName,
                          search_value: formData.searchText,
                          allowForDepartments: currentDepartment,
                        };
                        formService.globleSearchForm(data).then(
                          (data) => {
                            data &&
                              data.Data?.length == 0 &&
                              NotificationManager.warning(
                                "No Record Found",
                                "",
                                5000
                              );
                            this.setState({ searchResult: data.Data || [] });
                          },
                          (error) => {
                            setSubmitting(false);
                            setStatus(error);
                            NotificationManager.error(error, "", 5000);
                          }
                        );
                      }}
                      render={({
                        values,
                        errors,
                        status,
                        touched,
                        isSubmitting,
                        setFieldValue,
                      }) => (
                        <Form>
                          {/* <div className="searchbars">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text p-0">
                                                <Field name="fieldName" as="select" className="form-control seroption" onChange={(data)=>{
                                                    setFieldValue('searchText','')
                                                    this.setState({searchResult:[]})
                                                    setFieldValue('fieldName',data.target.value)
                                                }}>
                                                    {searchFields&&searchFields.map((item,i)=>(
                                                        <option key={i} value={item.value}>{item.name}</option>
                                                    ))}
                                                </Field>
                                            </span>
                                        </div>
                                        <div className="autoselect">
                                        {
                                            values.fieldName == 'dateOfBirth'
                                            ?
                                            <Field name={'searchText'} className="form-control relative-date" render={({ field }) => (
                                                <DatePicker
                                                    {...field}
                                                    style={{float:'unset'}}
                                                    format={'MM/dd/yyyy'}
                                                    onChange={(date) => date === null ? setFieldValue('searchText', "") : setFieldValue('searchText', date.getTime())}
                                                    value={values?.searchText?new Date(values?.searchText  || '') : new Date()}
                                                    className={"form-control relative-date"}
                                                />
                                            )}
                                            />
                                            :
                                            <Field  name="searchText" type="text" className="form-control" placeholder="Search Here" />
                                        }
                                        {values.searchText&&searchResult&&searchResult.length>0&&<div className="search_result">
                                        <ul className="list-group">
                                            {searchResult.map((item,i)=>(
                                                <li className="list-group-item" onClick={()=>{this.openForm.bind(this);setFieldValue('searchText','')}} key={i}>
                                                    <Link to={"/settlement-services/view/" + item._id}>
                                                        <div className="searchnames"> 
                                                            <strong> {`${item.firstName} ${item.lastName}`} </strong>
                                                            <span> <small>D.O.B</small> <b> {moment.utc(parseInt(item.dateOfBirth)).format("MM/DD/YYYY")} </b> </span> 
                                                            <span> <i className="mdi mdi-cellphone"></i> {`${item.phone}`} </span>
                                                        </div>
                                            <div className="createdby"> <span><b>Created by:</b> {`${item.createdByFirstName} ${item.createdByLastName}`}</span> <span className="c_time"><i className="mdi mdi-clock"></i> {moment(item.createdAt).fromNow()} </span> </div>
                                                        
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                        </div>}             
                                        </div>
                                        <div className="input-group-append">
                                            <button className="btn btn-warning" type="submit"><i className="ti-search"></i></button>
                                        </div>
                                        
                                    </div>
                                    <ErrorMessage name="searchText" component="div" className="invalid-feedback" />
                                </div> */}
                        </Form>
                      )}
                    />
                  </li>{" "}
                </>
              )}
            </ul>
            <ul className="navbar-nav my-lg-0">
              {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle text-muted text-muted waves-effect waves-dark" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="mdi mdi-message"></i>
                                    <div className="notify"> <span className="heartbit"></span> <span className="point"></span> </div>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right mailbox scale-up">
                                    <ul>
                                        <li>
                                            <div className="drop-title">Notifications</div>
                                        </li>
                                        <li>
                                            <div className="message-center">

                                                <a href="#">
                                                    <div className="btn btn-danger btn-circle"><i className="fa fa-link"></i></div>
                                                    <div className="mail-contnet">
                                                        <h5>Luanch Admin</h5> <span className="mail-desc">Just see the my new admin!</span> <span className="time">9:30 AM</span> </div>
                                                </a>

                                                <a href="#">
                                                    <div className="btn btn-success btn-circle"><i className="ti-calendar"></i></div>
                                                    <div className="mail-contnet">
                                                        <h5>Event today</h5> <span className="mail-desc">Just a reminder that you have event</span> <span className="time">9:10 AM</span> </div>
                                                </a>

                                                <a href="#">
                                                    <div className="btn btn-info btn-circle"><i className="ti-settings"></i></div>
                                                    <div className="mail-contnet">
                                                        <h5>Settings</h5> <span className="mail-desc">You can customize this template as you want</span> <span className="time">9:08 AM</span> </div>
                                                </a>

                                                <a href="#">
                                                    <div className="btn btn-primary btn-circle"><i className="ti-user"></i></div>
                                                    <div className="mail-contnet">
                                                        <h5>Pavan kumar</h5> <span className="mail-desc">Just see the my admin!</span> <span className="time">9:02 AM</span> </div>
                                                </a>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="nav-link text-center" href="#"> <strong>Check all notifications</strong> <i className="fa fa-angle-right"></i> </a>
                                        </li>
                                    </ul>
                                </div>
                            </li> */}

              {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle text-muted waves-effect waves-dark" href="#" id="2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="mdi mdi-email"></i>
                                    <div className="notify"> <span className="heartbit"></span> <span className="point"></span> </div>
                                </a>
                                <div className="dropdown-menu mailbox dropdown-menu-right scale-up" aria-labelledby="2">
                                    <ul>
                                        <li>
                                            <div className="drop-title">You have 4 new messages</div>
                                        </li>
                                        <li>
                                            <div className="message-center">

                                                <a href="#">
                                                    <div className="user-img"> <img src={window.location.origin + '/assets/images/users/1.jpg'} alt="user" className="img-circle" /> <span className="profile-status online pull-right"></span> </div>
                                                    <div className="mail-contnet">
                                                        <h5>Pavan kumar</h5> <span className="mail-desc">Just see the my admin!</span> <span className="time">9:30 AM</span> </div>
                                                </a>

                                                <a href="#">
                                                    <div className="user-img"> <img src={window.location.origin + '/assets/images/users/2.jpg'} alt="user" className="img-circle" /> <span className="profile-status busy pull-right"></span> </div>
                                                    <div className="mail-contnet">
                                                        <h5>Sonu Nigam</h5> <span className="mail-desc">I've sung a song! See you at</span> <span className="time">9:10 AM</span> </div>
                                                </a>

                                                <a href="#">
                                                    <div className="user-img"> <img src={window.location.origin + '/assets/images/users/3.jpg'} alt="user" className="img-circle" /> <span className="profile-status away pull-right"></span> </div>
                                                    <div className="mail-contnet">
                                                        <h5>Arijit Sinh</h5> <span className="mail-desc">I am a singer!</span> <span className="time">9:08 AM</span> </div>
                                                </a>

                                                <a href="#">
                                                    <div className="user-img"> <img src="assets/images/users/4.jpg" alt="user" className="img-circle" /> <span className="profile-status offline pull-right"></span> </div>
                                                    <div className="mail-contnet">
                                                        <h5>Pavan kumar</h5> <span className="mail-desc">Just see the my admin!</span> <span className="time">9:02 AM</span> </div>
                                                </a>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="nav-link text-center" href="#"> <strong>See all e-Mails</strong> <i className="fa fa-angle-right"></i> </a>
                                        </li>
                                    </ul>
                                </div>
                            </li> */}

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle text-muted waves-effect waves-dark"
                  href="#"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="mr-2">{`Welcome, ${currentUser.firstName}`}</span>
                  <img
                    src={currentUser.profileImageUrl}
                    alt="user"
                    className="profile-pic"
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-right scale-up">
                  <ul className="dropdown-user">
                    <li>
                      <div className="dw-user-box text-center">
                        <div className="u-img">
                          <img src={currentUser.profileImageUrl} alt="user" />
                        </div>
                        <div className="u-text mt-2">
                          <h4>{`${currentUser.firstName} ${currentUser.lastName}`}</h4>
                          <p className="text-muted">{currentUser.email}</p>
                        </div>
                      </div>
                    </li>
                    <li role="separator" className="divider"></li>
                    <li>
                      <Link
                        to="/my-profile"
                        className="waves-effect waves-dark"
                      >
                        <i className="ti-user mr-1"></i> My Profile
                      </Link>
                    </li>
                    <li role="separator" className="divider"></li>
                    <li>
                      <Link
                        to="/change-password"
                        className="waves-effect waves-dark"
                      >
                        <i className="ti-lock mr-1"></i>Change Password
                      </Link>
                    </li>

                    {/*
                                        <li><a href="#"><i className="ti-email"></i> Inbox</a></li>
                                        <li role="separator" className="divider"></li>
                                        <li><a href="#"><i className="ti-settings"></i> Account Setting</a></li> */}
                    <li role="separator" className="divider"></li>
                    <li>
                      <a onClick={this.logout}>
                        <i className="fa fa-power-off"></i> Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderAdmin);
