import { authenticationService } from '../authentication.service';
import { Storage } from 'aws-amplify';
import { AWS } from '@aws-amplify/core';
import config from '../../config';
import { authHeader, handleResponse } from '../../_helpers';
import { encData } from '../../_helpers/_helperFunctions';


export const appointmentService = {
    getAppointmentSlots,
    bookAppointment,
    getAllBookingsByUserId,
    cancelBooking
};


function getAppointmentSlots(date){
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/api/v1/appointments/getAppointmentSlots/${date}`, requestOptions).then(handleResponse);
}

function bookAppointment(data) {
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(data) };
    return fetch(`${config.apiUrl}/api/v1/appointments/bookAppointment`, requestOptions).then(handleResponse);
}

function getAllBookingsByUserId(userid){
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/api/v1/appointments/getAllBookingsByUserId/${userid}`, requestOptions).then(handleResponse);
}

function cancelBooking(data){
    const requestOptions = { method: 'POST', headers: authHeader(),body: encData(data) };
    return fetch(`${config.apiUrl}/api/v1/appointments/cancel`, requestOptions).then(handleResponse);
}