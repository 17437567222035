import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { setUserData } from "../../redux-store/action";
import { Link } from "react-router-dom";
import { authenticationService } from "../../_services";
import { departmentService, userService } from "../../_services/admin";
import { NotificationManager } from "react-notifications";
import MaskedInput from "react-text-mask";
import * as Yup from "yup";
import PasswordMask from "react-password-mask";
import config from "../../config";
import moment from "moment";
import axios from "axios";
import mime from "mime-types";
import NocCodeForm from "./NocCodeForm";
import swal from "sweetalert";
import { history, Role } from "../../_helpers";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

function validateEmail(value) {
  let error;
  if (!value) {
    error = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
}

class MyProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      user: {},
      department: [],
      loading: true,
      profileImage: "",
      profilepicture: "",
      url: "",
      success: false,
      imageLoading: false,
      showImgError: false,
    };
  }

  componentDidMount() {
    userService.getUserById(this.state.currentUser.id).then((data, error) => {
      if (data.Data.profileImageUrl) {
        this.setState({
          user: data.Data,
          loading: false,
          profilepicture: data.Data.profileImageUrl,
          url: data.Data.profileImageUrl,
        });
      } else {
        this.setState({ user: data.Data, loading: false });
      }
      //this.setState({user:data.Data, loading:false});
    });
  }

  profileImageEdit = () => {
    this.setState({
      profilepicture: "",
      url: "",
    });
  };

  checkimg = () => {
    if (this.state.url) {
      this.setState({
        showImgError: false,
      });
    } else {
      this.setState({
        showImgError: true,
      });
    }
  };

  onChangeFileHandler = (event) => {
    this.setState({
      profilepicture: "",
      url: "",
      imageLoading: true,
      showImgError: false,
    });
    if (event.target.files[0]) {
      //   document.getElementById("img_url").src = (window.URL
      //     ? URL
      //     : window.webkitURL
      //   ).createObjectURL(event.target.files[0]);
      let file = event.target.files[0];
      // Split the filename to get the name and type
      let fileName = "user-profile/" + event.target.files[0].name;
      let fileType = mime.lookup(event.target.files[0].name);
      console.log(fileName);
      console.log(fileType);
      console.log("Preparing the upload");

      axios
        .post(
          `${config.apiUrl}/api/v1/users/profile-image`,
          {
            fileName: fileName,
            fileType: fileType,
          },
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`,
              isenc: localStorage.getItem("isenc")
                ? parseInt(localStorage.getItem("isenc"))
                : 0,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          var returnData = response.data.Data;
          var signedRequest = returnData.signedRequest;
          var url = returnData.url;
          this.setState({ url: url });
          console.log("Recieved a signed request " + signedRequest);

          // Put the fileType in the headers for the upload
          var options = {
            headers: {
              "Content-Type": fileType,
            },
          };
          axios
            .put(signedRequest, file, options)
            .then((result) => {
              console.log("Response from s3", result);
              this.setState({
                success: true,
                imageLoading: false,
                profilepicture: url,
              });
            })
            .catch((error) => {
              alert("ERROR " + JSON.stringify(error));
            });
        })
        .catch((error) => {
          alert(JSON.stringify(error));
        });
    } else {
      document.getElementById("img_url").src = "";
      this.setState({
        url: "",
        imageLoading: false,
        showImgError: true,
      });
      // document.getElementById("validimage").innerHTML = "Choose Your Image"
    }
  };

  showWarningAlertOnDeleteAccount = () => {
    swal(
      "Are you sure you want to remove this account? Once removed you wont be able to login back!",
      {
        dangerMode: true,
        buttons: true,
      }
    ).then((isConfirm) => {
      console.log("value", isConfirm);

      if (isConfirm) {
        console.log("Account Deleted!");

        const payload = {
          userId: this.state.currentUser.id,
        };
        userService.updateUserActiveStatus(payload).then((data) => {
          console.log("data--->", data);
          authenticationService.logout();

          swal("Account Deleted Successfully", "", "success");
        });

        // history.push("/");
      }
    });
  };

  render() {
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <Sidebar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">My Profile</h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">My Profile</li>
                </ol>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        firstName: this.state.user.firstName || "",
                        lastName: this.state.user.lastName || "",
                        preferredName: this.state.user.preferredName || "",
                        email: this.state.user.email || "",
                        dob:
                          (this.state.user.dob &&
                            moment(this.state.user.dob).format("MM/DD/YYYY")) ||
                          "",
                        phoneNumber: this.state.user.phoneNumber || "",
                        gender: this.state.user.gender || "",
                        userId: this.state.currentUser.id || "",
                        currentUserId: this.state.currentUser.id || "",
                      }}
                      validationSchema={Yup.object().shape({
                        email: Yup.string().required("Email is required"),
                        firstName: Yup.string().required(
                          "First Name is required"
                        ),
                        lastName: Yup.string().required(
                          "Last Name is required"
                        ),
                        preferredName: Yup.string().required(
                          "Prefered Name is required"
                        ),
                        dob: Yup.string().required("Date of Birth is required"),
                        phoneNumber: Yup.string()
                          .required("Phone Number is Required")
                          .min(
                            14,
                            "Phone Number should contain minimum of 10 digits"
                          )
                          .max(
                            14,
                            "Phone Number should contain maximum of 10 digits"
                          ),
                        // phoneNumber: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Phone number is not valid'),
                        gender: Yup.string().required("Gender is required"),
                      })}
                      onSubmit={(formData, { setStatus, setSubmitting }) => {
                        setStatus();
                        console.log("formData: ", formData);
                        formData.profileImageUrl = this.state.url;
                        // deep copy formdata to userdata to prevent change in ref variables
                        let userData = JSON.parse(JSON.stringify(formData));
                        userData.dob = moment(
                          formData.dob,
                          "MM/DD/YYYY"
                        ).toDate();
                        userService
                          .updateProfile(userData)
                          .then(
                            (user) => {
                              //const { from } = this.props.location.state || { from: { pathname: "/login" } };
                              //this.props.history.push(from);
                              setSubmitting(false);
                              NotificationManager.success(
                                "Profile Updated Successfully"
                              );
                              authenticationService.refreshUserProfile(
                                userData
                              );
                            },
                            (error) => {
                              setSubmitting(false);
                              setStatus(error);
                            }
                          )
                          .catch((err) => {
                            console.log("error ----> ", err);
                            setSubmitting(false);
                            setStatus(err);
                          });
                      }}
                      render={({
                        errors,
                        status,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        values,
                        handleChange,
                      }) => (
                        <Form>
                          <div className="form-material">
                            <div className="form-group required">
                              <label className="control-label">
                                Legal First Name
                              </label>
                              <Field
                                name="firstName"
                                type="text"
                                placeholder="Name"
                                className={
                                  "form-control" +
                                  (errors.firstName && touched.firstName
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="firstName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">
                                {" "}
                                Legal Last Name
                              </label>
                              <Field
                                name="lastName"
                                type="text"
                                placeholder="Last Name"
                                className={
                                  "form-control" +
                                  (errors.lastName && touched.lastName
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="lastName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">
                                {" "}
                                Preferred Name
                              </label>
                              <Field
                                name="preferredName"
                                type="text"
                                placeholder="Preferred Name"
                                className={
                                  "form-control" +
                                  (errors.preferredName && touched.preferredName
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="preferredName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">Email</label>
                              <Field
                                name="email"
                                disabled={true}
                                type="text"
                                placeholder="Email"
                                validate={validateEmail}
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-group required">
                              <label className="control-label"> DOB</label>

                              <MaskedInput
                                mask={[
                                  /\d/,
                                  /\d/,
                                  "/",
                                  /\d/,
                                  /\d/,
                                  "/",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                ]}
                                name="dob"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.dob && touched.dob
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="MM/DD/YYYY"
                                guide={false}
                                type="text"
                                value={values.dob}
                                render={(ref, props) => (
                                  <input
                                    type="text"
                                    label="Date of Birth"
                                    name="dob"
                                    ref={ref}
                                    value={values.dob}
                                    {...props}
                                  />
                                )}
                              />
                              <ErrorMessage
                                name="dob"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-group required">
                              <label className="control-label">
                                {" "}
                                Phone Number
                              </label>
                              {/* <Field name="phoneNumber" type="number" min="0" placeholder="Phone Number"
                                                className={'form-control' + (errors.phoneNumber && touched.phoneNumber ? ' is-invalid' : '')}
    
                                            /> */}
                              <MaskedInput
                                mask={[
                                  "(",
                                  /[1-9]/,
                                  /\d/,
                                  /\d/,
                                  ")",
                                  " ",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  "-",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                ]}
                                name="phoneNumber"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.phoneNumber && touched.phoneNumber
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Phone Number"
                                guide={false}
                                type="text"
                                value={values.phoneNumber}
                                render={(ref, props) => (
                                  <input
                                    type="text"
                                    label="Phone Number"
                                    name="phoneNumber"
                                    ref={ref}
                                    value={values.phoneNumber}
                                    {...props}
                                  />
                                )}
                              />
                              <ErrorMessage
                                name="phoneNumber"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-group required">
                              <label className="control-label">Gender</label>
                              <div className="form-check form-check-inline">
                                <Field
                                  type="radio"
                                  name="gender"
                                  value="male"
                                  id="gender-male"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="gender-male"
                                >
                                  Male
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <Field
                                  type="radio"
                                  name="gender"
                                  value="female"
                                  id="gender-female"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="gender-female"
                                >
                                  Female
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <Field
                                  type="radio"
                                  name="gender"
                                  value="other"
                                  id="gender-other"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="gender-other"
                                >
                                  Other
                                </label>
                              </div>
                              <ErrorMessage
                                name="gender"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-group required">
                              <div>
                                <label className="control-label">
                                  {" "}
                                  Profile Picture
                                </label>
                              </div>
                              {/* <Field
                                                name="url"
                                                className={'form-control' + (errors.url && touched.url ? ' is-invalid' : '')}
                                                render={({ field }) => ( */}
                              <div
                                className="d-flex browseBTN"
                                style={{ marginTop: "5px" }}
                              >
                                <div
                                  className="d-flex"
                                  style={{ flexDirection: "column" }}
                                >
                                  <input
                                    name="url"
                                    className="inputFille"
                                    // className={(errors.url && touched.url ? ' is-invalid' : '')}
                                    type="file"
                                    onChange={this.onChangeFileHandler}
                                    onClick={this.profileImageEdit}
                                  />
                                  {this.state.showImgError && (
                                    <span
                                      style={{
                                        color: "#dc3545",
                                        marginTop: ".25rem",
                                        fontSize: "80%",
                                      }}
                                    >
                                      Profile Picture is Required
                                    </span>
                                  )}
                                </div>

                                {this.state.imageLoading && (
                                  <img
                                    style={{ height: "27px" }}
                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                                  />
                                )}
                                {this.state.profilepicture ? (
                                  <img
                                    src={this.state.profilepicture}
                                    height="128px"
                                    width="128px"
                                    id="img_url"
                                    alt="your image"
                                  ></img>
                                ) : (
                                  <img
                                    src=""
                                    id="img_url"
                                    alt="your image"
                                  ></img>
                                )}
                              </div>
                              {/* <ErrorMessage name="url" component="div" className="invalid-feedback" /> */}
                            </div>

                            <div className="form-group text-center mb-0 m-t-20">
                              <div className="col-xs-12 text-right">
                                <Link
                                  to="/"
                                  className="btn btn-outline-secondary mr-2"
                                >
                                  Cancel
                                </Link>
                                <button
                                  type="submit"
                                  disabled={isSubmitting}
                                  className="btn btn-primary text-uppercase waves-effect waves-light"
                                >
                                  Update
                                </button>
                                {isSubmitting && (
                                  <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                )}
                              </div>
                            </div>
                            {status && (
                              <div className={"alert alert-danger mt-2"}>
                                {status}
                              </div>
                            )}
                          </div>
                        </Form>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <label className="control-label">NOC Code</label>
                    <NocCodeForm />
                  </div>
                </div>

                <div
                  className="card"
                  style={{ borderColor: "red", borderWidth: "thin" }}
                >
                  <div className="card-body">
                    <label className="control-label">
                      Remove this Account ?
                    </label>
                    <div>
                      {" "}
                      <button
                        className="btn btn-danger text-uppercase waves-effect waves-light"
                        onClick={() => {
                          this.showWarningAlertOnDeleteAccount();
                        }}
                      >
                        Remove Account
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    needAssistanceWith: this.state.user.needAssistanceWith || [],
                                    preferredDay: this.state.user.preferredDay || [],
                                    preferredTime:this.state.user.preferredTime || [],
                                    preferredMethodOfContact:this.state.user.preferredMethodOfContact || [],
                                    userId: this.state.currentUser.id || "",
                                    currentUserId: this.state.currentUser.id || ""
                                }}
                                onSubmit={(formData, { setStatus, setSubmitting }) => {
                                    setStatus();
                                    userService.updatePrefference(formData)
                                    .then(
                                        user => {
                                            //const { from } = this.props.location.state || { from: { pathname: "/login" } };
                                            //this.props.history.push(from);
                                            NotificationManager.success('Preferrence Updated Successfully');
                                            setSubmitting(false);
                                        },
                                        error => {
                                            setSubmitting(false);
                                            setStatus(error);
                                        }
                                    );
                                }}
                                render={({ errors, status, touched, isSubmitting, setFieldValue, values, handleChange }) => (
                                <Form>
                                    <div className="form-horizontal form-material" id="loginform">
                                       
                                        <div className="form-group">
                                            <label className="control-label">I need assistance with:</label>
                                            <div class="form-check">
                                                <Field name="needAssistanceWith" type="checkbox" value="employment-services" id="employment-services" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="employment-services">
                                                    Employment Services (resumes, one-on-one career counseling)
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="needAssistanceWith" type="checkbox" value="newcomer-services" id="newcomer-services" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="newcomer-services">
                                                Newcomer Services (English Classes. Computer Training)
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="needAssistanceWith" type="checkbox" value="youth-programs" id="youth-programs" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="youth-programs">
                                                Youth Programs (pre-employment training and paid job placement ages 15 – 29)
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="needAssistanceWith" type="checkbox" value="energy-assistance" id="energy-assistance" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="energy-assistance">
                                                Energy Assistance (trouble paying your utility bills)
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="needAssistanceWith" type="checkbox" value="lbs" id="lbs" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="lbs">
                                                LBS
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="needAssistanceWith" type="checkbox" value="ged" id="ged" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="ged">
                                                GED
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="needAssistanceWith" type="checkbox" value="pre-apprenticeship" id="pre-apprenticeship" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="pre-apprenticeship">
                                                Pre-Apprenticeship
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="needAssistanceWith" type="checkbox" value="employment-workshops" id="employment-workshops" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="employment-workshops">
                                                Employment Workshops
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="needAssistanceWith" type="checkbox" value="computer-workshops" id="computer-workshops" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="computer-workshops">
                                                Computer Workshops
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="needAssistanceWith" type="checkbox" value="food-assistance" id="food-assistance" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="food-assistance">
                                                Food Assistance
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="needAssistanceWith" type="checkbox" value="cooking-classes" id="cooking-classes" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="cooking-classes">
                                                Cooking Classes
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="needAssistanceWith" type="checkbox" value="ri-workshops" id="ri-workshops" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="ri-workshops">
                                                RI Workshops (Resume Writing, Interview Techniques, etc.)
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="needAssistanceWith" type="checkbox" value="other" id="other" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="other">
                                                Other
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="control-label">Preferred Day:</label>
                                            <div class="form-check">
                                                <Field name="preferredDay" type="checkbox" value="monday" id="monday" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="monday">
                                                    Monday
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="preferredDay" type="checkbox" value="tuesday" id="tuesday" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="tuesday">
                                                    Tuesday
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="preferredDay" type="checkbox" value="wednesday" id="wednesday" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="wednesday">
                                                Wednesday
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="preferredDay" type="checkbox" value="thursday" id="thursday" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="thursday">
                                                Thursday
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="preferredDay" type="checkbox" value="friday" id="friday" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="friday">
                                                Friday
                                                </label>
                                            </div>
                                        </div>
                                        
                                        <div className="form-group">
                                            <label className="control-label">Preferred Time:</label>
                                            <div class="form-check">
                                                <Field name="preferredTime" type="checkbox" value="morning-9-11" id="morning-9-11" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="morning-9-11">
                                                    Morning (9-11)
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="preferredTime" type="checkbox" value="afternoon-1-4" id="afternoon-1-4" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="afternoon-1-4">
                                                    Afternoon (1-4)
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="preferredTime" type="checkbox" value="my-time-falls-outside-these-hours" id="my-time-falls-outside-these-hours" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="my-time-falls-outside-these-hours">
                                                my time falls outside of these hours
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="control-label">Preferred method of contact:</label>
                                            <div class="form-check">
                                                <Field name="preferredMethodOfContact" type="checkbox" value="phone" id="phone" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="phone">
                                                    Phone
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="preferredMethodOfContact" type="checkbox" value="text" id="text" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="text">
                                                    Text
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <Field name="preferredMethodOfContact" type="checkbox" value="email" id="email" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="email">
                                                Email
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-group text-right mb-0 m-t-20">
                                            <div className="col-xs-12">
                                                <button type="submit" disabled={isSubmitting} className="btn btn-primary text-uppercase waves-effect waves-light" >Update</button>
                                                {isSubmitting &&
                                                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                }
                                            </div>
                                        </div>
                                        
                                    </div>
                                </Form>

                                )}
                                />
                                </div>
                            </div>
                        </div> */}
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
