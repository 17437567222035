import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { userService } from "../../_services/admin";
import { authenticationService } from "../../_services";
import { contentLibraryService } from "../../_services/staff";
import { MDBDataTable } from "mdbreact";
import moment from "moment";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
  };
}

class ContentLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      userFromApi: null,
      contentCategories: [],
      loading: true,
    };
  }

  componentDidMount() {
    const { currentUser } = this.state;
    userService.getUserById(currentUser.id).then((data) => {
      data.Data && this.props.setUserData(data.Data);
    });

    this.getAllContentCategories();
    if (window.Websdk) {
      window.Websdk.q.push(["track_pageview", "content-library"]);
    }
  }

  deleteCategory(id) {
    contentLibraryService
      .deleteContentCategory(id, this.state.currentUser.id)
      .then((data) => {
        this.getAllContentCategories();
      });
  }

  getAllContentCategories() {
    contentLibraryService.getAll().then((data) => {
      let rowsArray = [];
      data &&
        data.Data &&
        data.Data.forEach((item, i) => {
          rowsArray.push({
            category: item.name,
            description: item.description,
            view: (
              <React.Fragment>
                <Link
                  to={"/category-detail/" + item._id}
                  className="btn btn-sm btn-primary"
                >
                  <span id={item._id} className="hide-menu">
                    View Files
                  </span>
                </Link>
              </React.Fragment>
            ),
            // clickEvent: () => this.handleClick(params)
            clickEvent: () => document.getElementById(item._id).click(),
          });
        });

      const tableData = {
        columns: [
          // {
          //   label: "No.",
          //   field: "key",
          //   sort: "asc",
          //   width: 270,
          // },
          {
            label: "Category",
            field: "category",
            sort: "asc",
            width: 150,
          },
          {
            label: "Description",
            field: "description",
            sort: "asc",
            width: 150,
          },
          {
            label: "View",
            field: "view",
            sort: "disabled",
            width: 270,
          },
        ],
        rows: rowsArray,
      };
      this.setState({ contentCategories: tableData, loading: false });
    });
  }

  render() {
    const { contentCategories } = this.state;
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper contentLibrary">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Resource Library</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="align-self-center tbl-head">
                          <i className="mdi mdi-view-dashboard mr-2"></i>
                          <span>RESOURCE LIBRARY</span>
                        </div>
                      </div>
                      <hr />
                      <MDBDataTable
                        bordered
                        noBottomColumns
                        hover
                        data={contentCategories}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentLibrary);
