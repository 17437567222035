import React, {Component} from 'react';

export default class FeedbackCategory extends Component {
    handleClick(value){
        this.props.onClick(value)
    }

    getClass(value){
        return (this.props.selection === value)? " feedback-category-selected":""
    }

    render(){
        return (
            <div className="d-flex justify-content-around">
                <div className={"px-2 py-3 feedback-category" + this.getClass('suggestion')} onClick={()=> {this.handleClick('suggestion')}}>
                    <label>Suggestion</label>
                </div>
                <div className={"px-2 py-1 feedback-category" + this.getClass('not-quite-right')} onClick={()=> {this.handleClick('not-quite-right')}}>
                    <label>Something is not <br />quite right</label>
                </div>
                <div className={"px-2 py-3 feedback-category" + this.getClass('compliment')} onClick={()=> {this.handleClick('compliment')}}>
                    <label>Compliment</label>
                </div>
            </div>
        )
    }
}