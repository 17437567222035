import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { history, AccessType } from "../../_helpers";
import { authenticationService } from "../../_services";

function mapStateToProps(state) {
  return {};
}

class Sidebaar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
    };
  }
  logout() {
    authenticationService.logout();
    history.push("/login");
  }
  render() {
    const { currentUser } = this.state;
    return (
      <aside className="left-sidebar">
        <div className="scroll-sidebar">
          <nav className="sidebar-nav">
            <ul id="sidebarnav">
              <li>
                <Link
                  to="/contents-library"
                  className="waves-effect waves-dark"
                >
                  <i className="mdi mdi-view-dashboard"></i>{" "}
                  <span className="hide-menu">Resource Library</span>
                </Link>
              </li>

              <li>
                <Link
                  to="/my-appointments"
                  className="waves-effect waves-dark disabled"
                >
                  <i className="mdi mdi-calendar-check"></i>{" "}
                  <span className="hide-menu">My Appointment</span>
                </Link>
              </li>

              <li>
                <Link to="/workshops" className="waves-effect waves-dark">
                  <i className="mdi mdi-calendar-text"></i>{" "}
                  <span className="hide-menu">Workshops</span>
                </Link>
              </li>

              <li>
                <Link to="/events" className="waves-effect waves-dark">
                  <i className="mdi mdi-calendar-text"></i>{" "}
                  <span className="hide-menu">Events</span>
                </Link>
              </li>

              {/* <li>
                <a className="waves-effect waves-dark disabled">
                  <i className="mdi mdi-briefcase"></i>{" "}
                  <span className="hide-menu">Opportunities</span>
                </a>
              </li> */}

              <li>
                <a
                  href="https://211ontario.ca/"
                  target="_blank"
                  className="waves-effect waves-dark"
                >
                  <i className="mdi mdi-phone"></i>{" "}
                  <span className="hide-menu">211 Ontario Search</span>
                </a>
              </li>

              <li>
                <Link to="/jobopps" className="waves-effect waves-dark">
                  <i className="mdi mdi-briefcase"></i>{" "}
                  <span className="hide-menu">Job-Opps</span>
                </Link>
              </li>

              <li>
                <a href="https://ayework.ca/" target="_blank">
                  <img
                    className="blink_me"
                    src="https://ayework.ca/wp-content/uploads/2020/03/logo-1.png"
                    alt="AyeWork"
                  />
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <div className="sidebar-footer">
          {/* <a href="#" className="link" data-toggle="tooltip" title="Settings"><i className="ti-settings"></i></a>

                <a href="#" className="link" data-toggle="tooltip" title="Email"><i className="mdi mdi-gmail"></i></a>

                <a onClick={this.logout} className="link" data-toggle="tooltip" title="Logout"><i className="mdi mdi-power"></i></a> */}
        </div>
      </aside>
    );
  }
}

export default connect(mapStateToProps)(Sidebaar);
