import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { NotificationManager } from "react-notifications";
import { authenticationService } from "../../_services";
import { eventService } from "../../_services/staff";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import EventInfoBox from "../../components/user/EventInfoBox";
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
  };
}

class EventBucket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      userFromApi: null,
      eventList: [],
      isHovering: false,
      loading: true,
    };
    this.getAllCurrentEvents = this.getAllCurrentEvents.bind(this);
  }

  componentDidMount() {
    const { currentUser } = this.state;
    // userService.getUserById(currentUser.id).then((data) => {
    //   data.Data && this.props.setUserData(data.Data);
    // });

    this.getAllCurrentEvents();
  }

  registerForEvent(eventId) {
    let registrationData = {
      userId: this.state.currentUser.id,
      userName: `${this.state.currentUser.firstName} ${this.state.currentUser.lastName}`,
      userEmail: this.state.currentUser.email,
      eventId: eventId,
      currentUserId: this.state.currentUser.id,
    };
    eventService
      .registerEvent(registrationData)
      .then((data) => {
        console.log("data: ", data);
        if (data.Status) {
          NotificationManager.success(data.Message, "", 5000);
          this.getAllCurrentEvents();
        } else {
          console.log("register event data on err: ", data);
        }
      })
      .catch((error) => NotificationManager.error(error, "", 5000));
  }

  getAllCurrentEvents() {
    eventService.getAllCurrentEvents(new Date().getTime()).then((data) => {
      if (data.Status && data.Data.length) {
        console.log(data.Data);
        this.setState({ eventList: data.Data, loading: false });
      }
    });
  }

  render() {
    const { eventList } = this.state;
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Events & Workshops</li>
                </ol>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-md-12">
                <div className="pt-3">
                  <h2>Upcoming events</h2>
                </div>
                <div>
                            <Link
                              to="/staff/content-library/create"
                              className="btn btn-primary"
                            >
                              <i className="mdi mdi-plus"></i>
                              <span className="hide-menu">Add New Category</span>
                            </Link>
                          </div>
              </div> */}

            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="align-self-center tbl-head">
                          <i className="mdi mdi-calendar-multiple-check mr-2"></i>
                          <span>Registered Events</span>
                        </div>
                      </div>
                      <hr />
                      {eventList.length ? (
                        eventList.map((item, index) => {
                          return (
                            <React.Fragment key={item._id}>
                              <div className="panel panel-default event">
                                <div
                                  className="panel-body row"
                                  style={{ backgroundColor: "darkgray" }}
                                >
                                  <div
                                    className="rsvp col-xs-2 col-sm-2"
                                    style={{ backgroundColor: "gray" }}
                                  >
                                    <i>{moment(item.startTime).format("DD")}</i>
                                    <i>
                                      {moment(item.startTime).format("MMM")}
                                    </i>
                                    <div
                                      className="hidden-xs"
                                      style={{ backgroundColor: "brown" }}
                                    >
                                      {item.participantCount ===
                                      item.participantsCapacity ? (
                                        <h4
                                          style={{
                                            color: "white",
                                            margin: "5px 0px",
                                          }}
                                        >
                                          Registration closed
                                        </h4>
                                      ) : (
                                        <h4
                                          className="cursor_pointer"
                                          style={{
                                            color: "white",
                                            margin: "5px 0px",
                                          }}
                                          onClick={() =>
                                            this.registerForEvent(item._id)
                                          }
                                        >
                                          Registered
                                        </h4>
                                      )}
                                    </div>
                                  </div>
                                  <div className="info col-xs-8 col-sm-7">
                                    {item.name.toUpperCase()}
                                    <div className="d-flex event_time">
                                      <div className="visible-xs">
                                        <i className="mdi mdi-clock"></i>{" "}
                                        {moment(item.startTime).format(
                                          "hh:mm A"
                                        )}{" "}
                                        -{" "}
                                        {moment(item.endTime).format("hh:mm A")}
                                      </div>
                                      <div className="visible-xs">
                                        <i className="mdi mdi-map-marker"></i>{" "}
                                        {item.location}
                                      </div>
                                      <div className="visible-xs">
                                        Participants: {item.participantCount} /{" "}
                                        {item.participantsCapacity}
                                      </div>
                                    </div>
                                    {/* <div className="hidden-xs">
                          <ul className="nav nav-tabs" role="tablist">
                            <li role="presentation" className="active"><a href={'#'+item._id} aria-controls={item._id} role="tab" data-toggle="tab">Location</a></li>
                            <li role="presentation"><a href={'#'+index} aria-controls={index} role="tab" data-toggle="tab">Description</a></li>
                          </ul>
                          <div className="tab-content">
                            <div role="tabpanel" className="tab-pane active" id={item._id}>{item.location}</div>
                            <div role="tabpanel" className="tab-pane" id={index}>
                              {item.description}
                            </div>
                          </div>
                        </div> */}
                                    <div className="hidden-xs">
                                      <ul
                                        className="nav nav-tabs"
                                        role="tablist"
                                      >
                                        <li
                                          role="presentation"
                                          className="active"
                                        >
                                          <a
                                            href={"#" + item._id}
                                            style={{ backgroundColor: "brown" }}
                                            aria-controls={item._id}
                                            role="tab"
                                            data-toggle="tab"
                                          >
                                            Description
                                          </a>
                                        </li>
                                        <li role="presentation">
                                          <a
                                            href={"#" + index}
                                            aria-controls={index}
                                            style={{
                                              backgroundColor: "rosybrown",
                                            }}
                                            role="tab"
                                            data-toggle="tab"
                                          >
                                            Access Link
                                          </a>
                                        </li>
                                      </ul>
                                      <div className="tab-content">
                                        <div
                                          style={{ color: "black" }}
                                          role="tabpanel"
                                          className="tab-pane active"
                                          id={item._id}
                                        >
                                          {item.description}
                                        </div>
                                        <div
                                          role="tabpanel"
                                          className="tab-pane"
                                          id={index}
                                        >
                                          <a
                                            href={item.accessLink}
                                            target="_blank"
                                          >
                                            {item.accessLink}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="author col-xs-2 col-sm-3">
                                    <div className="profile-image">
                                      <img src="http://api.randomuser.me/portraits/med/men/71.jpg" />
                                    </div>
                                    <div className="profile hidden-xs">
                                      <strong>{item.facilitatorName}</strong>
                                      <article>Event Leader</article>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <div className="col-md-12">
                          <div className="text-center">
                            <h3>No upcoming events</h3>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventBucket);
