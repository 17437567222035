import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Route } from "react-router-dom";
import Preloader from "../components/Preloader";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { authenticationService } from "../_services";
import { Role } from "../_helpers";

function mapStateToProps(state) {
  return {
    count: state.count,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

class LoginPage extends Component {
  constructor(props) {
    super(props);

    // redirect to home if already logged in
    if (authenticationService.currentUserValue) {
      this.props.history.push("/dashboard");
    }
  }
  componentDidMount() {
    let scripts = ["/assets/js/custom.min.js"];
    let host = window.location.origin;
    console.log("host", host);
    scripts.map((t) => {
      let script = document.createElement("script");
      script.src = host + t;
      script.async = false;
      document.body.appendChild(script);
      return true;
    });
  }
  render() {
    return (
      <React.Fragment>
        <Preloader />
        <section id="wrapper">
          <div className="login-register">
            <div className="login-box card">
              <div className="card-body">
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                    captchaValue: "",
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().required("Email is required"),
                    password: Yup.string().required("Password is required"),
                  })}
                  onSubmit={(
                    { email, password, captchaValue },
                    { setStatus, setSubmitting }
                  ) => {
                    setStatus();
                    console.log("client captcha: ", captchaValue);
                    authenticationService.login(email, password).then(
                      (user) => {
                        console.log("isPasswordChanged user", user);
                        if (!user.isPasswordChanged) {
                          let roleUrl =
                            user.role == Role.Admin
                              ? "/admin/change-password"
                              : "/staff/change-password";
                          const { from } = this.props.location.state || {
                            from: { pathname: roleUrl },
                          };
                          this.props.history.push(from);
                          //Route.Redirect(roleUrl);
                          //Router.Redirect(roleUrl);
                        } else {
                          const { from } = this.props.location.state || {
                            from: { pathname: "/dashboard" },
                          };
                          this.props.history.push(from);
                        }
                      },
                      (error) => {
                        setSubmitting(false);
                        setStatus(error);
                      }
                    );
                  }}
                  render={({
                    errors,
                    status,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    values,
                  }) => (
                    <Form>
                      <div
                        className="form-horizontal form-material"
                        id="loginform"
                      >
                        <img
                          className="loginlogo"
                          src={
                            window.location.origin +
                            "/assets/images/UHC_logo.png"
                          }
                        />
                        <h3 className="box-title m-b-20">Sign In</h3>
                        <div className="form-group ">
                          <div className="col-xs-12">
                            {/* <input className="form-control" type="text" required="" placeholder="Username" /> </div> */}
                            <Field
                              name="email"
                              type="text"
                              placeholder="Username"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-xs-12">
                            {/* <input className="form-control" type="password" required="" placeholder="Password" /> </div> */}
                            <Field
                              name="password"
                              type="password"
                              placeholder="Password"
                              className={
                                "form-control" +
                                (errors.password && touched.password
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="d-flex no-block align-items-center">
                            <div className="checkbox checkbox-primary p-t-0">
                              <input id="checkbox-signup" type="checkbox" />
                              <label htmlFor="checkbox-signup">
                                {" "}
                                Remember me{" "}
                              </label>
                            </div>
                            <div className="ml-auto">
                              <Link
                                to="/forgot-password"
                                id="to-recover"
                                className="text-muted"
                              >
                                <i className="fa fa-lock m-r-5"></i> Forgot pwd?
                              </Link>
                            </div>
                          </div>
                        </div>
                        <ReCAPTCHA
                          sitekey="6LcMfOIZAAAAAD1MVaVY12vdIaGR9GHKt9h3wDmm"
                          onChange={(value) =>
                            setFieldValue("captchaValue", value)
                          }
                        />
                        <div className="form-group text-center mb-0 m-t-20">
                          <div className="col-xs-12">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light"
                            >
                              Log In
                            </button>
                            {isSubmitting && (
                              <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            )}
                          </div>
                        </div>
                        <div className="pt-2 text-center">
                          <span>
                            Not Registered?{" "}
                            <Link to="/register">Create an account</Link>
                          </span>
                        </div>
                        {status && (
                          <div className={"alert alert-danger"}>{status}</div>
                        )}
                      </div>
                    </Form>
                  )}
                />
                <form className="form-horizontal" id="recoverform" action="#">
                  <div className="form-group ">
                    <div className="col-xs-12">
                      <h3>Recover Password</h3>
                      <p className="text-muted">
                        Enter your Email and instructions will be sent to you!{" "}
                      </p>
                    </div>
                  </div>
                  <div className="form-group ">
                    <div className="col-xs-12">
                      <input
                        className="form-control"
                        type="text"
                        required=""
                        placeholder="Email"
                      />{" "}
                    </div>
                  </div>
                  <div className="form-group text-center mb-0 m-t-20">
                    <div className="col-xs-12">
                      <button
                        className="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light"
                        type="submit"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
