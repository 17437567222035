import React, { Component } from "react";
import moment from 'moment';
import { eventService } from '../../_services/staff';
import { NotificationManager } from 'react-notifications';
class EventInfoBox extends Component {
    constructor(props){
        super(props);
        this.state = {
            isHovering:false,
        }
        this.handleMouseHover = this.handleMouseHover.bind(this);
    }

    handleMouseHover() {
        this.setState(this.toggleHoverState);
    }
    
    toggleHoverState(state) {
        return {
            isHovering: !state.isHovering,
        };
    }

    registerForEvent(eventId){
        let registrationData = {
            userId: this.props.currentUser.id,
            userName: `${this.props.currentUser.firstName} ${this.props.currentUser.lastName}`,
            userEmail: this.props.currentUser.email,
            eventId: eventId,
            currentUserId: this.props.currentUser.id
        }
        eventService.registerEvent(registrationData)
        .then(data => {
            console.log('data: ', data);
            if(data.Status){
                NotificationManager.success('Registered for event successfully', '', 5000);
                this.props.registerCallback();
            } else {
                console.log("register event data on err: ", data)
            }
        })
        .catch(error => NotificationManager.error(error, '', 5000));
      }

    render(){
        let {isHovering} = this.state;
        let {data} = this.props;
        return (
            <div className="col-md-12">
                <div className="event-box pt-3" 
                    onMouseEnter={this.handleMouseHover}
                    onMouseLeave={this.handleMouseHover}
                >
                    <div className="row">
                        <div className="col-md-3">
                            <div className="text-center event-date-box pt-1">
                            <h3>{moment(data.startTime).format('DD')}</h3>
                            <span className="text-uppercase">{moment(data.startTime).format('MMM')}</span>
                            </div>
                            
                        </div>
                        <div className="col-md-9">
                            <div className="event-time">{moment(data.startTime).format('hh:mm A')} - {moment(data.endTime).format('hh:mm A')}</div>
                            <div className="font-weight-bold event-name">{data.name}</div>
                            <div>{data.location}</div>
                        </div>
                        {isHovering && (<div className="col-md-12">
                            <div className="pt-2 pl-3 pr-3 pb-3">
                            <p>{data.description}</p>
                            <p>Facilitator: {data.facilitatorName}, Participants: {data.participantCount} / {data.participantsCapacity}</p>
                            <a href={data.accessLink} target="_blank">{data.accessLink}</a>
                            {(data.participantCount === data.participantsCapacity)?
                                <button className="mt-1 btn btn-secondary btn-block" disabled>Registration closed</button>:
                                <button className="mt-1 btn btn-primary btn-block" onClick={() => this.registerForEvent(data._id)}>Register</button>
                            }
                            
                            </div>
                            </div>)
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default EventInfoBox;