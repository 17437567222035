import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { history } from "../../_helpers";
import { authenticationService } from "../../_services";

function mapStateToProps(state) {
  return {};
}

class Sidebaar extends Component {
  logout() {
    authenticationService.logout();
    history.push("/login");
  }
  render() {
    return (
      <aside className="left-sidebar">
        <div className="scroll-sidebar">
          <nav className="sidebar-nav">
            <ul id="sidebarnav">
              {/* <li><Link to="/admin" className="waves-effect waves-dark disabled" ><i className="mdi mdi-view-dashboard"></i> <span className="hide-menu">Dashboard</span></Link></li>
                        <li><Link to="/admin/departments" className="waves-effect waves-dark disabled"><i className="mdi mdi-book-multiple"></i> <span className="hide-menu">Departments</span></Link></li>
                        <li><Link to="/admin/users" className="waves-effect waves-dark disabled"><i className="mdi mdi-account-multiple"></i> <span className="hide-menu">Users</span></Link></li>
                        <li><Link to="/admin/analytics" className="waves-effect waves-dark disabled"><i className="mdi mdi-account-multiple"></i> <span className="hide-menu">Analytics</span></Link></li> */}
              <li>
                <a className="waves-effect waves-dark disabled">
                  <i className="mdi mdi-view-dashboard"></i>{" "}
                  <span className="hide-menu">Dashboard</span>
                </a>
              </li>
              {/* <li><a className="waves-effect waves-dark disabled"><i className="mdi mdi-book-multiple"></i> <span className="hide-menu">Departments</span></a></li> */}
              <li>
                <Link
                  to="/admin/departments"
                  className="waves-effect waves-dark disabled"
                >
                  <i className="mdi mdi-book-multiple"></i>{" "}
                  <span className="hide-menu">Departments</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/site-locations"
                  className="waves-effect waves-dark disabled"
                >
                  <i className="mdi mdi-map-marker"></i>{" "}
                  <span className="hide-menu">Site Location</span>
                </Link>
              </li>
              <li>
              <Link to="/admin/users" className="waves-effect waves-dark disabled"><i className="mdi mdi-account-multiple"></i> <span className="hide-menu">Users</span></Link>
              </li>
              <li>
                <a className="waves-effect waves-dark disabled">
                  <i className="mdi mdi-account-multiple"></i>{" "}
                  <span className="hide-menu">Analytics</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <div className="sidebar-footer">
          {/* <a href="#" className="link" data-toggle="tooltip" title="Settings"><i className="ti-settings"></i></a>
                <a href="#" className="link" data-toggle="tooltip" title="Email"><i className="mdi mdi-gmail"></i></a>
                <a onClick={this.logout} className="link" data-toggle="tooltip" title="Logout"><i className="mdi mdi-power"></i></a> */}
        </div>
      </aside>
    );
  }
}

export default connect(mapStateToProps)(Sidebaar);
