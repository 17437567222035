import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData, setJobData } from "../../redux-store/action";
import { NotificationManager } from "react-notifications";
import { authenticationService } from "../../_services";
import { eventService } from "../../_services/staff";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { userService } from "../../_services/admin";
import Spinner from "react-bootstrap/Spinner";

function mapStateToProps(state) {
  return {
    jobData: state.jobData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
    setJobData: (data) => {
      dispatch(setJobData(data));
    },
  };
}

class JobOpps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      userFromApi: null,
      jobData: [],
      jobData1: [],
      dataLoaded: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchAllJobByUsersNocDetails();
  }

  fetchAllJobByUsersNocDetails() {
    const { jobData } = this.props;
    if (jobData.length > 0) {
      this.setState({ dataLoaded: true, loading: false });
      this.setState({ jobData: jobData });
      this.setState({ jobData1: jobData });
    } else {
      console.log("currentUser: ", this.state.currentUser.id);
      let userId = this.state.currentUser.id;
      userService.fetchAllJobByUsersNocDetails(userId).then((data) => {
        console.log("data", data.Data);
        this.setState({ dataLoaded: true, loading: false });
        this.setState({ jobData: data.Data });
        this.setState({ jobData1: data.Data });
        this.props.setJobData(data.Data);
      });
    }
  }

  filterJobDataBasedOnCity(city) {
    console.log("city", city);

    if (city === "all") {
      this.setState({ jobData1: this.state.jobData });
      return;
    }
    const filterJobData = this.state.jobData.filter(
      (jobItem) => jobItem.City == city
    );
    console.log(filterJobData);
    this.setState({ jobData1: filterJobData });
  }
  filterJobDataBasedOnTransist(transist) {
    console.log("transist: ", transist);

    if (transist == "All") {
      this.setState({ jobData1: this.state.jobData });
      return;
    }
    const filterJobData = this.state.jobData.filter(
      (jobItem) => jobItem.BusRoute == transist
    );
    console.log(filterJobData);
    this.setState({ jobData1: filterJobData });
  }

  render() {
    const { jobData, jobData1, dataLoaded } = this.state;
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Job-Opps</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="align-self-center tbl-head">
                          <i className="mdi mdi-briefcase mr-3"></i>
                          <span>Find Your New Job!</span>
                        </div>
                      </div>
                      <div className="mt-2">
                        Here are UHC’s exclusive employment opportunities for
                        you to check out!
                      </div>
                      <hr />
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          keyword: "",
                        }}
                        onSubmit={(formData, { setStatus, setSubmitting }) => {
                          setStatus();

                          let regularExpressionOfSearchString = new RegExp(
                            formData.keyword,
                            "i"
                          );

                          let filteredRegexData = [];
                          jobData.forEach((jobs) => {
                            if (
                              jobs?.JobTitle.match(
                                regularExpressionOfSearchString
                              )
                            ) {
                              filteredRegexData.push(jobs);
                            }
                          });

                          this.setState({ jobData1: filteredRegexData });
                          setSubmitting(false);
                        }}
                        render={({
                          errors,
                          status,
                          touched,
                          isSubmitting,
                          setFieldValue,
                          values,
                          handleChange,
                        }) => (
                          <Form>
                            <div className="row">
                              <div className="col-md-6">
                                <Field
                                  name="locations"
                                  as="select"
                                  className={"form-control"}
                                  placeholder="All Location"
                                  onChange={(e) => {
                                    this.filterJobDataBasedOnCity(
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option value="all">All Location </option>
                                  <option value="Belle River">
                                    Belle River, ON{" "}
                                  </option>
                                  <option value="Essex">Essex, ON </option>
                                  <option value="La Salle">
                                    La Salle, ON{" "}
                                  </option>
                                  <option value="Leamington">
                                    Leamington, ON{" "}
                                  </option>
                                  <option value="London">London, ON </option>
                                  <option value="Oldcastle">
                                    Oldcastle, ON{" "}
                                  </option>
                                  <option value="Tecumseh">
                                    Tecumseh, ON{" "}
                                  </option>
                                  <option value="Windsor">Windsor, ON </option>
                                </Field>
                              </div>
                              <div className="col-md-6">
                                <Field
                                  name="transit"
                                  as="select"
                                  className={"form-control"}
                                  placeholder=""
                                  onChange={(e) => {
                                    this.filterJobDataBasedOnTransist(
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option value="All">All</option>
                                  <option value="No">Transit</option>
                                  <option value="Yes">On Bus Route</option>
                                </Field>
                              </div>
                              <div className="col-md-12 mt-3 placeholder-center ">
                                <Field
                                  name="keyword"
                                  type="text"
                                  placeholder="Keyword"
                                  className={"form-control"}
                                />
                              </div>
                              <div className="form-group text-center mb-0 m-t-20">
                                <div className="col-xs-12">
                                  <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="btn btn-primary text-uppercase waves-effect waves-light"
                                  >
                                    Search
                                  </button>
                                  {isSubmitting && (
                                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                  )}
                                </div>
                              </div>
                              {status && (
                                <div className={"alert alert-danger mt-2"}>
                                  {status}
                                </div>
                              )}
                            </div>
                            <hr />
                            <table className="table table-bordered table-hover table-striped">
                              <thead>
                                <tr>
                                  <th style={{ fontWeight: "600" }}>
                                    Job Title
                                  </th>
                                  <th style={{ fontWeight: "600" }}>
                                    Date Posted
                                  </th>
                                  <th style={{ fontWeight: "600" }}>
                                    Full Details
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {!dataLoaded ? (
                                  <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </Spinner>
                                ) : (
                                  jobData1.map((jobItem, index) => {
                                    return (
                                      <>
                                        <tr key={index}>
                                          <td>{jobItem?.JobTitle}</td>
                                          <td>
                                            {moment(
                                              jobItem?.DisplayOnWebDatePost
                                                ?.date
                                            ).format("MMMM DD, YYYY")}
                                          </td>
                                          <td>
                                            <Link
                                              to={`/joboppsdetail/${jobItem?.JobId}`}
                                            >
                                              Learn More
                                              <i className="mdi mdi-arrow-right-drop-circle ml-2 mb-1"></i>
                                            </Link>
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })
                                )}
                              </tbody>
                            </table>
                          </Form>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
          <FooterAdmin />
        </div>
        <Modal
          isOpen={this.state.contactModal}
          toggle={this.toggleContact}
          centered
        >
          <ModalHeader toggle={this.toggleContact}>Registration</ModalHeader>
          <ModalBody>
            Please Contact UHC for details regarding registation.
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobOpps);
