import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Route } from "react-router-dom";
import Preloader from '../components/Preloader';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { authenticationService } from '../_services';
import { userService } from '../_services/admin';
import { Role } from '../_helpers';



function mapStateToProps(state) {
    return {
        count: state.count,
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}



class AccountVerification extends Component {
    constructor(props) {
        super(props);

        // redirect to home if already logged in
        if (authenticationService.currentUserValue) { 
            this.props.history.push('/dashboard');
        }

        this.state={
            isVerified:false
        }
    }
    componentDidMount () {
        let scripts = [
            '/assets/js/custom.min.js'
        ];
        let host = window.location.origin;
        console.log("host", host);
        scripts.map((t) => {
            let script = document.createElement("script");
            script.src = host + t;
            script.async = false;
            document.body.appendChild(script);
            return true;
        });

        userService.verifyEmail(this.props.match.params.token)
        .then(result => {
            if(result.Status){
                this.setState({isVerified:true})
            }
        })
        
    }
    render() {
        return (
            <React.Fragment>
                <Preloader />
                <section id="wrapper">
                    <div className="login-register">
                        <div className="login-box card">
                            <div className="card-body">
                                {this.state.isVerified? 
                                <div><h3>Your email has been successfully verified!</h3>
                                <p>Please login to access your account.</p>
                                <button type="button" className="btn btn-success btn-block" onClick={() => this.props.history.push('/')}>Login</button>
                                </div>:<h3>Please wait while we verify your account</h3>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountVerification);