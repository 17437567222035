import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { userService } from "../../_services/admin";
import { authenticationService } from "../../_services";
import { genderMap, GRADIENTS } from "../../_helpers";
import { getBirthYear } from "../../_helpers/_helperFunctions";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { MDBDataTable, MDBBtn } from "mdbreact";
import { Link } from "react-router-dom";
import { appointmentService } from "../../_services/user/appointment.service";
import { eventService } from "../../_services/staff";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Websdk from "../../_helpers/analytics";
import RegistrationConfirmation from "../../shared/RegistrationConfirmation";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { programType } from "../../_helpers";
import NocCodeForm from "./NocCodeForm";
import Badge from "react-bootstrap/Badge";

function mapStateToProps(state) {
  return {
    workshopChangeId: state.workshopChangeId,
    updatedAppointment: state.updatedAppointment,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
  };
}

class ClientDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      userFromApi: null,
      isPreferrenceModal: false,
      modal: false,
      appointmentDetails: {},
      eventList: [],
      workshopList: [],
      totalBooking: null,
      loading: true,
      noAppointment: true,
      contactModal: false,
      nocModal: false,
    };
  }
  componentDidMount() {
    userService
      .setNewUserFalse({ userId: this.state.currentUser.id })
      .then((data, error) => {});
    this.getAllCurrentWorkshops();
    this.getAllCurrentEvents();
    let scripts = [
      "/assets/plugins/echarts/echarts-all.js",
      "/assets/plugins/echarts/echarts-init.js",
      "/assets/js/custom.min.js",
    ];
    let host = window.location.origin;
    scripts.map((t) => {
      let script = document.createElement("script");
      script.src = host + t;
      script.async = false;

      document.body.appendChild(script);
      return true;
    });
    this.getBookingsbyId();
    if (this.state.currentUser.newUser) {
      this.setState({
        nocModal: true,
      });
      // this.setState({isPreferrenceModal: true})
    }
  }

  componentDidUpdate(prevProps) {
    let prevAppointment = JSON.stringify(prevProps.updatedAppointment);
    let newAppointment = JSON.stringify(this.props.updatedAppointment);
    let prevWorkshopId = prevProps.workshopChangeId;
    let newWorkshopId = this.props.workshopChangeId;

    if (prevAppointment !== newAppointment) {
      this.getBookingsbyId();
    }

    if (prevWorkshopId !== newWorkshopId) {
      //alert("Update state now!!");
      if (this.state.selectedTab === "all") {
        this.getAllCurrentWorkshops();
        this.getAllCurrentEvents();
      }
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  toggleContact = () => {
    this.setState((state) => {
      return { contactModal: !state.contactModal };
    });
  };

  toggleNocModal = () => {
    this.setState((state) => {
      return { nocModal: !state.nocModal };
    });
  };

  getDetails = (user) => {
    this.setState({
      appointmentDetails: user,
    });
    this.toggle();
  };

  getBookingsbyId = () => {
    appointmentService
      .getAllBookingsByUserId(this.state.currentUser.id)
      .then((data) => {
        let rowsArray = [];
        if (data?.Data && data?.Data?.length) {
          this.setState({
            noAppointment: false,
            totalBooking: data.Data.length,
          });
          data.Data.map((user, index) => {
            rowsArray.push({
              key: index + 1,
              bookingdate: user.date || "pending...",
              bookingtime: user.time || "pending...",
              // bookingdate: moment(user.date).format("dddd, MMM Do YYYY"),
              // preferredDay: user.preferredDay ? (user.preferredDay + " ") : " ",
              // bookingtime: moment(user.time?.startTime, "HH:mm").format("hh:mm A") + " - " + moment(user.time?.endTime, "HH:mm").format("hh:mm A"),
              // preferredTime: user.preferredTime ? (user.preferredTime + " ") : " ",
              reason: user.reason
                .map((item) => {
                  return programType[item];
                })
                .join(", "),
              bookingstatus: (
                <React.Fragment>
                  {user.status === 1 && (
                    <div className="btn-success status">Pending</div>
                  )}
                  {user.status === 2 && (
                    <div className="btn-primary status">Approved</div>
                  )}
                  {user.status === 3 && (
                    <div className="btn-danger status">Cancelled request</div>
                  )}
                  {user.status === 5 && (
                    <div className="btn-danger status">Unable to Contact</div>
                  )}
                </React.Fragment>
              ),
              view: (
                <span
                  className="cursor-pointer"
                  onClick={() => this.getDetails(user)}
                >
                  <i className="mdi mdi-eye-outline mr-2 view"></i>
                </span>
              ),
            });
          });

          const tableData = {
            columns: [
              // {
              //     label: "No.",
              //     field: "key",
              //     sort: "asc",
              //     width: 270,
              // },
              {
                label: "Program",
                field: "reason",
                sort: "asc",
                width: 167,
              },
              {
                label: "Appointment Date",
                field: "bookingdate",
                sort: "asc",
                width: 150,
              },
              {
                label: "Appointment Time",
                field: "bookingtime",
                sort: "asc",
                width: 200,
              },
              {
                label: "Status",
                field: "bookingstatus",
                sort: "disabled",
                width: 270,
              },
              {
                label: "View",
                field: "view",
                sort: "disabled",
                width: 270,
              },
            ],
            rows: rowsArray,
          };
          this.setState({
            bookingData: tableData,
            loading: false,
            noAppointment: false,
          });
        } else {
          this.setState({
            noAppointment: true,
            loading: false,
          });
        }
      });
  };

  getAllCurrentWorkshops() {
    eventService
      .getAllCurrentEvents(new Date().getTime(), this.state.currentUser.id)
      .then((data) => {
        if (data.Status && data.Data.length) {
          this.setState({
            workshopList: data.Data,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  }

  getAllCurrentEvents() {
    eventService
      .getAllCurrentEvents(
        new Date().getTime(),
        this.state.currentUser.id,
        "event"
      )
      .then((data) => {
        if (data.Status && data.Data.length) {
          this.setState({
            eventList: data.Data,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  }

  generate = (id, gradientColor) => {
    if (gradientColor) {
      return gradientColor;
    } else {
      if (id >= GRADIENTS.length - 1) {
        return GRADIENTS[Math.floor(Math.random() * GRADIENTS.length)];
      } else {
        return GRADIENTS[id];
      }
    }
  };

  changeIconColor = (id, eventId) => {
    if (document.getElementById(id).style[0] === "color") {
      // document.getElementById(id).style.removeProperty('color');
      document.getElementById(id).disabled = true;
    } else {
      document.getElementById(id).style.color = "blue";
    }
    let whishlistData = {
      userId: this.state.currentUser.id,
      userName: `${this.state.currentUser.firstName} ${this.state.currentUser.lastName}`,
      userEmail: this.state.currentUser.email,
      eventId: eventId,
      currentUserId: this.state.currentUser.id,
    };
    eventService
      .addToWishlist(whishlistData)
      .then((data) => {
        if (data.Status) {
          NotificationManager.success("Added to Wishlist", "", 5000);
          this.getAllCurrentWorkshops();
        }
        // else {
        //   console.log("register event data on err: ", data)
        // }
      })
      .catch((error) => NotificationManager.error(error, "", 5000));
  };

  showIsRegistered = (item, index) => {
    var now = moment(new Date(item.dateTime));
    var end = moment(new Date());
    let endTime = moment(item.endTime);
    var duration = moment.duration(now.diff(end));
    var hours = duration.asHours();
    let startTime = moment(new Date(item.startTime));
    let isJoinable = end.isBetween(startTime.subtract(30, "minutes"), endTime);
    return (
      <React.Fragment>
        {isJoinable ? (
          <a
            href={item.accessLink}
            target="_blank"
            className="btn register_btn mr-3 w-100"
          >
            Join Now
          </a>
        ) : (
          <a
            id={index}
            style={{ color: "white" }}
            className="btn btn-dark mr-3 register_btn w-100 disabled"
          >
            Registered
          </a>
        )}
      </React.Fragment>
    );
  };

  registerForEvent(eventId, index, link) {
    let registrationData = {
      userId: this.state.currentUser.id,
      userName: `${this.state.currentUser.firstName} ${this.state.currentUser.lastName}`,
      userEmail: this.state.currentUser.email,
      eventId: eventId,
      currentUserId: this.state.currentUser.id,
    };
    // document.getElementById(index).innerHTML= "Registered"
    eventService
      .registerEvent(registrationData)
      .then((data) => {
        if (data.Status) {
          NotificationManager.success("Successfully Registered", "", 5000);
          this.getAllCurrentWorkshops();
        } else {
          console.log("register event data on err: ", data);
        }
      })
      .catch((error) => NotificationManager.error(error, "", 5000));
  }

  renderBanner = (index, item) => {
    if (item.type === "workshop") {
      return (
        <div
          id={"grad-" + index}
          className="d-flex py-3 border-bottom setheight"
          style={{
            background: this.generate(index, item.gradientColor),
          }}
        >
          <div className="author col-xs-2 col-sm-3 align-self-center">
            <div className="profile-image d-flex">
              <img
                async
                src={
                  item.facilitatorImage || "/assets/images/user_placeholder.jpg"
                }
              />
            </div>
            <div className="facilitorname">{item.facilitatorName}</div>
          </div>
          <div className="event_name align-self-center">
            {item.name.toUpperCase()}
          </div>
        </div>
      );
    } else {
      if (item.bannerImage) {
        return (
          <div
            style={{
              backgroundImage: `url(${item.bannerImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            className="d-flex py-3 border-bottom setheight justify-content-center"
          >
            <div className="event_name align-self-center transbox">
              {item.name.toUpperCase()}
            </div>
          </div>
        );
      } else {
        return (
          <div
            id={"grad-" + index}
            className="d-flex py-3 border-bottom setheight justify-content-center"
            style={{
              background: this.generate(index, item.gradientColor),
            }}
          >
            <div className="event_name align-self-center">
              {item.name.toUpperCase()}
            </div>
          </div>
        );
      }
    }
  };
  renderRegisterButton = (item, index) => {
    if (item) {
      let startTime = moment(new Date(item.startTime));
      let currentTime = moment();
      if (currentTime.isBefore(startTime)) {
        return (
          <a
            id={index}
            className="btn register_btn mr-3 w-100"
            onClick={() => document.getElementById(item._id).click()}
          >
            Register
          </a>
        );
      } else {
        return (
          <button id={index} className="btn register_btn mr-3 w-100" disabled>
            Closed
          </button>
        );
      }
    } else {
      return null;
    }
  };

  renderEventFooter = (index, item) => {
    if (item.type === "workshop") {
      return (
        <div className="card-footer">
          <div className="d-flex">
            {item.isRegistered ? (
              this.showIsRegistered(item, index)
            ) : (
              <React.Fragment>
                {this.renderRegisterButton(item, index)}
                <RegistrationConfirmation
                  id={item._id}
                  confirm={() =>
                    this.registerForEvent(item._id, index, item.accessLink)
                  }
                ></RegistrationConfirmation>
              </React.Fragment>
            )}
            {item.isRegistered ? (
              <i
                id={"icon-" + item._id}
                className="mdi mdi-bookmark mdi-24px d-flex bookmark"
              ></i>
            ) : (
              <React.Fragment>
                {item.wishlisted ? (
                  <i
                    id={"icon-" + item._id}
                    style={{ color: "blue" }}
                    className="mdi mdi-bookmark mdi-24px d-flex bookmark"
                  ></i>
                ) : (
                  <i
                    id={"icon-" + item._id}
                    className="mdi mdi-bookmark mdi-24px d-flex bookmark cursor_pointer"
                    onClick={() =>
                      this.changeIconColor("icon-" + item._id, item._id)
                    }
                  ></i>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="card-footer">
          <a
            href={item.accessLink}
            className="btn register_btn mr-3 w-100"
            target="_blank"
            rel="noopener noreferrer"
          >
            Register
          </a>
        </div>
      );
    }
  };

  render() {
    const { currentUser, eventList, workshopList } = this.state;
    let commonList = workshopList.concat(eventList);
    workshopList.sort((a, b) => {
      return a.dateTime - b.dateTime;
    });
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        <Preloader />
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </div>
            </div>

            <div className="row pt-4">
              <div className="col-sm-4">
                <div className="card">
                  <div className="card-body d-flex">
                    <div className="round round-lg align-self-center round-info">
                      <i className="mdi mdi-book-open"></i>
                    </div>
                    <div className="m-l-10 align-self-center dashboard_cardTitle">
                      <h4 className="card-title">My Appointments</h4>
                      <p className="card-text dashboard_cardtext">
                        {this.state.totalBooking}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="card">
                  <div className="card-body d-flex">
                    <div className="round round-lg align-self-center round-warning">
                      <i className="mdi mdi-cellphone-link"></i>
                    </div>
                    <div className="m-l-10 align-self-center dashboard_cardTitle">
                      <h4 className="card-title">Upcoming Workshops</h4>
                      <p className="card-text dashboard_cardtext">
                        {workshopList.length}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="card">
                  <div className="card-body d-flex">
                    <div className="round round-lg align-self-center round-danger">
                      <i className="mdi mdi-bullseye"></i>
                    </div>
                    <div className="m-l-10 align-self-center dashboard_cardTitle">
                      <h4 className="card-title">Upcoming Events</h4>
                      <p className="card-text dashboard_cardtext">
                        {eventList.length}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <div className="card dashboard_eventcard">
                    <div className="card-body">
                      {/* <h3>Welcome to UHC..</h3> */}
                      <h3 className="" style={{ textAlign: "center" }}>
                        Upcoming Workshops &amp; Events
                      </h3>
                      <div className="d-flex groupofcard">
                        {commonList.length
                          ? commonList.map((item, index) => {
                              if (item && item.eventDetails) {
                                item = item.eventDetails;
                                item.isRegistered = true;
                              }
                              return (
                                <React.Fragment key={index}>
                                  <div
                                    className="card m-3 [ info-card ]"
                                    style={{ width: "18rem" }}
                                  >
                                    {this.renderBanner(index, item)}
                                    <div className="datetimebox">
                                      <div>
                                        {moment(item.dateTime).format(
                                          "dddd, MMM Do YYYY"
                                        )}
                                      </div>
                                      {moment(item.startTime).format("hh:mm A")}{" "}
                                      -{moment(item.endTime).format("hh:mm A")}
                                    </div>
                                    {/* <img className="card-img-top" src="..." alt="Card image cap" /> */}
                                    <div className="card-body">
                                      <h3 className="card-title description_title">
                                        Description
                                      </h3>
                                      <div
                                        className="card-text description"
                                        dangerouslySetInnerHTML={{
                                          __html: item.description,
                                        }}
                                      ></div>
                                      <div className="d-flex event_location">
                                        <div className="d-flex mr-3">
                                          <div className="card-title mr-1">
                                            <i className="mdi mdi-map-marker"></i>
                                          </div>
                                          <div>{item.location || "Online"}</div>
                                        </div>
                                        {item.pdfLink ? (
                                          <div className="d-flex mr-3">
                                            <div className="card-title mr-1">
                                              <i className="mdi mdi-map-marker"></i>
                                            </div>
                                            <a
                                              href={item.pdfLink}
                                              rel="noopener noreferrer"
                                              target="_blank"
                                            >
                                              More Info
                                            </a>
                                          </div>
                                        ) : null}
                                        {item.recurrence ? (
                                          <div className="d-flex mr-3">
                                            <div className="card-title mr-1">
                                              <i className="mdi mdi-alarm"></i>
                                            </div>
                                            <div>
                                              Held:{" "}
                                              <span className="text-capitalize">
                                                {item.recurrenceFrequency}
                                              </span>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                    {item.type === "workshop" ? (
                                      <div className="[ info-card-details ] animate">
                                        <div className="[ info-card-detail ]">
                                          <div className="details">
                                            {item.isRegistered &&
                                              item.isActive != 1 && (
                                                <div className="">
                                                  <i
                                                    className="mdi mdi-delete deleteIcon cursor-pointer"
                                                    style={{
                                                      right: "-8px",
                                                      color: "red",
                                                    }}
                                                    onClick={() =>
                                                      document
                                                        .getElementById(
                                                          "deleteConfirmationButton"
                                                        )
                                                        .click()
                                                    }
                                                  ></i>
                                                  <DeleteConfirmation
                                                    id={
                                                      "deleteConfirmationButton"
                                                    }
                                                    confirm={() =>
                                                      this.deleteRegistered(
                                                        item._id
                                                      )
                                                    }
                                                  ></DeleteConfirmation>
                                                </div>
                                              )}
                                            {item.wishlisted &&
                                              item.isActive != 1 && (
                                                <div className="">
                                                  <i
                                                    className="mdi mdi-delete deleteIcon cursor-pointer"
                                                    style={{
                                                      right: "-8px",
                                                      color: "red",
                                                    }}
                                                    onClick={() =>
                                                      document
                                                        .getElementById(
                                                          "deleteConfirmationButton2"
                                                        )
                                                        .click()
                                                    }
                                                  ></i>
                                                  <DeleteConfirmation
                                                    id={
                                                      "deleteConfirmationButton2"
                                                    }
                                                    confirm={() =>
                                                      this.deleteWishlisted(
                                                        item._id
                                                      )
                                                    }
                                                  ></DeleteConfirmation>
                                                </div>
                                              )}

                                            <b>What You Will Learn:</b>
                                            <div
                                              className="text-left"
                                              dangerouslySetInnerHTML={{
                                                __html: item.whatWillYouLearn,
                                              }}
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {this.renderEventFooter(index, item)}
                                  </div>
                                </React.Fragment>
                              );
                            })
                          : this.state.noEvent && (
                              <div className="col-md-12 mt-4 mb-4">
                                <div
                                  style={{ color: "rgba(0,0,0,.5)" }}
                                  className="text-center"
                                >
                                  <h3 style={{ color: "rgba(0,0,0,.5)" }}>
                                    No upcoming events
                                  </h3>
                                </div>
                              </div>
                            )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <div className="card dashboard_tablecard">
                    <h3 className="mt-2" style={{ textAlign: "center" }}>
                      My Appointments
                    </h3>
                    {this.state.noAppointment ? (
                      <h3 className="text-center">You have no appointments</h3>
                    ) : (
                      <MDBDataTable
                        responsive
                        className="dashboard_table"
                        id="dashboard_appointmenttable"
                        paging={false}
                        noBottomColumns
                        hover
                        data={this.state.bookingData}
                      />
                    )}
                    {console.log(
                      "this.state.bookingData: ",
                      this.state.bookingData
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <Modal
                isOpen={this.state.modal}
                centered={true}
                toggle={this.toggle}
              >
                <ModalHeader toggle={this.toggle}>
                  Details of Appointment
                </ModalHeader>
                <ModalBody>
                  {/* {this.state.appointmentDetails.status == 3 ? */}
                  {console.log(
                    "this.state.appointmentDetails: ",
                    this.state.appointmentDetails
                  )}
                  <div>
                    {this.state.appointmentDetails.notes && (
                      <div className="full_width myappointment_modal">
                        <div>Counsellor's Note</div>
                        <div
                          id="notes"
                          dangerouslySetInnerHTML={{
                            __html: this.state.appointmentDetails.notes,
                          }}
                        ></div>
                      </div>
                    )}
                    {this.state.appointmentDetails.status === 5 && (
                      <div className="full_width myappointment_modal">
                        <div>
                          Unfortunately we are unable to reach you please
                          contact us via phone{" "}
                          <span>
                            <i className="fas fa-phone"></i>{" "}
                          </span>
                          (519) 944-4900 or email{" "}
                          <span>
                            <i className="fas fa-envelope-square"></i>{" "}
                          </span>
                          (uhc@uhc.ca)
                        </div>
                      </div>
                    )}
                    {this.state.appointmentDetails.status === 3 && (
                      <div className="full_width myappointment_modal">
                        <div>
                          Your request for an appointment has been canceled, if
                          this is done with an error please contact us
                          immediately via phone{" "}
                          <span>
                            <i className="fas fa-phone"></i>{" "}
                          </span>
                          (519) 944-4900 or email{" "}
                          <span>
                            <i className="fas fa-envelope-square"></i>{" "}
                          </span>
                          (uhc@uhc.ca)
                        </div>
                      </div>
                    )}
                    {this.state.appointmentDetails.status === 1 && (
                      <div className="full_width myappointment_modal">
                        <div className="row">
                          <div className="col-lg-6 col-sm-6">
                            {" "}
                            <span>
                              Name :{" "}
                              {`${this?.state?.appointmentDetails?.name}`}
                            </span>
                          </div>
                          <div className="col-lg-6 col-sm-6">
                            {" "}
                            <span>
                              Email :{" "}
                              {`${this?.state?.appointmentDetails?.email}`}
                            </span>
                          </div>
                        </div>

                        <div className="row pt-3">
                          <div className="col-lg-6 col-sm-6">
                            {" "}
                            <span>
                              Phone Number :{" "}
                              {`${this?.state?.appointmentDetails?.phoneNumber}`}
                            </span>
                          </div>
                          <div className="col-lg-6 col-sm-6"> </div>
                        </div>
                        {this?.state?.appointmentDetails?.preferredDay.length >
                          0 && (
                          <div className="row pt-3">
                            <div className="col-lg-12 col-sm-12">
                              {" "}
                              <span>Preferred Day : </span>
                              <span>
                                {this?.state?.appointmentDetails?.preferredDay.map(
                                  (days) => {
                                    return (
                                      <Badge bg="primary">
                                        {days.toUpperCase()}
                                      </Badge>
                                    );
                                  }
                                )}
                              </span>
                            </div>
                          </div>
                        )}

                        {this?.state?.appointmentDetails?.preferredTime.length >
                          0 && (
                          <div className="row pt-3">
                            <div className="col-lg-12 col-sm-12">
                              {" "}
                              <span>Preferred Time </span>
                              <span>
                                {this?.state?.appointmentDetails?.preferredTime.map(
                                  (days) => {
                                    return (
                                      <Badge bg="secondary">
                                        {days.toUpperCase()}
                                      </Badge>
                                    );
                                  }
                                )}
                              </span>
                            </div>
                          </div>
                        )}

                        {this?.state?.appointmentDetails
                          ?.preferredMethodOfContact.length > 0 && (
                          <div className="row pt-3">
                            <div className="col-lg-12 col-sm-12">
                              {" "}
                              <span>Preferred Method of Contact </span>
                              <span>
                                {this?.state?.appointmentDetails?.preferredMethodOfContact.map(
                                  (method) => {
                                    return (
                                      <Badge bg="secondary">
                                        {method.toUpperCase()}
                                      </Badge>
                                    );
                                  }
                                )}
                              </span>
                            </div>
                          </div>
                        )}

                        {this?.state?.appointmentDetails?.reason.length > 0 && (
                          <div className="row pt-3">
                            <div className="col-lg-12 col-sm-12">
                              {" "}
                              <span>Preferred Method of Contact </span>
                              <span>
                                {this?.state?.appointmentDetails?.reason.map(
                                  (reason) => {
                                    return (
                                      <Badge bg="secondary">
                                        {reason.toUpperCase()}
                                      </Badge>
                                    );
                                  }
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {this.state.appointmentDetails.status === 2 && (
                      <div className="full_width myappointment_modal">
                        <div className="row pt-3">
                          <div className="col-lg-6 col-sm-6">
                            {" "}
                            <span>
                              Name :{" "}
                              {`${this?.state?.appointmentDetails?.name}`}
                            </span>
                          </div>
                          <div className="col-lg-6 col-sm-6">
                            {" "}
                            <span>
                              Email :{" "}
                              {`${this?.state?.appointmentDetails?.email}`}
                            </span>
                          </div>
                        </div>

                        <div className="row pt-3">
                          <div className="col-lg-6 col-sm-6">
                            {" "}
                            <span>
                              Appointment Type :{" "}
                              {`${this?.state?.appointmentDetails?.appointmentType}`}
                            </span>
                          </div>
                          <div className="col-lg-6 col-sm-6">
                            {" "}
                            <span>
                              Booking Status :{" "}
                              {`${this?.state?.appointmentDetails?.bookingStatus}`}
                            </span>
                          </div>
                        </div>

                        <div className="row pt-3">
                          <div className="col-lg-6 col-sm-6">
                            {" "}
                            <span>
                              codeStatus :{" "}
                              {`${this?.state?.appointmentDetails?.codeStatus}`}
                            </span>
                          </div>
                          <div className="col-lg-6 col-sm-6">
                            {" "}
                            <span>
                              Date and Time :{" "}
                              {`${this?.state?.appointmentDetails?.date} ${this?.state?.appointmentDetails?.time}`}
                            </span>
                          </div>
                        </div>

                        {this?.state?.appointmentDetails?.preferredDay.length >
                          0 && (
                          <div className="row pt-3">
                            <div className="col-lg-12 col-sm-12">
                              {" "}
                              <span>Preferred Day : </span>
                              <span>
                                {this?.state?.appointmentDetails?.preferredDay.map(
                                  (days) => {
                                    return (
                                      <Badge bg="primary">
                                        {days.toUpperCase()}
                                      </Badge>
                                    );
                                  }
                                )}
                              </span>
                            </div>
                          </div>
                        )}

                        {this?.state?.appointmentDetails?.preferredTime.length >
                          0 && (
                          <div className="row pt-3">
                            <div className="col-lg-12 col-sm-12">
                              {" "}
                              <span>Preferred Time </span>
                              <span>
                                {this?.state?.appointmentDetails?.preferredTime.map(
                                  (days) => {
                                    return (
                                      <Badge bg="secondary">
                                        {days.toUpperCase()}
                                      </Badge>
                                    );
                                  }
                                )}
                              </span>
                            </div>
                          </div>
                        )}

                        {this?.state?.appointmentDetails
                          ?.preferredMethodOfContact.length > 0 && (
                          <div className="row pt-3">
                            <div className="col-lg-12 col-sm-12">
                              {" "}
                              <span>Preferred Method of Contact </span>
                              <span>
                                {this?.state?.appointmentDetails?.preferredMethodOfContact.map(
                                  (method) => {
                                    return (
                                      <Badge bg="secondary">
                                        {method.toUpperCase()}
                                      </Badge>
                                    );
                                  }
                                )}
                              </span>
                            </div>
                          </div>
                        )}

                        {this?.state?.appointmentDetails?.reason.length > 0 && (
                          <div className="row pt-3">
                            <div className="col-lg-12 col-sm-12">
                              {" "}
                              <span>Preferred Method of Contact </span>
                              <span>
                                {this?.state?.appointmentDetails?.reason.map(
                                  (reason) => {
                                    return (
                                      <Badge bg="secondary">
                                        {reason.toUpperCase()}
                                      </Badge>
                                    );
                                  }
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </ModalBody>
              </Modal>
            </div>
            <Modal
              isOpen={this.state.contactModal}
              toggle={this.toggleContact}
              centered
            >
              <ModalHeader toggle={this.toggleContact}>
                Registration
              </ModalHeader>
              <ModalBody>
                Please Contact UHC for details regarding registation.
              </ModalBody>
            </Modal>

            <Modal isOpen={this.state.nocModal} toggle={this.toggleNocModal}>
              <ModalHeader toggle={this.toggleNocModal}>NOC Code</ModalHeader>
              <ModalBody>
                <NocCodeForm />
              </ModalBody>
            </Modal>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDashboard);
