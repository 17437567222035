import React, { Component } from 'react';
import { connect } from 'react-redux';
import Preloader from '../components/Preloader';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {userService} from '../_services/admin';
import PasswordMask from 'react-password-mask';
import { NotificationManager } from 'react-notifications';
function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

class ResetPasswordPage extends Component {
    
    componentDidMount () {
        let scripts = [
            '/assets/js/custom.min.js'
        ];
        let host = window.location.origin;
        console.log("host", host);
        scripts.map((t) => {
            let script = document.createElement("script");
            script.src = host + t;
            script.async = false;
            document.body.appendChild(script);
            return true;
        });
    }
    render() {
        return (
            <React.Fragment>
                <Preloader />
                <section id="wrapper">
                    <div class="login-register" >
                        <div class="login-box card">
                            <div class="card-body">
                            <Formik
                                initialValues={{
                                    password: '',
                                    confirmpassword:'p'
                                }}
                                validationSchema={Yup.object().shape({
                                    password: Yup.string().when({
                                        is: val => (val && val.length > 0 ? true : false),
                                        then: Yup.string().when({
                                            is: inVal => (inVal && inVal.length > 0 ? true : false),
                                            then: Yup.string().matches(
                                                /^(?=.*[a-z])(?=.*[A-Z])/,
                                                'Must have both uppercase and lowercase letters'
                                            ).matches(
                                                /^(?=.*[0-9])/,
                                                'Must consist of a number'
                                            ).matches(
                                                /^(?=.{8,})/,
                                                'Must be atleast 8 character long'
                                            ).required('Password is required'),
                                        }),
                                        otherwise: Yup.string().matches(
                                            /^(?=.*[a-z])(?=.*[A-Z])/,
                                            'Must have both uppercase and lowercase letters'
                                        ).matches(
                                            /^(?=.*[0-9])/,
                                            'Must consist of a number'
                                        ).matches(
                                            /^(?=.{8,})/,
                                            'Must be atleast 8 character long'
                                        ).required('Password is required'),
                                    }),
                                    confirmPassword: Yup.string().when("password", {
                                        is: val => (val && val.length > 0 ? true : false),
                                        then: Yup.string().oneOf(
                                          [Yup.ref("password")],
                                          "Must be same as Password"
                                        ).required('Confirm Password is required')
                                      }),
                                })}
                                onSubmit={(formData, { setStatus, setSubmitting }) => {
                                    setStatus();
                                    userService.setPassword(formData, this.props.match.params.token)
                                        .then(
                                            result => {
                                                setSubmitting(false);
                                                if(result.Status){
                                                    NotificationManager.success('Password changed succesfully.','',5000);
                                                    const { from } = this.props.location.state || { from: { pathname: '/login' } };
                                                    this.props.history.push(from);
                                                } else {
                                                    NotificationManager.error('Something went wrong please contact UHC Support.','',5000);
                                                }
                                                
                                            },
                                            error => {
                                                setSubmitting(false);
                                                setStatus(error);
                                            }
                                        );
                                }}
                                render={({ errors, status, touched, isSubmitting, setFieldValue }) => (
                                <Form>
                                    <div className="form-horizontal form-material" id="loginform">
                                        <img className="loginlogo" src={window.location.origin + '/assets/images/UHC_logo.png'} />
                                    <h3 className="box-title m-b-20">Reset Password</h3>
                                    <div className="form-group required">
                                        <label className="control-label">Password</label>
                                        <Field name="password" placeholder="Password"
                                            autoComplete="off"
                                            render={({ field }) => (
                                                <PasswordMask
                                                    inputStyles={{ border: 'none' }}

                                                    buttonStyles={{ top: '5px', backgroundColor: 'transparent' }}

                                                    showButtonContent={<i className="ti-eye" style={{ color: '#ccc', backgroundColor: 'transparent' }}></i>}
                                                    hideButtonContent={<i className="ti-eye" style={{ backgroundColor: 'transparent' }}></i>}
                                                    onChange={(password) => password === null ? setFieldValue('password', "") : setFieldValue('password', password)}
                                                    {...field}
                                                    className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                                                />
                                            )}
                                        />
                                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group required">
                                        <label className="control-label">Confirm Password</label>
                                        <Field name="confirmPassword" placeholder="Confirm Password"
                                            autoComplete="off"
                                            render={({ field }) => (
                                                <PasswordMask
                                                    inputStyles={{ border: 'none' }}

                                                    buttonStyles={{ top: '5px', backgroundColor: 'transparent' }}

                                                    showButtonContent={<i className="ti-eye" style={{ color: '#ccc', backgroundColor: 'transparent' }}></i>}
                                                    hideButtonContent={<i className="ti-eye" style={{ backgroundColor: 'transparent' }}></i>}
                                                    onChange={(confirmPassword) => confirmPassword === null ? setFieldValue('confirmPassword', "") : setFieldValue('confirmPassword', confirmPassword)}
                                                    {...field}
                                                    className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')}
                                                />
                                            )}
                                        />
                                        <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group text-center mb-0 m-t-20">
                                        <div className="col-xs-12">
                                            <button type="submit" disabled={isSubmitting} className="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light" >Submit</button>
                                            {isSubmitting &&
                                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            }
                                        </div>
                                    </div>
                                    {status &&
                                        <div className={'alert alert-danger mt-2'}>{status}</div>
                                    }
                                    </div>
                                </Form>

                                )}
                                />
                            </div>
                        </div>
                    </div>

                </section>
            </React.Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPasswordPage);